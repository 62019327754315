import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom'; // Import Link
import axios from 'axios';
import { BASE_URL } from './commonConstants';


const Activate = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [tokenVerified, setTokenVerified] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false);
    const navigate = useNavigate();



    const resetForm = () => {
        setPassword(''); 
        setConfirmPassword('');
    };

     const handlePasswordChange = (e) => {
        const { value } = e.target;
        setPassword(value.trim());
        setPasswordError(value.trim() === '' ? 'Password is required' : '');
        setPasswordError(value.length < 8 ? 'Password should be at least 8 characters' : '');
      };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };



    const handlePasswordUpdate = async (e) => {
        e.preventDefault();

        // Password validation
        if (!password) {
            setPasswordError('Please enter a password');
            return;
        } else {
            if(password.length < 8){
                setPasswordError('Password should be at least 8 characters');
                return;
            }
            setPasswordError(null);
        }

        // Confirm password validation
        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
            return;
        } else {
            setConfirmPasswordError(null);
        }

        try {
            const response = await axios.post(`${BASE_URL}activate/${token}`, { token, password, password_confirmation: confirmPassword });
            if (response.data.status === 'success') {
				setSuccess(<>Password updated successfully!</>);
                resetForm();
                //  window.location.href = '/'; // Redirect using window.location
                navigate('/');
			} else {
                setError(response.data.message);
            }
        } catch (error) {
            // console.error('Password update error:', error);
            setError('Password update failed.');
        }
    };

    const verifyToken = async () => {
        try {
            const response = await axios.post(`${BASE_URL}verifytoken/`, { token });
            if (response.data.status === 'success') {
                // setSuccess('Token verified successfully!');
                resetForm();
                setTokenVerified(true);
            } else {
                setError('Token verification failed.');
               
                // window.location.href = '/'; // Redirect using window.location
            }
        } catch (error) {
            // console.error('Token verification error:', error);
            setError('Token verification failed.');
        }
        setIsLoading(false);
    };

    useEffect(() => {        
        verifyToken();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <section className="forget-password">
            <div className="container-fluid">
                <div className="row profit-bg1 justify-content-center align-items-center align-content-stretch">
                    <div className="banner col-lg-6 col-md-5 min-vh-100" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center', alignContent: 'stretch' }}>
                        <div className="row justify-content-center">
                            <div className="col-xl-12">
                                <div className="card shadow-sm p-5" style={{ minWidth: '500px', maxWidth: '500px'}}> {tokenVerified ? (
                                        <form className="text-center" onSubmit={handlePasswordUpdate}>
                                            <img className="mb-4" src="../assets/images/logo.svg" alt="logo" width="255" height="33" />
                                            <h4 className="mb-2">Enter the New Password</h4>
                                            {error && <div className="alert alert-danger alert-dismissible fade show" role="alert">{error}</div>}
                                            {success && <div className="alert alert-success alert-dismissible fade show" role="alert">{success}</div>}
                                            <div className="form-floating mb-4 text-muted">
                                                <input type={showPassword ? 'text' : 'password'} className={`form-control ${passwordError && 'is-invalid'}`} id="floatingPassword" value={password}  onChange={handlePasswordChange} placeholder="Password" />
                                                <label htmlFor="floatingPassword">Password *</label>
                                                {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                                                <button type="button" className={"btn btn-link " + (showPassword ? "hide-password " : "show-password")} onClick={togglePasswordVisibility}>
                                                {showPassword ? (
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                ) : (
                                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                )}
                                                </button>
                                            </div>
                                            <div className="form-floating mb-4 text-muted">
                                                <input type={showConfirmPassword ? 'text' : 'password'} className={`form-control ${confirmPasswordError && 'is-invalid'}`} id="floatingCPassword" value={confirmPassword}  onChange={handleConfirmPasswordChange} placeholder="Password" />
                                                <label htmlFor="floatingCPassword">Confirm Password *</label>
                                                {confirmPasswordError && <div className="invalid-feedback">{confirmPasswordError}</div>}
                                                <button type="button" className={"btn btn-link " + (showConfirmPassword ? "hide-password " : "show-password")} onClick={toggleConfirmPasswordVisibility}>
                                                {showConfirmPassword ? (
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                ) : (
                                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                )}
                                                </button>
                                            </div>
                                            <button className="w-100 btn btn-lg btn-primary" type="submit">Update Password</button>
                                        </form>
                                    ) : (
                                        <div>
                                            <p>Your token is invalid or has expired. Please contact your administrator or log in again.</p>
											<div className="back-to-login mt-3"><u><Link to="/">Back to Login</Link></u></div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </section>
    );
};

export default Activate;
