import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import 'bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';
import { BASE_URL, TOKEN } from '../../commonConstants';
import axios from 'axios';


const SubResources = ({ userName, role, onLogout }) => {
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);
	const [folderNameError, setFolderNameError] = useState('');
	const [fileNameError, setFileNameError] = useState('');
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]); // Define state variable to hold data
	const [source, setSource] = useState([]); // Define state variable to hold data
	const [downloading, setDownloading] = useState(false);
	const [show, setShow] = useState(false);
	const [fadeOut, setFadeOut] = useState(false);
	const created = localStorage.getItem('userId') || '';
	const [eshow, setEShow] = useState(false);
	const [dshow, setDShow] = useState(false);
	const [fshow, setFShow] = useState(false);

	const [selectedFile, setSelectedFile] = useState(null);
	const [fileSizeFormatted, setFileSizeFormatted] = useState('');
	const [uploading, setUploading] = useState(false);


	const navigate = useNavigate();
	const { id } = useParams();
	const parent = id ? id : '';

	// let utype = localStorage.getItem('type') || '';


	const onFileChange = (event) => {
		const file = event.target.files[0];
		setSelectedFile(file);
		setFileSizeFormatted(formatFileSize(file.size));
		setUploading(false);
		setSuccess(false);
		setError(false);
	};
	const resetfileForm = async (event) => {
		setSelectedFile(null);
		setFileSizeFormatted('');
		setError(null);
		setSuccess(false);
	}

	const onFileUpload = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior

		if (!selectedFile) {
			console.error('No file selected');
			setError('No file selected');
			setUploading(false);
			setSuccess(false);
			setSelectedFile(null);
			setFileSizeFormatted('');
			return;
		}

		setUploading(true);
		setSuccess('');
		setError(null);

		const formData = new FormData();
		formData.append("myFile", selectedFile, selectedFile.name);
		formData.append("parent", parent);
		formData.append("created_by ", created);
		try {
			const response = await axios.post(`${BASE_URL}file/upload/`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${TOKEN}`,
				}
			});

			let successMessage = "File Uploaded Successfully";
			if (response.data && response.data.message) {
				successMessage = response.data.message;
			}

			setSuccess(successMessage);
			setSelectedFile(null);
			setFileSizeFormatted('');
			const source = axios.CancelToken.source();
			fetchData(source);
			setFShow(false);
			// resetfileForm();

		} catch (error) {
			let errorMessage = "Error uploading file";
			if (error.response && error.response.data && error.response.data.message) {
				errorMessage = error.response.data.message;
			}
			console.error(errorMessage);
			setError(errorMessage);
			setSuccess(false);
			setSelectedFile(null);
			setFileSizeFormatted('');
		} finally {
			setUploading(false);
		}
	};

	const fileData = () => {
		// const [fileData, setFileData] = useState({

		if (selectedFile) {
			return (
				<div className="uploded-files w-500">
					<div className="d-flex px-4 py-2 mb-3 rounded align-items-center justify-content-between profit-upload-bg">
						<div>
							<p className="mb-0 fw-semibold">{selectedFile.name}</p>
							<small className="text-muted fw-semibold">File size : {fileSizeFormatted}</small>
						</div>
						<div>
							<button className="border-0 bg-transparent" onClick={resetfileForm}>
								<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd"
										d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
										fill="currentColor"></path>
									<path fillRule="evenodd" clipRule="evenodd"
										d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
										fill="currentColor"></path>
								</svg>
							</button>
						</div>
					</div>
				</div>
			);
		}
	};


	const [folderData, setFolderData] = useState({
		name: 'Untitled folder',
		created_by: created,
		type: 'folder',
		parent: parent
	});
	// const [fileData, setFileData] = useState({
	// 	name: '',
	// 	created_by : created,
	// 	type: '',
	// 	parent : parent
	// });


	const [folderEditData, setFolderEditData] = useState({
		name: ''
	});

	// Fetch data from API
	const fetchData = async (source) => {
		try {
			const response = await axios.get(`${BASE_URL}resources/${parent}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				cancelToken: source.token,
			});
			// if (!response.ok) {
			// 	throw new Error('Failed to fetch users');
			// }
			const responseData = response.data;
			setData(responseData.data); // Set fetched data to state	
			setSource(responseData.source); 
			setLoading(false);
		} catch (error) {
			if (axios.isCancel(error)) {
				// console.log('Request cancelled');
			} else {
				if (error.response.status == 401) {
					onLogout();
				}
				// handle other errors
				setLoading(false);
			}
		}
	};

	const downloadFile = async (id, file_original_name) => {
		setDownloading(prevStates => ({ ...prevStates, [id]: true }));
		try {
			const response = await axios.get(`${BASE_URL}download/${id}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				responseType: 'blob',
			});

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', file_original_name);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (error) {
			console.error('Error downloading the file', error);
		}
		setDownloading(prevStates => ({ ...prevStates, [id]: false }));
	};
	const handleClose = () => {
		setShow(false);
		setSuccess('');
		setError('');
		resetForm();
		resetFormValidation();
	};
	const handleEClose = () => {
		setEShow(false);
		setSuccess('');
		setError('');
		resetEForm();
		resetFormValidation();
	};
	const handleFClose = () => {
		setFShow(false);
		setSuccess('');
		setError('');
		resetfileForm();
		resetFFormValidation();
	};
	const handleDClose = () => {
		setDShow(false);
		setSuccess('');
		setError('');
	};

	const handleShow = () => {
		setShow(true);
		setSuccess('');
		setError('');
		resetForm();
		resetFormValidation();
	};
	const handleFShow = (id, name) => {
		setFShow(true);
		setSuccess('');
		setError('');
		resetfileForm();
		resetFFormValidation();
	};
	const handleEShow = (id, name) => {
		setEShow(true);
		setSuccess('');
		setError('');
		resetEForm(id, name);
		resetFormValidation();
	};
	const handleDShow = (did, dname) => {
		setDShow(true);
		setSuccess('');
		setError('');
		setFolderEditData({
			id: did,
			name: dname,
		});
	};

	const resetFForm = () => {
		// setFileData({			
		// 	name: '',
		// 	created_by : created,
		// 	type: '',
		// 	parent : parent
		// });
	};
	const resetForm = () => {
		setFolderData({
			name: 'Untitled folder',
			created_by: created,
			type: 'folder',
			parent: parent
		});
	};
	const resetEForm = (eid, ename) => {
		setFolderEditData({
			id: eid,
			name: ename,
		});
	};

	// Define editFolder function within useEffect

	// useEffect(() => {
	// $('.dblclk').on('click', function () {
	// 	var did = $(this).id;			
	// 	navigate(`/resources/${did}`);
	// });
	// $('.renamefolder').on('click', function () {
	// 	var did = $(this).id;			
	// 	navigate(`/resources/${did}`);
	// });
	// }, []);

	useEffect(() => {
		const source = axios.CancelToken.source();
		fetchData(source);
		return () => {
			source.cancel('Operation canceled by the user.');
		};
	}, [id]);

	useEffect(() => {
		let hideTimeout;
		let removeTimeout;
	
		if (success || error) {
		  // Start fading out before removing
		  hideTimeout = setTimeout(() => {
			setFadeOut(true);
		  }, 2000); // Start fading out 0.5 seconds before removing
	
		  // Remove the alert after the fade-out transition
		  removeTimeout = setTimeout(() => {
			setSuccess(null);
			setError(null);
			setFadeOut(false); // Reset fadeOut for future alerts
		  }, 5000); // Remove after 2 seconds
		}
	
		return () => {
		  clearTimeout(hideTimeout);
		  clearTimeout(removeTimeout);
		};
	  }, [success, error]);


	const handleSubmit = async () => {
		if (!validateForm()) {
			return;
		}
		try {
			const response = await fetch(`${BASE_URL}folder`, {
				method: 'POST', // Use PUT method for updating
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				body: JSON.stringify(folderData), // Send updated data in the request body
			});
			const responseData = await response.json();
			if (response.ok) {
				setError('');
				setSuccess('Folder Created Successfully');
				const source = axios.CancelToken.source();
				fetchData(source);
				resetForm();
				setShow(false);
			} else {
				// Handle login error (e.g., display error message)
				setError('Error creating folder ', error);
			}
			// console.log('Folder created successfully');
		} catch (error) {
			if (error.response.status == 401) {
				onLogout();
			}
			setSuccess('');
			setError('Error Creating folder:', error);
			console.error('Error  folder:', error);
		}
		window.scrollTo(0, 0);
	};
	const handleDSubmit = async () => {
		if (!validateForm()) {
			return;
		}
		try {
			const response = await fetch(`${BASE_URL}delete/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				body: JSON.stringify(folderEditData), // Send updated data in the request body
			});
			const responseData = await response.json();
			if (response.ok) {
				setError('');
				setSuccess('Resource  Deleted');
				const source = axios.CancelToken.source();
				fetchData(source);
				resetForm();
				setDShow(false);
			} else {
				// Handle login error (e.g., display error message)
				setError('Error updating Folder ', error);
			}
			// console.log('Folder created successfully');
		} catch (error) {
			if (error.response.status == 401) {
				onLogout();
			}
			setSuccess('');
			setError('Error Creating Folder:', error);
			console.error('Error  Folder:', error);
		}
		window.scrollTo(0, 0);
	};

	const handleESubmit = async () => {
		if (!validateForm()) {
			return;
		}
		try {
			const response = await fetch(`${BASE_URL}folder`, {
				method: 'PUT', // Use PUT method for updating
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				body: JSON.stringify(folderEditData), // Send updated data in the request body
			});
			const responseData = await response.json();
			if (response.ok) {
				setError('');
				setSuccess('Resource  Updated');
				const source = axios.CancelToken.source();
				fetchData(source);
				resetForm();
				setEShow(false);
			} else {
				// Handle login error (e.g., display error message)
				setError('Error updating Folder ', error);
			}
			// console.log('Folder created successfully');
		} catch (error) {
			if (error.response.status == 401) {
				onLogout();
			}
			setSuccess('');
			setError('Error Creating Folder:', error);
			console.error('Error  Folder:', error);
		}
		window.scrollTo(0, 0);
	};

	const validateForm = () => {
		let isValid = true;

		if (!folderData.name) {
			setFolderNameError(true);
			isValid = false;
		} else {
			setFolderNameError('');
		}

		return isValid;
	};

	const resetFormValidation = () => {
		setFolderNameError('');
	};
	const resetFFormValidation = () => {
		setFileNameError('');
	};


	function formatFileSize(bytes) {
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (typeof bytes !== 'number') return '—';
		if (bytes === 0) return '0 KB';
		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
		return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
	}

	function getFileTypeIcon(type) {
		switch (type) {
			case 'pdf':
				return 'pdf.png';
			case 'docs':
			case 'docx':
				return 'docs.png';
			case 'xls':
			case 'csv':
				return 'xls.png';
			case 'ppt':
			case 'pptx':
				return 'ppt.png';
			case 'jpeg':
			case 'svg':
			case 'png':
			case 'jpg':
				return 'gallery.png';
			case 'folder':
				return 'folder.png';
			default:
				return 'default.png';
		}
	}

	// File content to be displayed after

	// File contet to be displayed after


	return (
		<div className="sb-nav-fixed">
			<Navbar userName={userName} role={role} onLogout={onLogout} />
			<div id="layoutSidenav">
				<Sidebar role={role} onLogout={onLogout} page='resources' />
				<div id="layoutSidenav_content">
					<main>
						<div className="container-fluid px-4">
						{success && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-success alert-dismissible  mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{success}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setSuccess(null)}></button></div>}
						{error && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-danger alert-dismissible mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{error}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setError(null)}></button></div>}
							<div className="row align-items-center justify-content-between">
								<div className="col-md-6">
								
								<h1 className="fs-4 mb-0">Resources</h1>
									
								</div>
								{role == 'admin'  && (
								<div className="col-md-6 text-end">
									{/* //if role is admin show add button */}
									{/* <div>{role === 'admin' && <Link className="btn btn-primary" to={'/resources/upload'}>+ Add Resource</Link>}</div> */}

									<div className="dropdown">
										<button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">+ Add Resource</button>
										<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
										{role == 'admin' && (
											<li><button className="dropdown-item" onClick={handleShow} >New folder</button></li>
										)}
											{/* <li><Link className="dropdown-item" to={'/resources/upload'}>File Upload</Link></li> */}
											{role == 'admin' && (
											<li><button className="dropdown-item" onClick={handleFShow} >New File </button></li>
											)}
											
										</ul>
									</div>
								</div>
								)}

								<Modal show={show} dialogClassName="modal-dialog modal-dialog-centered modal-sm">
									<div className="modal-content">
										{/* <Modal.Header > */}
										{/* <Modal.Title>New Folder</Modal.Title>
												{/* <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button> */}
										{/* </Modal.Header> */}
										<Modal.Body >
											{error && <div className={`position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-danger alert-dismissible mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{error}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setError(null)}></button></div>}
											<form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
												<div className="">
													<div className="row mb-4">
														<div className="col-md-12">
															<h5>New Folder</h5>
														</div>
													</div>
													<div className="row mb-4">
														<div className="col-md-12">
															<div>
																<input className={`form-control ${folderNameError ? 'is-invalid' : ''}`} id="inputCompany" type="text" value={folderData.name} onChange={(e) => { setFolderData({ ...folderData, name: e.target.value }); setFolderNameError(e.target.value.trim() === '' ? 'Folder name is required' : ''); }} />
																{/* {folderNameError && <div className="invalid-feedback">{folderNameError}</div>} */}
															</div>
														</div>
													</div>


												</div>
												<div>
													<div className="row">
														<div className="col-md-12 text-end">
															<button className="btn" type="button" onClick={handleClose}>Cancel</button>
															<button type="submit" className="btn btn-link mx-2 text-decoration-none" disabled={!!folderNameError}>Create</button>
														</div>
													</div>
												</div>
											</form>
										</Modal.Body>
									</div>
								</Modal>
								<Modal show={fshow} dialogClassName="modal-dialog modal-dialog-centered modal-lg">
									<div className="modal-content">
										<Modal.Header >
											<Modal.Title>New File</Modal.Title>
											<button type="button" className="btn-close" aria-label="Close" onClick={handleFClose}></button>
										</Modal.Header>
										<Modal.Body >

											<form id="file-upload-form" className="form-container d-flex justify-content-center align-items-center my-4" encType="multipart/form-data">
												<div className="upload-files-container bg-light py-5 rounded-lg d-flex align-items-center justify-content-center flex-column rounded-3 vw-100 vh-50">
													{uploading && <div>Loading...</div>}
													{success && <div className="alert alert-success alert-dismissible w-500 fade m-auto show mb-4" role="alert">{success ? success : "The File Updated Successfully"}</div>}
													{error && <div className="alert alert-danger alert-dismissible w-500 fade m-auto show mb-4" role="alert">{error ? error : "Error Uploading the File"}</div>}
													<div className="drag-file-area rounded mt-0">
														<h3 className="dynamic-message">Select a file to upload</h3>

														<label htmlFor="file-upload" id="file-drag" className="label">
															<span className="browse-files">
																<input id="file-upload" type="file" className="default-file-input" name="fileUpload" onChange={onFileChange} />
																<span id="file-upload-btn" className="btn btn-primary browse-files-text fw-bold">Browse file</span>
																<span className="ms-3 fs-5">from device</span>
															</span>
														</label>
													</div>

													<output htmlFor="file-upload" id="messages">{fileData()}</output>
													<button type="button" className="btn btn-primary btn-lg btn-block w-500" onClick={onFileUpload}>
														{uploading ? (
															<div>
																<span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
																<span className="sr-only"> Uploading...</span>
															</div>
														) : (
															<div>Upload Files</div>
														)}
													</button>
												</div>
											</form>
										</Modal.Body>
									</div>
								</Modal>
							</div>

							<div className="card my-4">
								<div className="card-body">
									{/* {source !== null && (		 */}
										{/* <div className='row'>
											<div className="col-4 mb-4 gap-2 d-flex">												
												<Link className="btn" to={`/resources/${source.parent ? source.parent : ''}`}>Back</Link>
												<h3 className="fs-4 mb-0">{ source.original_name }</h3>
												</div>										
										</div> */}

									{/* )} */}
									<table id="datatablesSimple" className="table table-hover text-break">
										<thead className='table-primary'>
											<tr>
												<th style={{ width: '70%' }}>Name</th>
												<th>Size</th>
												<th>Modified</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{source !== null && (


											<tr className="" >
														<td className='d-flex align-items-center'>													
														<Link className="btn btn-link text-decoration-none" to={`/resources/${source.parent ? source.parent : ''}`}><img className="m-2" src={`/../assets/icons/folder.png`} alt="logo" height="19" width="19" />..</Link>													
														</td>
														<td></td>
														<td></td>
														<td></td></tr>										

									)}
											{data && Array.isArray(data) && data.length > 0 ? (
												data.map((file, index) => (
													<tr key={index} className={`${file.type ? 'dblclk' : ''}`} id={file.id} >
														<td className='d-flex align-items-center'><img className="m-2" src={`/../assets/icons/${getFileTypeIcon(file.file_type ? file.file_type : file.type)}`} alt="logo" height="19" width="19" />
														{file.type == 'folder' ? (
														<Link className="dropdown-item" to={`/resources/${file.id}`}>{file.original_name}</Link>
														) : (
															file.original_name
														)}
														</td>
														<td>{formatFileSize(file.size ? file.size : '-')}</td>
														<td>{file.updated_at_formated}</td>
														<td>
															{file.type == 'folder' ? (
																role == 'admin' && (
																<div className="dropdown">
																	<button className="btn btn-sm btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
																	<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
																		<li><button className="dropdown-item" onClick={() => handleEShow(file.id, file.original_name)} >Rename</button></li>
																		<li><Link className="dropdown-item" to={`/resources/${file.id}`}>View</Link></li>
																		<li><button className="dropdown-item" onClick={() => handleDShow(file.id, file.original_name)} >Delete</button></li>
																	</ul>
																</div>
																)
															) : (
																<div  role="group" aria-label="Small button group">
																	<button className="btn btn-sm btn-outline-primary" id={file.id + "_dwn_btn"} onClick={() => downloadFile(file.id, file.original_name)}>
																		{downloading[file.id] ? (
																			<div><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span className="sr-only"> Loading...</span></div>
																		) : (
																			<span> Download</span>
																		)}
																	</button>
																	{role == 'admin' && (
																	<button className="btn btn-sm btn-outline-dark mx-2" onClick={() => handleDShow(file.id, file.original_name)} >Delete</button>
																	)}
																</div>
															)}
														</td>
													</tr>
												)
												)) : (
												<tr><td colSpan="8"> <p>{loading ? ("Loading...") : ("No Records Found")}</p></td></tr>
											)}
											<Modal show={eshow} dialogClassName="modal-dialog modal-dialog-centered modal-sm">
												<div className="modal-content">
													{/* <Modal.Header > */}
													{/* <Modal.Title>New Folder</Modal.Title>
												{/* <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button> */}
													{/* </Modal.Header> */}
													<Modal.Body >
														{error && <div className={`position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-danger alert-dismissible mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{error}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setError(null)}></button></div>}
														<form onSubmit={(e) => { e.preventDefault(); handleESubmit(); }}>
															<div className="">
																<div className="row mb-4">
																	<div className="col-md-12">
																		<h5>Rename</h5>
																	</div>
																</div>
																<div className="row mb-4">
																	<div className="col-md-12">
																		<div>
																			<input className={`form-control ${folderNameError ? 'is-invalid' : ''}`} id="inputCompany" type="text" value={folderEditData.name} onChange={(e) => { setFolderEditData({ ...folderEditData, name: e.target.value }); setFolderNameError(e.target.value.trim() === '' ? 'Folder name is required' : ''); }} />
																			{/* {folderNameError && <div className="invalid-feedback">{folderNameError}</div>} */}
																		</div>
																	</div>
																</div>
															</div>
															<div>
																<div className="row">
																	<div className="col-md-12 text-end">
																		<button className="btn" type="button" onClick={handleEClose}>Cancel</button>
																		<button type="submit" className="btn btn-link mx-2 text-decoration-none" disabled={!!folderNameError}>Create</button>
																	</div>
																</div>
															</div>
														</form>
													</Modal.Body>
												</div>
											</Modal>
											<Modal show={dshow} dialogClassName="modal-dialog modal-dialog-centered modal-sm">
												<div className="modal-content">
													<Modal.Header >
														<Modal.Title>Delete Folder</Modal.Title>
													</Modal.Header>
													<Modal.Body >
														{error && <div className={`position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-danger alert-dismissible mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{error}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setError(null)}></button></div>}
														<form onSubmit={(e) => { e.preventDefault(); handleDSubmit(); }}>
															<div>
																<div className="">Are you sure want to delete {folderEditData.name}</div>
																<div className="row">
																	<div className="col-md-12 text-end">
																		<button className="btn" type="button" onClick={handleDClose}>Cancel</button>
																		<button type="submit" className="btn btn-primary mx-2 text-decoration-none">Delete</button>
																	</div>
																</div>
															</div>
														</form>
													</Modal.Body>
												</div>
											</Modal>
										</tbody>
									</table>
								</div>
							</div>

							{/* // )} */}
						</div>
					</main>
				</div>
			</div>
		</div>
	);
};

export default SubResources;
