// ViewPartnerLeads.js
import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams
import { BASE_URL, TOKEN } from '../../commonConstants';


const ViewPartnerLeads = ({ userName, role, onLogout }) => {
	const { id } = useParams(); // Get the id parameter from the URL

	// const [email, setEmail] = useState('');

	// const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]); // Define state variable to hold data

	const navigate = useNavigate();

	// Define editPartner and viewPartnerLeads functions within useEffect
	const editLead = (pid) => {
		navigate(`/edit-lead/${pid}`);
	};
	const editOpportunity = (pid) => {
		navigate(`/edit-opportunity/${pid}`);
	};


	const [partnerData, setPartnerData] = useState({});

	const fetchPartnerData = async () => {
		try {
			const response = await fetch(`${BASE_URL}users/edit/${id}`, { // Use the id parameter in the URL
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				body: JSON.stringify({}),
			});
			if (!response.ok) {
				navigate(`/partners/`);
				throw new Error('Failed to fetch partner');
			}
			const responseData = await response.json();
			setPartnerData(responseData.data);

			setLoading(false);
		} catch (error) {
			console.error('Error fetching users:', error);
			// setError('Failed to fetch users');
			setLoading(false);
		}
	};

	const fetchOppData = async () => {
		try {
			const response = await fetch(`${BASE_URL}opportunities/partner/${id}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
			});
			if (!response.ok) {
				throw new Error('Failed to fetch users');
			} else {
				const responseData = await response.json();
				if (responseData.status !== "failed") {
					setData(responseData.data); // Set fetched data to state

					// Destroy existing DataTable instance if it exists
					if ($.fn.DataTable.isDataTable('#datatablesSimple')) {
						$('#datatablesSimple').DataTable().destroy();
					}

					$('#datatablesSimple').DataTable({
						data: responseData.data,
						columns: [
							{ data: 'id', defaultContent: '' },
							{ data: 'fname' },
							{ data: 'lname' },
							{ data: 'email' },
							{ data: 'company_name' },
							{
								data: 'lead_status',
								render: function (data) {
									// Assuming data contains the status value (e.g., 'active', 'pending', etc.)
									// Define the Bootstrap tag class based on the status value
									let tagClass;
									switch (data.toLowerCase()) {
										case 'approved':
											tagClass = 'badge badge-pill bg-success';
											break;
										case 'pending':
											tagClass = 'badge badge-pill bg-warning text-dark';
											break;
										case 'rejected':
											tagClass = 'badge badge-pill bg-dark';
											break;
										case 'converted':
											tagClass = 'badge badge-pill bg-primary';
											break;
										default:
											tagClass = 'badge badge-pill bg-info';
									}

									// Construct the Bootstrap tag with the appropriate class and status text
									const tagMarkup = `<span class="${tagClass}">${data}</span>`;

									// Return the HTML markup for the Bootstrap tag
									return tagMarkup;
								}
							},
							{
								data: 'created_at_formated',
								render: function (data) {

									// Return the formatted date string
									return data;
								}
							},
							{
								data: 'id',
								render: function (pid) {
									return `
						<div class="btn-group btn-group-sm" role="group" aria-label="Small button group">
							<button class="btn btn-outline-secondary editOpportunity" >Edit</a>					
						</div>
						`;
								}
							}
						],
						order: [0, 'desc'], // Disable initial sorting
						createdRow: function (row, data, dataIndex) {
							// Bind click event to the buttons inside the created row
							$(row).find('.editOpportunity').on('click', function () {
								editOpportunity(data.id);
							});
						}
					});
				}
			}
			setLoading(false);
		} catch (error) {
			console.error('Error fetching users:', error);
			// setError('Failed to fetch users');
			setLoading(false);
		}
	};

	const fetchLeadData = async () => {
		try {
			const response = await fetch(`${BASE_URL}leads/partner/${id}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
			});
			if (!response.ok) {
				throw new Error('Failed to fetch users');
			} else {

				const responseData = await response.json();
				if (responseData.status !== "failed") {
					setData(responseData.data); // Set fetched data to state

					// Destroy existing DataTable instance if it exists
					if ($.fn.DataTable.isDataTable('#datatableslSimple')) {
						$('#datatableslSimple').DataTable().destroy();
					}

					$('#datatableslSimple').DataTable({
						data: responseData.data,
						columns: [
							{ data: 'id', defaultContent: '' },
							{ data: 'fname' },
							{ data: 'lname' },
							{ data: 'email' },
							{ data: 'company_name' },
							{
								data: 'lead_status',
								render: function (data) {
									// Assuming data contains the status value (e.g., 'active', 'pending', etc.)
									// Define the Bootstrap tag class based on the status value
									let tagClass;
									switch (data.toLowerCase()) {
										case 'approved':
											tagClass = 'badge badge-pill bg-success';
											break;
										case 'pending':
											tagClass = 'badge badge-pill bg-warning text-dark';
											break;
										case 'rejected':
											tagClass = 'badge badge-pill bg-dark';
											break;
										case 'converted':
											tagClass = 'badge badge-pill bg-primary';
											break;
										default:
											tagClass = 'badge badge-pill bg-info';
									}

									// Construct the Bootstrap tag with the appropriate class and status text
									const tagMarkup = `<span class="${tagClass}">${data}</span>`;

									// Return the HTML markup for the Bootstrap tag
									return tagMarkup;
								}
							},
							{
								data: 'created_at_formated',
								render: function (data) {

									return data;
								}
							},
							{
								data: 'id',
								render: function (pid) {
									return `
						<div class="btn-group btn-group-sm" role="group" aria-label="Small button group">
							<button class="btn btn-outline-secondary editLead" >Edit</a>					
						</div>
						`;
								}
							}
						],
						order: [0, 'desc'], // Disable initial sorting
						createdRow: function (row, data, dataIndex) {
							// Bind click event to the buttons inside the created row
							$(row).find('.editLead').on('click', function () {
								editLead(data.id);
							});
						}
					});
				}
			}
			setLoading(false);
		} catch (error) {
			console.error('Error fetching users:', error);
			// setError('Failed to fetch users');
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchLeadData();
		fetchOppData();
		fetchPartnerData();
	}, []);

	return (
		<div className="sb-nav-fixed">
			<Navbar userName={userName} role={role} onLogout={onLogout} />
			<div id="layoutSidenav">
				<Sidebar role={role} onLogout={onLogout} page="partners" />
				<div id="layoutSidenav_content">
					<main>
						<div className="container-fluid px-4">
							<div className="row align-items-center justify-content-between">
								<div className="col-md-6">
									<h1 className="fs-4 mb-0">Partner Detail</h1>
								</div>
								<div className='col-md-6 text-end'>
									<label className="form-label mb-0">Partner Status:
									</label>
									<span className={`badge badge-pill p-2 ms-2 ${(() => {
								 let tagClass;
								 if (partnerData.status === null || partnerData.status === undefined) {
									 tagClass = 'bg-light text-dark'; // Set tagClass to an empty string if data is null or undefined
								 } else {
									 switch (partnerData.status) {
										 case 'active':
											 tagClass = 'bg-success';
											 break;
										 case 'pending':
											 tagClass = 'bg-warning text-dark';
											 break;
										 case 'suspended':
											 tagClass = 'bg-dark';
											 break;
										 case 'converted':
											 tagClass = 'bg-primary';
											 break;
										 default:
											 tagClass = 'bg-info';
									 }
								 }
								 return tagClass;
							 })()}`}>
                                {partnerData.status ? partnerData.status : "N/A"}
							 </span>								
									
									
								</div>
							</div>
							{/* {loading && <p>Loading...</p>} */}
							{error && <p>{error}</p>}
							<div className="row pt-4 mb-4 mx-1 border border-sm rounded bg-light my-4 text-break">
								<div className="row  mb-4 ">
									{/* <p className="mb-0">Partner</p> */}
								</div>
								{partnerData.name &&
									<div className="col-4 mb-4 gap-2 d-flex">
										<label className="form-label mb-0">First Name:</label>
										<p className="mb-0 fw-medium">{partnerData.name}</p>
									</div>
								}
								{partnerData.email &&
									<div className="col-4 mb-4 gap-2 d-flex">
										<label className="form-label mb-0">Email Address:</label>
										<p className="mb-0 fw-medium">{partnerData.email}</p>
									</div>}
								{partnerData.company_name &&
									<div className="col-4 mb-4 gap-2 d-flex">
										<label className="form-label mb-0">Company:</label>
										<p className="mb-0 fw-medium">{partnerData.company? partnerData.company.company_name : "N/A" }</p>
									</div>}
								{partnerData.phone_number &&
									<div className="col-4 mb-4 gap-2 d-flex">
										<label className="form-label mb-0">Phone Number:</label>
										<p className="mb-0 fw-medium">{partnerData.phone_number}</p>
									</div>
								}
								{partnerData.website &&
									<div className="col-4 mb-4 gap-2 d-flex">
										<label className="form-label mb-0">Website:</label>
										<p className="mb-0 fw-medium">{partnerData.website}</p>
									</div>
								}

								
								<div className="col-4 mb-4 gap-2 d-flex">
									{partnerData.created_at_formated ? (
										<p>Created Date: {partnerData.created_at_formated}</p>
									) : (
										<p>Created Date: N/A</p>
									)}

								</div>
							</div>
							{partnerData.type != "referral" &&
								<div className="row  mb-4">
									<h2 class="fs-4">Opportunities</h2>

									<div className="card my-4">

										<div className="card-body">
											<table id="datatablesSimple" className="table table-bordered text-break">
												<thead className='table-primary'>
													<tr>
														<th>ID</th>
														<th>First Name</th>
														<th>Last Name</th>
														<th>Email</th>
														<th>Company Name</th>
														<th>Converted Status</th>
														<th>Created Date</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody><tr><td colSpan="8">{loading ? ("Loading...") : ("No Records Found")}</td></tr></tbody>
											</table>
										</div>
									</div>
								</div>
							}
							<div className="row  mb-4 ">
								<h2 class="fs-4">Leads</h2>
							</div>
							<div className="card my-4">

								<div className="card-body">
									<table id="datatableslSimple" className="table table-bordered">
										<thead className='table-primary'>
											<tr>
												<th>ID</th>
												<th>First Name</th>
												<th>Last Name</th>
												<th>Email</th>
												<th>Company Name</th>
												<th>Converted Status</th>
												<th>Created Date</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody><tr><td colSpan="8">{loading ? ("Loading...") : ("No Records Found")}</td></tr></tbody>
									</table>
								</div>
							</div>

						</div>
					</main>
				</div>
			</div>
		</div>
	);
};

export default ViewPartnerLeads;
