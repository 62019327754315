
// import './App.css';

// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import Activate from './components/Activate.js';
import RegisterPage from './components/pages/admin/RegisterPage';
// import Navigation from './components/Navigation'; // Import the Navigation component
import ForgotPasswordPage from './components/ForgotPasswordPage.js';
import Users from './components/pages/admin/Users.js';
import Partners from './components/pages/admin/Partners.js';
import Companies from './components/pages/admin/Companies.js';
import EditPartner from './components/pages/admin/EditPartner.js';
import EditUser from './components/pages/admin/EditUser.js';
import ViewPartnerLeads from './components/pages/admin/ViewPartnerLeads.js';
import Leads from './components/pages/admin/Leads.js';
import Opportunities from './components/pages/admin/Opportunities.js';
import Payments from './components/pages/admin/Payments.js';
import EditCompany from './components/pages/admin/EditCompany.js';


import EditLead from './components/pages/admin/EditLead.js';
import EditOpportunity from './components/pages/admin/EditOpportunity.js';
import FileUpload from './components/pages/admin/FileUpload.js';
import Resources from './components/pages/admin/Resources.js';
import SubResources from './components/pages/admin/SubResources.js';

import axios from 'axios';
import { BASE_URL, setToken as setCommonConstantToken, setUserId as setCommonConstantUserId } from './components/commonConstants.js';


const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token')); // Retrieve token from local storage
  const [userName, setUserName] = useState(localStorage.getItem('userName') || '');
  const [role, setRole] = useState(localStorage.getItem('role') || '');
  const [type, setType] = useState(localStorage.getItem('type') || '');
  const [ptype, setPType] = useState(localStorage.getItem('ptype') || '');
  const [userId, setUserId] = useState(localStorage.getItem('userId') || '');

  // const [logoutTimer, setLogoutTimer] = useState(null);
  const TIMEOUT_DURATION = 2 * 24 * 60 * 60 * 1000; // 2 days in milliseconds

  const handleLogin = (token, userInfo) => {
    setToken(token);
    setUserId(userInfo.id);
    setCommonConstantToken(token); // set the token in commonConstants.js
    setCommonConstantUserId(userInfo.id); // set the id in commonConstants.js
    setUserName(userInfo.name);
    setRole(userInfo.role);
    setType(userInfo.type);
    if (userInfo.company && userInfo.company.type) {
      setPType(userInfo.company.type);
    } else {
      setPType(null);
    }

    // Store token in local storage upon successful login
    localStorage.setItem('token', token);
    localStorage.setItem('userName', userInfo.name);
    localStorage.setItem('role', userInfo.role);
    localStorage.setItem('type', userInfo.type);
    if (userInfo.company && userInfo.company.type) {
      localStorage.setItem('ptype', userInfo.company.type);
    } else {
      localStorage.setItem('ptype', null);
    }
    localStorage.setItem('userId', userInfo.id);

    // Remove token and related data after 1 day
    setTimeout(() => {      
      localStorage.removeItem('token');
      localStorage.removeItem('userName');
      localStorage.removeItem('role');
      localStorage.removeItem('type');
      localStorage.removeItem('ptype');
      localStorage.removeItem('userId');
    }, TIMEOUT_DURATION);
    // resetLogoutTimer(); // Reset timer on login
  };
  

  const handleLogout = () => {

     // Revoke backend token
     const token = localStorage.getItem('token'); // Get the token from local storage
     if (token) {
       axios.post(`${BASE_URL}logout/`, null, {
         headers: {
           'Authorization': `Bearer ${token}`
         }
       })
       .then(response => {
         // Handle successful token revocation
        //  console.log('Token revoked successfully');
       })
       .catch(error => {
         // Handle error
         console.error('Error revoking token:', error);
       });
     }

    setToken(null);
    setUserName('');
    setRole('');
    setUserId('');
    // Remove token from local storage upon logout
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('role');
    localStorage.removeItem('type');
    localStorage.removeItem('ptype');
    localStorage.removeItem('userId');
    // clearTimeout(logoutTimer); // Clear timer on logout

  };

  // const resetLogoutTimer = () => {
  //   clearTimeout(logoutTimer);
  //   const timer = setTimeout(() => {
  //     handleLogout();
  //   }, TIMEOUT_DURATION);
  //   setLogoutTimer(timer);
  // };

  // useEffect(() => {
  //   const storedToken = localStorage.getItem('token');
  //   if (storedToken) {
  //     setToken(storedToken);
  //     setUserName(localStorage.getItem('userName') || '');
  //     setRole(localStorage.getItem('role') || '');
  //     setType(localStorage.getItem('type') || '');
  //     setUserId(localStorage.getItem('userId') || '');
  //     // resetLogoutTimer(); // Reset timer on initial load if user is logged in

  //   }
  // }, []);

  // const handleUserActivity = () => {
  //   resetLogoutTimer(); // Reset timer on user activity
  // };

  // useEffect(() => {
  //   // Listen to user activity events to reset the timer
  //   window.addEventListener('mousemove', handleUserActivity);
  //   window.addEventListener('keypress', handleUserActivity);
  //   window.addEventListener('click', handleUserActivity);

  //   return () => {
  //     window.removeEventListener('click', handleUserActivity);
  //     window.removeEventListener('mousemove', handleUserActivity);
  //     window.removeEventListener('keypress', handleUserActivity);
  //   };
  // }, []);

  return (
    <Router>
      <div>
        {/* <Navigation token={token} role={role} onLogout={handleLogout} /> Pass token and logout function to Navigation */}
        <Routes>
          <Route path="/activate/:token" element={!token ? <Activate /> : <Navigate to="/" />} />  
          <Route path="/" element={token ? <Navigate to="/dashboard" /> : <LoginPage onLogin={handleLogin} />} />
          <Route path="/dashboard" element={token ? <Dashboard userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />

          <Route path="/register" element={token && role === 'admin' ? <RegisterPage /> : <Navigate to="/" />} />
          <Route path="/users" element={token && role === 'admin' ? <Users userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />          
          <Route path="/partners" element={token && (role === 'admin' || type === 'partner-admin') ? <Partners userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />

          <Route path="/companies" element={token && role === 'admin' ? <Companies userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />
          <Route path="/contacts/leads" element={token ? <Leads userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />
          <Route path="/contacts/opportunities" element={token && (role === 'admin' || ptype === 'channel') ? <Opportunities userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />

          <Route path="/forgot-password" element={!token ? <ForgotPasswordPage/>: <Navigate to="/" />} />
          <Route path="/view-partner-leads/:id" element={token && (role === 'admin' || type === 'partner-admin') ? <ViewPartnerLeads userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />
          {/* <Route path="/contacts/edit-lead/:id" element={token && role === 'admin' ? <EditLead userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} /> */}
          {/* <Route path="/contacts/edit-opportunity/:id" element={token && role === 'admin' ? <EditOpportunity userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} /> */}

          <Route path="/edit-partner/:id" element={token && (role === 'admin' || type === 'partner-admin') ? <EditPartner userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />
          <Route path="/edit-company/:id" element={token && role === 'admin' ? <EditCompany userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />
          <Route path="/edit-user/:id" element={token && role === 'admin' ? <EditUser userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />
          <Route path="/edit-lead/:id" element={token ? <EditLead userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />
          <Route path="/edit-opportunity/:id" element={token && (role === 'admin' || ptype === 'channel') ? <EditOpportunity userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />
          <Route path="/payments" element={token && role === 'admin' ? <Payments userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />
          <Route path="/resources/upload" element={token && role === 'admin' ? <FileUpload userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />
          <Route path="/resources" element={token ? <Resources userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />
          <Route path="/resources/:id" element={token ? <SubResources userName={userName} role={role} onLogout={handleLogout} /> : <Navigate to="/" />} />

          
          <Route path="*" element={<Navigate to="/" />} />

        </Routes>
      </div>
    </Router>
  );
};

export default App;

