// EditOpprtunity.js
import React, { useEffect, useState } from 'react';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import { Link, useNavigate, useParams } from 'react-router-dom'; // Import useParams
import { BASE_URL, USER_ID, TOKEN } from '../../commonConstants';

const EditOpportunity = ({ userName, role, onLogout }) => {
    const navigate = useNavigate();
    const { id } = useParams(); // Get the id parameter from the URL

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [companyNameError, setCompanyNameError] = useState('');
    const [websiteError, setWebsiteError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [regionError, setRegionError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [leadTypeError, setLeadTypeError] = useState('');
    const [pipelineValueError, setPipelineValueError] = useState('');
    const [pipelineStageError, setPipelineStageError] = useState('');
    const [numUsersError, setNumUsersError] = useState('');
    const [billingAddressError, setBillingAddressError] = useState('');
    const [demoDateError, setDemoDateError] = useState('');
    const [demoStatusError, setDemoStatusError] = useState('');
    const [fadeOut, setFadeOut] = useState(false);

    // const [leadStatusError, setLeadStatusError] = useState('');

    const [loading, setLoading] = useState(true);
    // const [data, setData] = useState([]); // Define state variable to hold data?
    // const [data, setData] = useState({ lead_status: "" });


    const [leadData, setLeadData] = useState({
        fname: '',
        lname: '',
        email: '',
        company_name: '',
        phone_number: '',
        website: '',
        region: '',
        country: '',
        lead_type: '',
        lead_status: '',
        partner_associated: USER_ID,
        is_opportunity: 1,
        converted_by: '',
        converted_date: '',
        created_at: '',
        lead_notes: '',
        opportinies_notes: '',
        opportunity_status: 'pending'
    });


    const fetchData = async () => {
        try {
            const response = await fetch(`${BASE_URL}opportunities/${id}`, { // Use the id parameter in the URL
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${TOKEN}`,
                },
            });
            if (!response.ok) {
                navigate(`/contacts/opportunities`);
                throw new Error('Failed to fetch opportunity');
            }
            const responseData = await response.json();
            // console.log(responseData.data);
            setLeadData(responseData.data); // Set fetched data to state
            setLoading(false);
        } catch (error) {
            // console.error('Error fetching opportunities:', error);
            setError('Failed to fetch opportunities');
            window.scrollTo(0, 0);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [id]);

    useEffect(() => {
        let hideTimeout;
        let removeTimeout;

        if (success || error) {
            // Start fading out before removing
            hideTimeout = setTimeout(() => {
                setFadeOut(true);
            }, 2000); // Start fading out 0.5 seconds before removing

            // Remove the alert after the fade-out transition
            removeTimeout = setTimeout(() => {
                setSuccess(null);
                setError(null);
                setFadeOut(false); // Reset fadeOut for future alerts
            }, 5000); // Remove after 2 seconds
        }

        return () => {
            clearTimeout(hideTimeout);
            clearTimeout(removeTimeout);
        };
    }, [success, error]);


    const updateLead = async () => {
        if (!validateForm()) {
            return;
        }
        try {
            const response = await fetch(`${BASE_URL}contacts/${id}`, {
                method: 'PUT', // Use PUT method for updating
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${TOKEN}`,
                },
                body: JSON.stringify(leadData), // Send updated data in the request body
            });
            const responseData = await response.json();
            if (response.ok) {
                setError('');
                setSuccess('Opportunity Updated successfully');
                window.scrollTo(0, 0);
                fetchData();
            } else {
                // Handle login error (e.g., display error message)
                if (responseData.data.email) {
                    setError('');
                    setSuccess('');
                    setEmailError(responseData.data.email[0]);
                } else {
                    setSuccess('');
                    setError('Error updating partner ', error);
                    window.scrollTo(0, 0);
                }
            }
        } catch (error) {
            setSuccess('');
            setError('Error updating lead ', error);
            window.scrollTo(0, 0);
            // console.error('Error updating lead ', error);
        }
        window.scrollTo(0, 0);
    };

    const validateForm = () => {
        let isValid = true;
        if (!leadData.fname) {
            setFirstNameError('First name is required');
            isValid = false;
        } else {
            setFirstNameError('');
        }
        if (!leadData.lname) {
            setLastNameError('Last name is required');
            isValid = false;
        } else {
            setLastNameError('');
        }
        if (!leadData.email) {
            setEmailError('Email address is required');
        }
        else if (!isValidEmail(leadData.email)) {
            setEmailError('Valid email address is required');
            isValid = false;
        } else {
            setEmailError('');
        }

        if (!leadData.company_name) {
            setCompanyNameError('Company name is required');
            isValid = false;
        } else {
            setCompanyNameError('');
        }
        if (!leadData.website) {
            setWebsiteError('Website is required');
            isValid = false;
        } else {
            setWebsiteError('');
        }
        if (!leadData.phone_number) {
            setPhoneError('Phone Number is required');
            isValid = false;
        } else {
            setPhoneError('');
        }
        if (!leadData.region) {
            setRegionError('Region is required');
            isValid = false;
        } else {
            setRegionError('');
        }
        if (!leadData.country) {
            setCountryError('Region is required');
            isValid = false;
        } else {
            setCountryError('');
        }
        return isValid;
    };

    const isValidEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    return (
        <div className="sb-nav-fixed">
            <Navbar userName={userName} role={role} onLogout={onLogout} />

            <div id="layoutSidenav">
                <Sidebar role={role} onLogout={onLogout} page='opportunities' />
                {/* old content starts */}
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid position-relative px-4">
                            {success && <div className={`position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-success alert-dismissible  mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{success}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccess(null)}></button></div>}
                            {error && <div className={`position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-danger alert-dismissible mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{error}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setError(null)}></button></div>}
                            <div className="row align-items-center justify-content-between">
                                <div className="col-md-6">
                                    <h1 className="fs-4 mb-0">Edit Opportunity</h1>
                                </div>
                                {role == 'partner' &&
                                    <div className="col-md-6 text-end">
                                        <p className='mb-0'>Opportunity Status:
                                            <span className={`badge badge-pill p-2 ms-2 ${(() => {
                                                let tagClass;
                                                if (leadData.opportunity_status === null || leadData.opportunity_status === undefined) {
                                                    tagClass = 'bg-light text-dark'; // Set tagClass to an empty string if data is null or undefined
                                                } else {
                                                    switch (leadData.opportunity_status) {
                                                        case 'approved':
                                                            tagClass = 'bg-success';
                                                            break;
                                                        case 'pending':
                                                            tagClass = 'bg-warning text-dark';
                                                            break;
                                                        case 'rejected':
                                                            tagClass = 'bg-dark';
                                                            break;
                                                        case 'converted':
                                                            tagClass = 'bg-primary';
                                                            break;
                                                        default:
                                                            tagClass = 'bg-info';
                                                    }
                                                }
                                                return tagClass;
                                            })()}`}>
                                                {leadData.opportunity_status ? leadData.opportunity_status : "N/A"}
                                            </span>
                                        </p>
                                    </div>

                                }
                                {role == 'admin' &&
                                    <div className="col-md-2 ">
                                        <div className="form-floating">
                                            <select className={`form-select ${demoStatusError && 'is-invalid'}`} id="demoStatus" value={leadData.opportunity_status} onChange={(e) => { setLeadData({ ...leadData, opportunity_status: e.target.value }); setDemoStatusError(e.target.value.trim() === '' ? 'Opportunity status is required' : ''); }}>
                                                <option disabled value="">Select status</option>
                                                <option value="pending">Pending</option>
                                                <option value="approved">Approved</option>
                                            </select>
                                            <label htmlFor="demoStatus">Status</label>
                                            {demoStatusError && <div className="invalid-feedback">{demoStatusError}</div>}
                                        </div></div>
                                }
                            </div>

                            <form className="p-4 shadow-sm border my-4" onSubmit={(e) => { e.preventDefault(); updateLead(); }}>
                                {role == "admin" &&
                                    <div>
                                        <div className="row mb-4">
                                            <div className="col-md-6">
                                                <h5>Basic Details</h5>
                                            </div>

                                            <div className="col-md-6 text-end">
                                                {leadData.partner && leadData.partner.name && (
                                                    <span>Created : {leadData.partner.name}</span>
                                                )}
                                            </div>

                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-md-4">
                                                <div className="form-floating">
                                                    <input className={`form-control ${firstNameError ? 'is-invalid' : ''}`} id="inputFirstName" type="text" placeholder="Enter your first name" value={leadData.fname} onChange={(e) => { setLeadData({ ...leadData, fname: e.target.value }); setFirstNameError(e.target.value.trim() === '' ? 'First name is required' : ''); }} />
                                                    <label htmlFor="inputFirstName">First name</label>
                                                    {firstNameError && <div className="invalid-feedback">{firstNameError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-floating">
                                                    <input className={`form-control ${lastNameError ? 'is-invalid' : ''}`} id="inputLastName" type="text" placeholder="Enter your last name" value={leadData.lname} onChange={(e) => { setLeadData({ ...leadData, lname: e.target.value }); setLastNameError(e.target.value.trim() === '' ? 'Last name is required' : ''); }} />
                                                    <label htmlFor="inputLastName">Last name </label>
                                                    {lastNameError && <div className="invalid-feedback">{lastNameError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-floating">
                                                    <input className={`form-control ${emailError ? 'is-invalid' : ''}`} id="inputEmail" disabled type="email" placeholder="name@example.com" value={leadData.email} />
                                                    <label htmlFor="inputEmail">Email address</label>
                                                    {emailError && <div className="invalid-feedback">{emailError}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-4">

                                            <div className="col-md-4">
                                                <div className="form-floating">
                                                    <input className={`form-control ${companyNameError ? 'is-invalid' : ''}`} id="inputCompany" type="text" placeholder="Enter your company name" value={leadData.company_name} onChange={(e) => { setLeadData({ ...leadData, company_name: e.target.value }); setCompanyNameError(e.target.value.trim() === '' ? 'Company Name is required' : ''); }} />
                                                    <label htmlFor="inputCompany">Company</label>
                                                    {companyNameError && <div className="invalid-feedback">{companyNameError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-floating">
                                                    <input className={`form-control ${phoneError ? 'is-invalid' : ''}`} id="inputPhoneNumber" type="tel" placeholder="Enter your phone number" value={leadData.phone_number} onChange={(e) => { setLeadData({ ...leadData, phone_number: e.target.value }); setPhoneError(e.target.value.trim() === '' ? 'Phone Number is required' : ''); }} />
                                                    <label htmlFor="inputPhoneNumber">Phone Number</label>
                                                    {phoneError && <div className="invalid-feedback">{phoneError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-floating">
                                                    <input className={`form-control ${websiteError ? 'is-invalid' : ''}`} id="inputWebsite" type="text" placeholder="Enter website" value={leadData.website} onChange={(e) => { setLeadData({ ...leadData, website: e.target.value }); setWebsiteError(e.target.value.trim() === '' ? 'Website is required' : ''); }} />
                                                    <label htmlFor="inputWebsite">Website</label>
                                                    {websiteError && <div className="invalid-feedback">{websiteError}</div>}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row mb-4">
                                            <div className="col-md-4">
                                                <div className="form-floating">
                                                    <input className={`form-control ${regionError ? 'is-invalid' : ''}`} id="inputRegion" type="text" placeholder="Enter your region" value={leadData.region} onChange={(e) => { setLeadData({ ...leadData, region: e.target.value }); setRegionError(e.target.value.trim() === '' ? 'Region is required' : ''); }} />
                                                    <label htmlFor="inputRegion">Region</label>
                                                    {regionError && <div className="invalid-feedback">{regionError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-floating">
                                                    <input className={`form-control ${countryError ? 'is-invalid' : ''}`} id="inputCountry" type="text" placeholder="Enter your country" value={leadData.country} onChange={(e) => { setLeadData({ ...leadData, country: e.target.value }); setCountryError(e.target.value.trim() === '' ? 'Country is required' : ''); }} />
                                                    <label htmlFor="inputCountry">Country</label>
                                                    {countryError && <div className="invalid-feedback">{countryError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-floating">
                                                    <select className={`form-select${leadTypeError ? 'is-invalid' : ''}`} id="inputStatus" value={leadData.lead_type} onChange={(e) => { setLeadData({ ...leadData, lead_type: e.target.value }); }} >
                                                        <option value="" disabled>Select Type</option>
                                                        <option value="VSB (50 or fewer emp.)">VSB</option>
                                                        <option value="SMB (51-1000 emp.)">SMB</option>
                                                        <option value="Enterprise (>1000 emp.)">Enterprise</option>
                                                        <option value="UAE Lead">UAE Lead</option>
                                                    </select>
                                                    <label htmlFor="inputCreatedAt">Lead Type</label>
                                                    {leadTypeError && <div className="invalid-feedback">{leadTypeError}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        {(leadData.created_at_formated || leadData.converted_date || leadData.lead_status || leadData.converted_by) && (
                                            <div className="row py-4">


                                                {leadData.lead_status && leadData.is_lead == '1' && (
                                                    <div className="col-md-3">
                                                        <p>Lead Status: {leadData.lead_status}</p>
                                                    </div>
                                                )}
                                                <div className="col-md-3">
                                                    <p>Created Date: {leadData.created_at_formated}</p>
                                                </div>
                                                {(leadData.converted_date) && (
                                                    <div className="col-md-3">
                                                        <p>Converted Date: {leadData.converted_date}</p>
                                                    </div>
                                                )}
                                                {(leadData.converted_by) && (
                                                    <div className="col-md-3">
                                                        <p>Converted By: {leadData.converted_by}</p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {leadData.is_lead == '1' &&
                                            <div className="row mb-4">
                                                <div className="col-md-12">
                                                    <div className="form-floating">
                                                        <textarea className="form-control" id="inputLeadComments" placeholder="Enter lead comments" style={{ height: "100px" }} value={leadData.lead_notes || ''} onChange={(e) => { setLeadData({ ...leadData, lead_notes: e.target.value }); }}></textarea>
                                                        <label htmlFor="inputLeadComments">Lead Notes</label>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className="row mb-4">
                                            <div className="col-md-6">
                                                <h5>Opportunity Details</h5>
                                            </div>
                                        </div>



                                        <div className="row mb-4">
                                            <div className="col-md-4">
                                                <div className="form-floating">
                                                    <input className={`form-control ${pipelineValueError ? 'is-invalid' : ''}`} id="pipelineValue" type="text" placeholder="Pipeline Value" value={leadData.pipeline_value} onChange={(e) => { setLeadData({ ...leadData, pipeline_value: e.target.value }); setPipelineValueError(e.target.value.trim() === '' ? 'Pipeline Value is required' : ''); }} />
                                                    <label htmlFor="pipelineValue">Pipeline Value</label>
                                                    {pipelineValueError && <div className="invalid-feedback">{pipelineValueError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-floating">
                                                    <select className={`form-select ${pipelineStageError ? 'is-invalid' : ''}`} id="pipelineStage" placeholder="Pipeline Stage" value={leadData.pipeline_stage} onChange={(e) => { setLeadData({ ...leadData, pipeline_stage: e.target.value }); setLeadTypeError(e.target.value.trim() === '' ? 'Pipeline Value is required' : ''); }} >
                                                        <option value="">Select Stage</option>
                                                        <option value="no_show">No Show</option>
                                                        <option value="demo_set">Demo Set</option>
                                                        <option value="reschedule_demo">Reschedule Demo</option>
                                                        <option value="demo_complete">Demo Complete</option>
                                                        <option value="engaged">Engaged</option>
                                                        <option value="on_hold">on Hold</option>
                                                        <option value="proposal">Proposal</option>
                                                        <option value="sow_entimate">SOW + Estimate</option>
                                                        <option value="closed_won">Closed Won</option>
                                                        <option value="demolost_noshow">Demo Lost - No Show</option>
                                                        <option value="closed_lost">Closed Lost</option>
                                                        <option value="invalid">Invalid</option>
                                                        <option value="demo_cancelled">Demo Cancelled</option>
                                                    </select>
                                                    <label htmlFor="pipelineStage">Pipeline Stage</label>
                                                    {pipelineStageError && <div className="invalid-feedback">{pipelineStageError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-floating">
                                                    <select className={`form-select ${numUsersError ? 'is-invalid' : ''}`} id="numUsers" placeholder="Number of Users" value={leadData.no_of_users} onChange={(e) => { setLeadData({ ...leadData, no_of_users: e.target.value }); setNumUsersError(e.target.value.trim() === '' ? 'No Of Users is required' : ''); }} >
                                                        <option value="">Select Users</option>
                                                        <option value="1-50">1-50</option>
                                                        <option value="51-100">51-100</option>
                                                        <option value="101-300">101-300</option>
                                                        <option value="301-500">301-500</option>
                                                        <option value="500-1000">500-1000</option>
                                                        <option value="1000+">1000+</option>
                                                        <option value="10000+">10000+</option>
                                                    </select>

                                                    <label htmlFor="numUsers">Number of Users</label>
                                                    {numUsersError && <div className="invalid-feedback">{numUsersError}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-4">

                                            <div className="col-md-4">
                                                <div className="form-floating">
                                                    <input className={`form-control ${billingAddressError ? 'is-invalid' : ''}`} id="billingAddress" type="text" placeholder="Billing Address" value={leadData.billing_address} onChange={(e) => { setLeadData({ ...leadData, billing_address: e.target.value }); setBillingAddressError(e.target.value.trim() === '' ? 'Billing Address is required' : ''); }} />
                                                    <label htmlFor="billingAddress">Billing Address</label>
                                                    {billingAddressError && <div className="invalid-feedback">{billingAddressError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-floating">
                                                    <input className={`form-control ${demoDateError ? 'is-invalid' : ''}`} id="demoDate" type="date" placeholder="Billing Address" value={leadData.demo_date} onChange={(e) => { setLeadData({ ...leadData, demo_date: e.target.value }); setDemoDateError(e.target.value.trim() === '' ? 'Pipeline Value is required' : ''); }} />
                                                    <label htmlFor="demoDate">Demo Date</label>
                                                    {demoDateError && <div className="invalid-feedback">{demoDateError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-floating">

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                }
                                {role == "partner" &&
                                    <div>
                                        <div className="row pt-4 mb-4 mx-1 border border-sm rounded bg-light text-break">

                                            <div className="row mb-4">
                                                <div className="col-4">
                                                    <h5>Basic Details</h5>
                                                </div>
                                            </div>
                                            {leadData.fname &&
                                                <div className="col-4 mb-4 gap-2 d-flex">
                                                    <label className="form-label mb-0">First Name:</label>
                                                    <p className="mb-0 fw-medium">{leadData.fname}</p>
                                                </div>
                                            }
                                            {leadData.lname &&
                                                <div className="col-4 mb-4 gap-2 d-flex">
                                                    <label className="form-label mb-0">Last Name:</label>
                                                    <p className="mb-0 fw-medium">{leadData.lname}</p>
                                                </div>
                                            }


                                            {leadData.email &&
                                                <div className="col-4 mb-4 gap-2 d-flex">
                                                    <label className="form-label mb-0">Email Address:</label>
                                                    <p className="mb-0 fw-medium">{leadData.email}</p>
                                                </div>}
                                            {leadData.company_name &&
                                                <div className="col-4 mb-4 gap-2 d-flex">
                                                    <label className="form-label mb-0">Company:</label>
                                                    <p className="mb-0 fw-medium">{leadData.company_name}</p>
                                                </div>}
                                            {leadData.phone_number &&
                                                <div className="col-4 mb-4 gap-2 d-flex">
                                                    <label className="form-label mb-0">Phone Number:</label>
                                                    <p className="mb-0 fw-medium">{leadData.phone_number}</p>
                                                </div>
                                            }
                                            {leadData.website &&
                                                <div className="col-4 mb-4 gap-2 d-flex">
                                                    <label className="form-label mb-0">Website:</label>
                                                    <p className="mb-0 fw-medium">{leadData.website}</p>
                                                </div>
                                            }
                                            {leadData.region &&
                                                <div className="col-4 mb-4 gap-2 d-flex">
                                                    <label className="form-label mb-0">Region:</label>
                                                    <p className="mb-0 fw-medium">{leadData.region}</p>
                                                </div>
                                            }

                                            {leadData.country &&
                                                <div className="col-4 mb-4 gap-2 d-flex">
                                                    <label className="form-label mb-0">Country:</label>
                                                    <p className="mb-0 fw-medium">{leadData.country}</p>
                                                </div>
                                            }
                                            {leadData.lead_type &&
                                                <div className="col-4 mb-4 gap-2 d-flex">
                                                    <label className="form-label mb-0">Lead Type:</label>
                                                    <p className="mb-0 fw-medium">{leadData.lead_type}</p>
                                                </div>
                                            }

                                            {(leadData.created_at_formated || leadData.converted_date || leadData.lead_status || leadData.converted_by) && (
                                                <div className="row mb-4">

                                                    {leadData.lead_status && leadData.is_lead == '1' && (

                                                        <div className="col-4 mb-4 gap-2 d-flex">
                                                            <p>Lead Status: {leadData.lead_status}</p>
                                                        </div>
                                                    )}
                                                    <div className="col-4 mb-4 gap-2 d-flex">
                                                        <p>Created Date: {leadData.created_at_formated}</p>
                                                    </div>

                                                    {(leadData.converted_date) && (
                                                        <div className="col-4 mb-4 gap-2 d-flex">
                                                            <p>Converted Date: {leadData.converted_date}</p>
                                                        </div>
                                                    )}
                                                    {(leadData.converted_by) && (
                                                        <div className="col-4 mb-4 gap-2 d-flex">
                                                            <p>Converted By: {leadData.converted_by}</p>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            <div className="row mb-4">
                                                <div className="col-4">
                                                    <h5>Opportunity Details</h5>
                                                </div>
                                            </div>

                                            {leadData.pipeline_value &&
                                                <div className="col-4 mb-4 gap-2 d-flex">
                                                    <label className="form-label mb-0">Pipeline Value:</label>
                                                    <p className="mb-0 fw-medium">{leadData.pipeline_value}</p>
                                                </div>}
                                            {leadData.pipeline_stage &&
                                                <div className="col-4 mb-4 gap-2 d-flex">
                                                    <label className="form-label mb-0">Pipeline Stage:</label>
                                                    <p className="mb-0 fw-medium">{leadData.pipeline_stage}</p>
                                                </div>}
                                            {leadData.no_of_users &&
                                                <div className="col-4 mb-4 gap-2 d-flex">
                                                    <label className="form-label mb-0">No Of Users:</label>
                                                    <p className="mb-0 fw-medium">{leadData.no_of_users}</p>
                                                </div>}
                                            {leadData.billing_address &&
                                                <div className="col-4 mb-4 gap-2 d-flex">
                                                    <label className="form-label mb-0">Billing Address:</label>
                                                    <p className="mb-0 fw-medium">{leadData.billing_address}</p>
                                                </div>}
                                            {leadData.demo_date &&
                                                <div className="col-4 mb-4 gap-2 d-flex">
                                                    <label className="form-label mb-0">Demo Date:</label>
                                                    <p className="mb-0 fw-medium">{leadData.demo_date}</p>
                                                </div>}
                                            {leadData.opportunity_status &&
                                                <div className="col-4 mb-4 gap-2 d-flex">
                                                    <label className="form-label mb-0">Opportunity Status:</label>
                                                    <p className="mb-0 fw-medium">{leadData.opportunity_status}</p>
                                                </div>}

                                        </div>
                                    </div>
                                }
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <div className="form-floating">
                                            <textarea className="form-control" id="inputLeadComments" placeholder="Enter Opportunity comments" style={{ height: "100px" }} value={leadData.opportinies_notes || ''} onChange={(e) => { setLeadData({ ...leadData, opportinies_notes: e.target.value }); }}></textarea>
                                            <label htmlFor="inputLeadComments">Opportunity Notes</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-12 text-end">
                                        <Link className="btn btn-link text-decoration-none" aria-current="page" to="/contacts/opportunities">Cancel</Link>
                                        <button type="submit" className="btn btn-primary mx-2">Update</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </main>
                </div>
                {/* old content ends */}
            </div>
        </div>
    );
};

export default EditOpportunity;
