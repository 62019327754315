// EditPartner.js
import React, { useEffect, useState } from 'react';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import { Link, useNavigate, useParams } from 'react-router-dom'; // Import useParams
import { BASE_URL,TOKEN } from '../../commonConstants'; 


const EditUser = ({ userName, role, onLogout }) => {
    const navigate = useNavigate();
    const { id } = useParams(); // Get the id parameter from the URL

    const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);
	const [nameError, setNameError] = useState('');
	const [emailError, setEmailError] = useState('');
	const [loading, setLoading] = useState(true);
	const [fadeOut, setFadeOut] = useState(false);


    const [partnerData, setPartnerData] = useState({
        name: '',
        email: '',
        company_name: '',
        website: '',
        status: '',
        created_at: ''
    });

    // Dynamic options for status select
    let statusOptions = [
        { value: 'active', label: 'Active' },
        { value: 'suspended', label: 'Block' },
        { value: 'pending', label: 'Pending'},
    ];
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${BASE_URL}users/edit/${id}`, { // Use the id parameter in the URL
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${TOKEN}`,
                    },
                    body: JSON.stringify({}),
                });
                if (!response.ok) {
                    navigate(`/users/`);
                    throw new Error('Failed to fetch partner');
                }
                const responseData = await response.json();
                setPartnerData(responseData.data); 
               
                setLoading(false);
            } catch (error) {
                // console.error('Error fetching users:', error);
                setError('Failed to fetch users');
                setLoading(false);
            }
        };

        fetchData();
    }, [id]); 

    useEffect(() => {
		let hideTimeout;
		let removeTimeout;
	
		if (success || error) {
		  // Start fading out before removing
		  hideTimeout = setTimeout(() => {
			setFadeOut(true);
		  }, 2000); // Start fading out 0.5 seconds before removing
	
		  // Remove the alert after the fade-out transition
		  removeTimeout = setTimeout(() => {
			setSuccess(null);
			setError(null);
			setFadeOut(false); // Reset fadeOut for future alerts
		  }, 5000); // Remove after 2 seconds
		}
	
		return () => {
		  clearTimeout(hideTimeout);
		  clearTimeout(removeTimeout);
		};
	  }, [success, error]);

    const updatePartner = async () => {
        if (!validateForm()) {
            return;
          }
        try {
            const response = await fetch(`${BASE_URL}users/update/${id}`, { 
                method: 'POST', // Use PUT method for updating
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${TOKEN}`,
                },
                body: JSON.stringify(partnerData), // Send updated data in the request body
            });
			const responseData = await response.json();
            if (response.ok) {
				setError('');
				setSuccess('User Updated successfully');
			} else {
                setSuccess('');
				// Handle login error (e.g., display error message)
				if(responseData.data.email){
					setEmailError(responseData.data.email[0]);
				} else{
					setError('Error updating partner ', error);
				}
			}
            // Optionally, you can handle success (e.g., show a success message or navigate to another page)
            // console.log('User updated successfully');
        } catch (error) {
            setError('Error Updating User:', error);
            // console.error('Error Updating User:', error);
            // Optionally, you can set an error state to display an error message to the user
        }
        window.scrollTo(0, 0);
    };
    // Handler for updating partner status
    const handleStatusChange = (e) => {
        setPartnerData({ ...partnerData, status: e.target.value });
    };

    const validateForm = () => {
		let isValid = true;
		if (!partnerData.name) {			
			setNameError('User name is required');
			isValid = false;
		} else {
			setNameError('');
		}
		if(!partnerData.email){
			setEmailError('Email address is required');
		}
		else if (!isValidEmail(partnerData.email)) {
			setEmailError('Valid email address is required');
			isValid = false;
		} else {
			setEmailError('');
		}	
		return isValid;
	};
	
	const isValidEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

   if (partnerData.email_verified_at === null) {
    statusOptions = statusOptions.filter(option => option.value !== 'active');
    }

    return (
        <div className="sb-nav-fixed">
            <Navbar userName={userName} role={role} onLogout={onLogout} />
            <div id="layoutSidenav">
                <Sidebar role={role} onLogout={onLogout} page="users"/>
                <div id="layoutSidenav_content">
                <main>
                <div className="container-fluid position-relative px-4">
                       
                       {success && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-success alert-dismissible  mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{success}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setSuccess(null)}></button></div>}
                       {error && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-danger alert-dismissible mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{error}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setError(null)}></button></div>}
                        <div className="row align-items-center justify-content-center">
                                <div className="col-md-12">
                                    <h1 className="fs-4 mb-0">Edit User</h1>
                                </div>
                            </div>
                            <form className="p-4 shadow-sm border my-4" onSubmit={(e) => { e.preventDefault(); updatePartner(); }}>
                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input className={`form-control ${nameError ? 'is-invalid' : ''}`} id="inputName" type="text" placeholder="User Name" value={partnerData.name} onChange={(e) => { setPartnerData({ ...partnerData, name: e.target.value });  setNameError(e.target.value.trim() === '' ? 'User name is required' : '');
                                        }} />
                                            <label htmlFor="inputName">User Name</label>
                                            {nameError && <div className="invalid-feedback">{nameError}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input className={` form-control ${emailError ? 'is-invalid' : ''}`} id="inputEmail" disabled type="email" placeholder="name@example.com" value={partnerData.email}  />
                                            <label htmlFor="inputEmail">Email address</label>
                                            {emailError && <div className="invalid-feedback">{emailError}</div>}
                                        </div>
                                    </div>                                    
                                </div>
                                <div className="row mb-4">
                    
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <select className="form-select" id="inputStatus" value={partnerData.status || ''} onChange={handleStatusChange}>
                                                {statusOptions.map(option => (
                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                            <label htmlFor="inputCommission">Lead Status</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">                                           
                                           {(partnerData.email_verified_at) ? (<span>Activated At : {partnerData.email_verified_at}</span>) : ''}
                                        </div>
                                    </div>  
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        <div className="form-floating">                                           
                                            <span>Created Date : </span>{(partnerData.created_at_formated) || ''}
                                        </div>
                                    </div>   
                                    <div className="col-md-6 text-end">
                                    <Link className="btn btn-link text-decoration-none" aria-current="page" to="/users">Cancel</Link>
                                        <button type="submit" className="btn btn-primary mx-2">Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default EditUser;
