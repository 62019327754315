import React, { useState } from 'react';
import axios from 'axios'; // Import axios for making HTTP requests
import { BASE_URL } from '../../commonConstants'; 

const RegisterPage = () => {
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    company: '',
    website: '',
    commission: ''
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser(prevUser => ({
      ...prevUser,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make an API call to register the new user
      const response = await axios.post(`${BASE_URL}register`, newUser);
      // If registration is successful, notify the user and send activation email
      alert('Registration successful. An activation email has been sent to your email address.');
      // Clear the form after successful registration
      setNewUser({
        name: '',
        email: '',
        company: '',
        website: '',
        commission: ''
      });
    } catch (error) {
      console.error('Registration error:', error);
      // Handle registration error (e.g., display an error message)
      setError('Registration failed. Please try again later.');
    }
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <h2>Register New User</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input type="text" name="name" value={newUser.name} onChange={handleChange} required />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" name="email" value={newUser.email} onChange={handleChange} required />
        </div>
        <div>
          <label>Company:</label>
          <input type="text" name="company" value={newUser.company} onChange={handleChange} required />
        </div>
        <div>
          <label>Website:</label>
          <input type="url" name="website" value={newUser.website} onChange={handleChange} required />
        </div>
        <div>
          <label>Commission percentage:</label>
          <input type="number" name="commission" value={newUser.commission} onChange={handleChange} required />
        </div>
        <button type="submit">Register</button>
      </form>
      {error && <p>{error}</p>}
    </div>
  );
};

export default RegisterPage;
