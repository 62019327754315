// LoginPage.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import { BASE_URL } from './commonConstants'; 

const LoginPage = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  
  // Function to validate email format
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value.trim());
    setEmailError(value.trim() === '' ? 'Email address is required' : !validateEmail(value.trim()) ? 'Invalid email format' : '');
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value.trim());
    setPasswordError(value.trim() === '' ? 'Password is required' : '');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!emailError && !passwordError) {
    // Reset error messages
    setEmailError('');
    setPasswordError('');
    setError('');

    // Basic form validation  
    if(!email && !password) {
      setEmailError('Email address is required');
      setPasswordError('Password is required');
      return;
    }
    if (!email) {
      setEmailError('Email address is required');
      return;
    } else if (!validateEmail(email)) {
      setEmailError('The email address you entered is incorrect');
      return;
    }
    if (!password) {
      setPasswordError('Password is required');
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      if (response.ok) {
        // Store token and user information in local storage
        localStorage.setItem('token', data.data.token);
        localStorage.setItem('userInfo', JSON.stringify(data.data.user));

        const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds
        const tokenExpiry = new Date().getTime() + oneWeekInMilliseconds; // Calculate expiry timestamp
        localStorage.setItem('tokenExpiry', tokenExpiry); // Store expiry timestamp

        // Update token state on successful login and pass user information
        onLogin(data.data.token, data.data.user);
      } else {

        // Handle login error (e.g., display error message)
        setError(data.message || 'Login failed');
      }
    } catch (error) {
      // console.error('Login error:', error);
      // Handle network error or other exceptions
      setError('An unexpected error occurred');
    }
}
  };

  return (
    <section className="login">
      <div className="container-fluid">
        <div className="row" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center', alignContent: 'stretch' }}>
          <div className="banner col-lg-6 col-md-5 profit-blue min-vh-100 text-center text-white"
            style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center', alignContent: 'stretch' }}>
            <div><img src="../assets/images/partnership_portal.png" alt="partnership_portal"
              width="588" height="452" />
              <div className="w-75 m-auto">
                <h1>Empowering Partnerships for Mutual Success</h1>
                <div className="w-75 m-auto">
                  <figure className="text-center mt-4">
                    <blockquote className="blockquote">
                      <i>Alone we can do so little;<br /> together we can do so much.</i>
                    </blockquote>
                    <figcaption className="blockquote-author">
                      - Helen Keller
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-7 col-sm-8">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8 col-md-10">
                <form onSubmit={handleSubmit}>
                  <img className="mb-4" src="../assets/images/logo.svg" alt="logo" width="255" height="33" />
                  <h1 className="h3 mb-4 fw-bolder profit-clr-blue">Login</h1>
                  {error && <div className="alert alert-danger alert-dismissible fade show" role="alert">{error}</div>}
                  <div className="form-floating mb-4 text-muted">
                    <input type="email" className={`form-control ${emailError && 'is-invalid'}`} id="floatingInput" value={email} onChange={handleEmailChange} placeholder="name@example.com" />
                    <label htmlFor="floatingInput">Email Address *</label>
                    {emailError && <div className="invalid-feedback">{emailError}</div>}
                  </div>
                  <div className="form-floating mb-4 text-muted position-relative">
                    <input type={showPassword ? 'text' : 'password'} className={`form-control ${passwordError && 'is-invalid'}`} id="floatingPassword" value={password}  onChange={handlePasswordChange} placeholder="Password" />
                    <label htmlFor="floatingPassword">Password *</label>
                    {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                    <button type="button" className={"btn btn-link " + (showPassword ? "hide-password " : "show-password")} onClick={togglePasswordVisibility}>
                    {showPassword ? (
                      <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      ) : (
                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      )}
                    </button>
                  </div>
                  <div className="checkbox d-flex justify-content-between mb-4">
                    <label className="d-none"><input className="me-2" type="checkbox"
                      value="remember-me" />Remember me</label>
                      
                    <p><u><Link to="/forgot-password">Forgot Password?</Link></u></p>
                  </div>
                  <button className="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default LoginPage;
