// ForgotPasswordPage.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import { BASE_URL } from './commonConstants'; 

const ForgotPasswordPage = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

 
  // Function to validate email format
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setEmailError(value.trim() === '' ? 'Email address is required' : '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    // Reset error messages
    setEmailError('');
    setError('');

    
    if (!email) {
      setEmailError('Email address is required');
      return;
    } else if (!validateEmail(email)) {
      setEmailError('The email address you entered is incorrect');
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}sendactivationlink`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (response.ok) {
        setSuccess(data.message || 'The Forgot Password email sent to user');
    
      } else {
        // console.log('else -> ');
        // Handle login error (e.g., display error message)
        setError(data.error || 'This email id has not been enabled as a user');
      }
    } catch (error) {
      // console.error('Forgot password error:', error);
      // Handle network error or other exceptions
      setError('An unexpected error occurred');
    }
  };

  return (
    <section className="forget-password">
            <div className="container-fluid">
                <div className="row profit-bg1 justify-content-center align-items-center align-content-stretch">
                    <div className="banner col-lg-6 col-md-5 min-vh-100"
                        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center', alignContent: 'stretch' }}>
                        <div className="row justify-content-center">
                            <div className="col-xl-12">
                              <div className="card shadow-sm p-5" style={{ minWidth: '500px', maxWidth: '500px' }}>
                                    <form className="text-center" onSubmit={handleSubmit}>
                                    <img className="mb-4" src="../assets/images/logo.svg" alt="logo" width="255" height="33" />
                                        <h4 className="mb-2">Forgot Password?</h4>
                                        {error && <div class="alert alert-danger alert-dismissible fade show" role="alert">{error}</div>}
                                        {success && <div class="alert alert-success alert-dismissible fade show" role="alert">{success}</div>}
                                        <div className="form-floating mb-4 text-muted">
                                          <input type="email" className={`form-control ${emailError && 'is-invalid'}`} id="floatingInput" value={email} onChange={handleEmailChange} placeholder="name@example.com" />
                                          <label htmlFor="floatingInput">Email Address *</label>
                                          {emailError && <div className="invalid-feedback">{emailError}</div>}
                                        </div>
                                        <button className="w-100 btn btn-lg btn-primary" type="submit">Send</button>
                                        <div className="back-to-login mt-3"><u><Link to="/">Back to Login</Link></u></div>
                                    </form>          

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  );
};

export default ForgotPasswordPage;
