import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import $ from 'jquery';
import 'bootstrap';
import Modal from 'react-bootstrap/Modal';
import 'datatables.net-bs5';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, TOKEN } from '../../commonConstants'; 
import axios from 'axios';


const Users = ({ userName, role, onLogout }) => {
	const [success, setSuccess] = useState(false);
	const [fadeOut, setFadeOut] = useState(false);
	const [error, setError] = useState(null);
	const [nameError, setNameError] = useState('');
	const [emailError, setEmailError] = useState('');
	const [companyNameError, setCompanyNameError] = useState('');
	const [websiteError, setWebsiteError] = useState('');
	const [commissionError, setCommissionError] = useState('');
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]); // Define state variable to hold data
	const [show, setShow] = useState(false);

	const navigate = useNavigate();
	

	// Define editUser functions within useEffect
	const editUser = (pid) => {
		navigate(`/edit-user/${pid}`);
	};

	const [userData, setUserData] = useState({
		name: '',
		email: '',
		role: 'admin'
	});

	const handleClose = () => {
		setShow(false);
		setSuccess('');
		setError('');
		resetForm();
		resetFormValidation();
	};

	const handleShow = () => {
		setShow(true);
		setSuccess('');
		setError('');
		resetForm();
		resetFormValidation();
	};

	const resetForm = () => {
		setUserData({
			name: '',
			email: '',
			role: 'admin'			
		});
	};

	const fetchData = async (source) => {
		try {
			const response = await axios.post(`${BASE_URL}users/admin`, {}, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				cancelToken: source.token,
			});
			// if (!response.ok) {
			// 	throw new Error('Failed to fetch users');
			// }
			const responseData = response.data;
			setData(responseData.data); // Set fetched data to state

			// Destroy existing DataTable instance if it exists
			if ($.fn.DataTable.isDataTable('#datatablesSimple')) {
				$('#datatablesSimple').DataTable().destroy();
			}

			$('#datatablesSimple').DataTable({
				data: responseData.data,
				columns: [
					{ data: 'id', defaultContent: '' },
					{ data: 'name' },
					{ data: 'email' },
					{
						data: 'status',
						render: function (data) {
							// Assuming data contains the status value (e.g., 'active', 'pending', etc.)
							// Define the Bootstrap tag class based on the status value
							let tagClass;
							switch (data) {
								case 'active':
									tagClass = 'badge badge-pill bg-success';
									break;
								case 'pending':
									tagClass = 'badge badge-pill bg-warning text-dark';
									break;
								case 'suspended':
									tagClass = 'badge badge-pill bg-dark';
									break;
								// Add more cases for other status values if needed
								default:
									tagClass = 'badge badge-pill bg-primary';
							}

							// Construct the Bootstrap tag with the appropriate class and status text
							const tagMarkup = `<span class="${tagClass}">${data}</span>`;

							// Return the HTML markup for the Bootstrap tag
							return tagMarkup;
						}
					},
					{
						data: 'created_at_formated',
						render: function (data) {

							return data;
						}
					},
					{
						data: 'id',
						render: function (pid) {
							return `
							<div class="btn-group btn-group-sm" role="group" aria-label="Small button group">
							<button class="btn btn-outline-secondary editUser" >Edit</a>
							</div>
							`;
						}
					}
				],
				order: [0, 'desc'], // Disable initial sorting
				createdRow: function (row, data, dataIndex) {
					// Bind click event to the buttons inside the created row
					$(row).find('.editUser').on('click', function () {
						editUser(data.id);
					});
				}
			});
			setLoading(false);
		} catch (error) {
			if (axios.isCancel(error)) {
				// console.log('Request cancelled');
			} else {
				if(error.response.status == 401){
                    onLogout();
                }
				setLoading(false);
				// handle other errors
			}
		}
	};

	useEffect(() => {
		const source = axios.CancelToken.source();
		fetchData(source);
		return () => {
			source.cancel('Operation canceled by the user.');
		};
	}, []);

	useEffect(() => {
		let hideTimeout;
		let removeTimeout;
	
		if (success || error) {
		  // Start fading out before removing
		  hideTimeout = setTimeout(() => {
			setFadeOut(true);
		  }, 2000); // Start fading out 0.5 seconds before removing
	
		  // Remove the alert after the fade-out transition
		  removeTimeout = setTimeout(() => {
			setSuccess(null);
			setError(null);
			setFadeOut(false); // Reset fadeOut for future alerts
		  }, 5000); // Remove after 2 seconds
		}
	
		return () => {
		  clearTimeout(hideTimeout);
		  clearTimeout(removeTimeout);
		};
	  }, [success, error]);
	  

	const handleSubmit = async () => {		
		if (!validateForm()) {
		  return;
		}
		try {
			const response = await fetch(`${BASE_URL}register/`, {
				method: 'POST', // Use PUT method for updating
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				body: JSON.stringify(userData), // Send updated data in the request body
			});
			const responseData = await response.json();
			if (response.ok) {
				setError('');
				setSuccess('User Created & Activation link Sent');
				const source = axios.CancelToken.source();
				fetchData(source);
				resetForm();
				setShow(false);
			} else {
				// Handle login error (e.g., display error message)
				if(responseData.data.email){
					setEmailError(responseData.data.email[0]);
					setSuccess('');
					setError('');
				} else{
					setError('Error updating user ', error);
				}
			}			
			// console.log('User created successfully');
		} catch (error) {
			setSuccess('');
			setError('Error Creating User:', error);
			console.error('Error  User:', error);
		}
		window.scrollTo(0, 0);
	};

	const validateForm = () => {
		let isValid = true;
		if (!userData.name) {			
			setNameError('Name is required');
			isValid = false;
		} else {
			setNameError('');
		}
		if(!userData.email){
			setEmailError('Email address is required');
		}
		else if (!isValidEmail(userData.email)) {
			setEmailError('Valid email address is required');
			isValid = false;
		} else {
			setEmailError('');
		}
	
		return isValid;
	};
	
	const resetFormValidation = () => {
		setNameError('');
		setEmailError('');

	};
	const isValidEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	return (
		<div className="sb-nav-fixed">
			<Navbar userName={userName} role={role} onLogout={onLogout} />
			<div id="layoutSidenav">
				<Sidebar role={role} onLogout={onLogout} page='users' />
				<div id="layoutSidenav_content">
					<main>
						<div className="container-fluid position-relative px-4">						
								 {success && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-success alert-dismissible  mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{success}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setSuccess(null)}></button></div>}
								{error && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-danger alert-dismissible mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{error}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setError(null)}></button></div>}
								 
							<div className="row align-items-center justify-content-between ">
								<div className="col-md-2">
									<h1 className="fs-4 mb-0">Admins</h1>
								</div>
								<div className="col-md-2 text-end">
									<button className="btn btn-primary" onClick={handleShow}>+ Add Admin</button>
									<Modal show={show} >
										<div className="modal-content" style={{ animation: 'slideFromRight 0.3s forwards' }}>
											<Modal.Header >
												<Modal.Title>Register Admin</Modal.Title>
												<button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
											</Modal.Header>
											<Modal.Body >
												{success && <div className="alert alert-success alert-dismissible fade show w-50" role="alert">{success}<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>}										
												{error && <div className="alert alert-danger alert-dismissible fade show" role="alert">{error}</div>}
												<form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
													<div className="row mb-4">
														<div className="col-md-12">
															<div className="form-floating">
																<input className={`form-control ${nameError ? 'is-invalid' : ''}`} id="inputName" type="text" placeholder="Name" value={userData.name} onChange={(e) => { setUserData({ ...userData, name: e.target.value });  setNameError(e.target.value.trim() === '' ? 'Name is required' : '');
															}} />
																<label htmlFor="inputName">Name</label>
																{nameError && <div className="invalid-feedback">{nameError}</div>}
															</div>
														</div>
													</div>
													<div className="row mb-4">
														<div className="col-md-12">
															<div className="form-floating">
																<input className={`form-control ${emailError ? 'is-invalid' : ''}`} id="inputEmail" type="email" placeholder="name@example.com" value={userData.email} onChange={(e) => { setUserData({ ...userData, email: e.target.value }); setEmailError(e.target.value.trim() === '' ? 'Email address is required' : ''); }} />
																<label htmlFor="inputEmail">Email address</label>
																{emailError && <div className="invalid-feedback">{emailError}</div>}
															</div>
														</div>
													</div>
													
													<div className="row mb-4">
														<div className="col-md-12 text-end">
															<button className="btn" type="button" onClick={handleClose}>Cancel</button>
															<button type="submit" className="btn btn-primary mx-2">Register & Send Activation link</button>
														</div>
													</div>
												</form>
											</Modal.Body>
										</div>
									</Modal>
								</div>
							</div>
							
							{error && <p>{error}</p>}
							{/* {!loading && !error && ( */}
								<div className="card my-4">
									<div className="card-body">
										<table id="datatablesSimple" className="table table-bordered text-break">
											<thead className='table-primary'>
												<tr>
													<th>ID</th>
													<th>Name</th>
													<th>Email</th>
													<th>Status</th>
													<th>Created Date</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody><tr><td colSpan="8">{loading ? (  "Loading..." ) :( "No Records Found" ) }</td></tr></tbody>										
										</table>
									</div>
								</div>
							{/* // )} */}
						</div>
					</main>
				</div>
			</div>
		</div>
	);
};

export default Users;
