
let TOKEN = localStorage.getItem('token') !== null ? localStorage.getItem('token') : "";

let USER_ID = localStorage.getItem('userId') !== null ? localStorage.getItem('userId') : "";

function setToken(newToken) {
  TOKEN = newToken;
}
function setUserId(newUserId) {
  USER_ID = newUserId;
}


const BASE_URL = 'https://partners-api.profitapps.co/api/'; // laravel Backend URL for GCP

// const BASE_URL = 'https://stag-laravel.profitokr.net/api/'; // laravel Backend URL Staging
// const BASE_URL = 'http://localhost:8000/api/'; // laravel Backend URL Localhost

export { BASE_URL, USER_ID, TOKEN, setToken, setUserId };

