// Dashboard.js
import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link, useNavigate } from 'react-router-dom'; // Import useParams
import { BASE_URL, USER_ID, TOKEN } from './commonConstants';
import axios from 'axios';


const Dashboard = ({ userName, role, onLogout }) => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]); // Define state variable to hold data
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Define editPartner and viewPartnerLeads functions within useEffect
    const editLead = (pid) => {
        navigate(`/edit-lead/${pid}`);
    };

    let fetchDataapiUrl = ``;
    if (role == 'partner') {
        fetchDataapiUrl = `${BASE_URL}dashboard/partner/${USER_ID}`;
    }
    if (role === 'admin') {
        fetchDataapiUrl = `${BASE_URL}dashboard/admin`;
    }


    const fetchData = async (source) => {
        try {
			const response = await axios.get(fetchDataapiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${TOKEN}`,
                },
                cancelToken: source.token,
            });
			if (response.status !== 200) {
				throw new Error('Failed to fetch users');
			}           
			const responseData = response.data;
            // console.log(responseData);

            setData(responseData.data); // Set fetched data to state

            // Destroy existing DataTable instance if it exists
            if ($.fn.DataTable.isDataTable('#datatablesSimple')) {
                $('#datatablesSimple').DataTable().destroy();
            }

            // Define the table columns based on the user's role
            let columns = [
                { data: 'id', defaultContent: '' },
                { data: 'fname' },
                { data: 'lname' },
                { data: 'email' },
                { data: 'company_name' },
            ];
            const hasPartnerName = responseData.data.leads.some(entry => entry.partner && entry.partner.name);

            if (role == 'admin' && hasPartnerName) {
                columns.push({ data: 'partner.name' });
            }
            columns.push(
                {
                    data: 'lead_status',
                    render: function (data) {
                        // Assuming data contains the status value (e.g., 'active', 'pending', etc.)
                        // Define the Bootstrap tag class based on the status value
                        let tagClass;
                        switch (data.toLowerCase()) {
                            case 'approved':
                                tagClass = 'badge badge-pill bg-success';
                                break;
                            case 'pending':
                                tagClass = 'badge badge-pill bg-warning text-dark';
                                break;
                            case 'rejected':
                                tagClass = 'badge badge-pill bg-dark';
                                break;
                            case 'converted':
                                tagClass = 'badge badge-pill bg-primary';
                                break;
                            default:
                                tagClass = 'badge badge-pill bg-info';
                        }

                        // Construct the Bootstrap tag with the appropriate class and status text
                        const tagMarkup = `<span class="${tagClass}">${data}</span>`;

                        // Return the HTML markup for the Bootstrap tag
                        return tagMarkup;
                    }
                },
                {
                    data: 'created_at_formated',
                    render: function (data) {

                        return data;
                    }
                },
                {
                    data: 'id',
                    render: function (pid) {
                        return `
          <div class="btn-group btn-group-sm" role="group" aria-label="Small button group">
            <button class="btn btn-outline-secondary  editLead" >Edit</a>					
          </div>
          `;
                    }
                }
            );

            $('#datatablesSimple').DataTable({
                data: responseData.data.leads,
                columns: columns,
                order: [0, 'desc'], // Disable initial sorting
                pageLength: 5, // Show only 5 records per page
                lengthChange: false, // Remove "Entries per page" dropdown
                info: false, // Remove "Showing 1 to 2 of 2 entries" information
                paging: false, // Remove pagination
                searching: false, // Remove search functionality  
                createdRow: function (row, data, dataIndex) {
                    // Bind click event to the buttons inside the created row
                    $(row).find('.editLead').on('click', function () {
                        editLead(data.id);
                    });
                }
            });
            setLoading(false);
        } catch (error) {
            if (axios.isCancel(error)) {
				// console.log('Request cancelled');
			} else {
                setLoading(false);
                if(error.response.status == 401){
                    onLogout();
                }
				// handle other errors
                // console.log(error.response.status);
			}
        }
    };

    useEffect(() => {
		const source = axios.CancelToken.source();
		fetchData(source);
		return () => {
			source.cancel('Operation canceled by the user.');
		};
	}, []);


    return (
        <div className="sb-nav-fixed">
            {/* /* Render the common navbar */}
            <Navbar userName={userName} role={role} onLogout={onLogout} />

            <div id="layoutSidenav">
                <Sidebar role={role} onLogout={onLogout} page='dashboard' />
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4">
                            <div className="row">
                                <h1 className="fs-4 mb-4">Dashboard</h1>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-6">
                                    <div className="small-box">
                                        <div
                                            className="inner d-flex justify-content-start align-items-center p-3 profit-card-bg1">
                                            <div className="rounded-circle p-3 me-3 profit-card-icon-bg1"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="26" height="26"
                                                viewBox="0 0 26 26" fill="none">
                                                <path
                                                    d="M8.99805 21.8C9.198 21.5934 9.41129 21.2667 10.0645 21.2667C10.7177 21.2667 17.2562 21.2667 17.9094 21.2667C18.5026 21.2667 21.7618 18 22.1751 17.5334C22.575 17.0867 24.3479 14.42 24.8412 13.2667C24.5346 12.8534 23.6947 12.5067 22.7083 12.7334C21.7685 12.9534 21.2086 13.6267 20.5754 14.8667L18.256 16.0734"
                                                    fill="#5B93FF" />
                                                <path
                                                    d="M8.99805 21.8C9.198 21.5934 9.41129 21.2667 10.0645 21.2667C10.7177 21.2667 17.2562 21.2667 17.9094 21.2667C18.5026 21.2667 21.7618 18 22.1751 17.5334C22.575 17.0867 24.3479 14.42 24.8412 13.2667C24.5346 12.8534 23.6947 12.5067 22.7083 12.7334C21.7685 12.9534 21.2086 13.6267 20.5754 14.8667L18.256 16.0734"
                                                    stroke="#5B93FF" />
                                                <path
                                                    d="M8.99809 21.5C8.99809 21.5 16.3098 18.0667 16.843 18.0667C18.9759 18.0667 18.9759 15.4 16.843 15.4C16.3098 15.4 15.1234 15.4 13.6437 15.4C13.1971 15.4 12.364 14.3334 11.6642 14.3334C11.3509 14.3334 9.71793 14.3334 8.99809 14.3334C8.27825 14.3334 7.37845 14.92 6.86524 15.4C6.06541 16.2 4.69238 17.4934 4.69238 17.4934"
                                                    fill="#5B93FF" />
                                                <path
                                                    d="M8.99809 21.5C8.99809 21.5 16.3098 18.0667 16.843 18.0667C18.9759 18.0667 18.9759 15.4 16.843 15.4C16.3098 15.4 15.1234 15.4 13.6437 15.4C13.1971 15.4 12.364 14.3334 11.6642 14.3334C11.3509 14.3334 9.71793 14.3334 8.99809 14.3334C8.27825 14.3334 7.37845 14.92 6.86524 15.4C6.06541 16.2 4.69238 17.4934 4.69238 17.4934"
                                                    stroke="#5B93FF" />
                                                <path
                                                    d="M6.86535 25L9.53142 22.3333L3.66607 16.4667L1 19.1333L6.86535 25Z"
                                                    fill="#5B93FF" stroke="#5B93FF" />
                                                <path d="M3.66602 19.1333L4.73244 20.2L3.66602 19.1333Z"
                                                    fill="#5B93FF" />
                                                <path d="M3.66602 19.1333L4.73244 20.2" stroke="#5B93FF" />
                                                <path
                                                    d="M14.1101 9.00004H12.1106C12.0706 9.00004 12.0439 8.97337 12.0439 8.93337V6.40004C12.0439 6.36004 12.0706 6.33337 12.1106 6.33337H14.1101C14.1501 6.33337 14.1768 6.36004 14.1768 6.40004V8.93337C14.1768 8.97337 14.1501 9.00004 14.1101 9.00004Z"
                                                    fill="#5B93FF" stroke="#5B93FF" />
                                                <path
                                                    d="M6.71191 7.91333V11.6667C6.71191 11.96 6.95186 12.2 7.24513 12.2H18.9758C19.2691 12.2 19.509 11.96 19.509 11.6667V7.91333"
                                                    stroke="#5B93FF" />
                                                <path
                                                    d="M14.1769 7.39996C16.3098 7.39996 18.4426 7.39996 19.509 6.3333V3.66663C19.509 3.3733 19.2691 3.1333 18.9758 3.1333H7.24513C6.95186 3.1333 6.71191 3.3733 6.71191 3.66663V6.3333C7.77834 7.39996 9.9112 7.39996 12.0441 7.39996"
                                                    stroke="#5B93FF" />
                                                <path
                                                    d="M10.9775 3.13333V1.53333C10.9775 1.24 11.2175 1 11.5108 1H14.71C15.0033 1 15.2432 1.24 15.2432 1.53333V3.13333"
                                                    fill="#5B93FF" />
                                                <path
                                                    d="M10.9775 3.13333V1.53333C10.9775 1.24 11.2175 1 11.5108 1H14.71C15.0033 1 15.2432 1.24 15.2432 1.53333V3.13333"
                                                    stroke="#5B93FF" />
                                            </svg></div>
                                            <div>
                                                <h5 className="mb-0 fw-semibold">{data.total_count ? data.total_count : (data.total_count = 0)}</h5>
                                                <p className="mb-0 fw-semibold text-muted">TOTAL LEADS</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-6">

                                    <div className="small-box">
                                        <div
                                            className="inner d-flex justify-content-start align-items-center p-3 profit-card-bg2">
                                            <div className="rounded-circle p-3 me-3 profit-card-icon-bg2"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 22 24" fill="none">
                                                <path
                                                    d="M21.2047 7.70145L13.8797 6.63466L10.5995 0L7.32494 6.63466L0 7.70145L5.29974 12.87L4.05039 20.1664L6.42358 18.9228L6.63466 18.803V15.4371C6.63466 14.57 6.91419 13.7428 7.43903 13.0468C7.65581 12.7616 7.90682 12.5106 8.18636 12.2938C7.884 11.8317 7.71286 11.2898 7.71286 10.7136C7.71286 9.12194 9.00785 7.82126 10.5995 7.82126C12.1968 7.82126 13.4918 9.12194 13.4918 10.7136C13.4918 11.2898 13.3207 11.8374 13.0183 12.2995C13.1552 12.4022 13.2845 12.5163 13.4062 12.6418C14.1536 13.3834 14.5643 14.376 14.5643 15.4371V18.803L14.7811 18.9228L17.1543 20.1664L15.9049 12.87L21.2047 7.70145Z"
                                                    fill="#FFC327" />
                                                <path
                                                    d="M12.4366 12.9441C12.1799 12.7502 11.8832 12.5961 11.5695 12.4934C11.8547 12.3394 12.1 12.1169 12.2769 11.8488C12.4993 11.5236 12.6248 11.1357 12.6248 10.7136C12.6248 9.59541 11.7178 8.68835 10.5997 8.68835C9.48151 8.68835 8.57445 9.59541 8.57445 10.7136C8.57445 11.1357 8.70566 11.5236 8.92815 11.8488C9.105 12.1169 9.3503 12.3394 9.63554 12.4934C9.32178 12.5961 9.03084 12.7502 8.76842 12.9441C8.52311 13.121 8.31203 13.332 8.12948 13.5716C7.73585 14.0908 7.50195 14.7411 7.50195 15.4371V18.9969C7.65598 18.9398 7.81572 18.8828 7.98116 18.8315C8.24928 18.7516 8.53452 18.6831 8.82547 18.6261L9.40165 24H11.8034L12.3738 18.6261C12.6705 18.6774 12.9557 18.7516 13.2238 18.8315C13.3893 18.8828 13.549 18.9398 13.7031 18.9969V15.4371C13.7031 14.5814 13.3551 13.8055 12.796 13.2465C12.6876 13.1381 12.5678 13.0354 12.4366 12.9441Z"
                                                    fill="#FFC327" />
                                                <path
                                                    d="M3.1204 3.97629C3.82019 4.40986 4.51807 4.84342 5.21405 5.27698C5.57345 5.49947 5.89863 4.9404 5.54493 4.71792C4.84515 4.28435 4.14536 3.85079 3.44557 3.41722C3.09188 3.20044 2.76671 3.75951 3.1204 3.97629Z"
                                                    fill="#FFC327" />
                                                <path
                                                    d="M15.9846 5.27698C16.6844 4.84342 17.3842 4.40986 18.084 3.97629C18.4377 3.75951 18.1125 3.20044 17.7588 3.41722C17.059 3.85079 16.3592 4.28435 15.6595 4.71792C15.3058 4.9404 15.6309 5.49947 15.9846 5.27698Z"
                                                    fill="#FFC327" />
                                                <path
                                                    d="M3.28583 13.2978C2.58985 13.7314 1.89197 14.165 1.19218 14.5985C0.838484 14.821 1.16365 15.3801 1.51735 15.1576C2.21714 14.724 2.91692 14.2905 3.61671 13.8569C3.97041 13.6401 3.64523 13.0811 3.28583 13.2978Z"
                                                    fill="#FFC327" />
                                                <path
                                                    d="M20.0122 14.5985C19.3124 14.165 18.6126 13.7314 17.9129 13.2978C17.5592 13.0811 17.234 13.6401 17.5877 13.8569C18.2875 14.2905 18.9873 14.724 19.687 15.1576C20.0407 15.3801 20.3659 14.821 20.0122 14.5985Z"
                                                    fill="#FFC327" />
                                            </svg></div>
                                            <div>
                                                <h5 className="mb-0 fw-semibold">{data.approved_count ? data.approved_count : (data.approved_count = 0)}</h5>
                                                <p className="mb-0 fw-semibold text-muted">APPROVED LEADS</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-lg-3 col-6">

                                    <div className="small-box">
                                        <div
                                            className="inner d-flex justify-content-start align-items-center p-3 profit-card-bg3">
                                            <div className="rounded-circle p-3 me-3 profit-card-icon-bg3"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 18 19" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                    d="M17.1123 6.1627C16.5904 7.62386 15.4324 8.73215 13.9514 9.14465C13.3301 10.7499 12.0628 12.0024 10.4873 12.549C9.92575 12.7478 9.32439 12.8522 8.69818 12.8522C8.00239 12.8522 7.33145 12.718 6.71021 12.4745C7.33145 12.718 8.00239 12.8522 8.70315 12.8522C9.32439 12.8522 9.92575 12.7478 10.4873 12.549V16.6641C12.356 17.0468 13.6681 17.9464 13.6681 19H3.33066C3.33066 17.9464 4.64769 17.0419 6.52135 16.6641V12.395C5.12977 11.7837 4.02148 10.6108 3.44994 9.14465C1.9689 8.72718 0.810918 7.62386 0.289076 6.1627C-0.0985774 5.07429 0.0157308 3.87157 0.0157308 2.73346C0.0157308 2.50981 0.20459 2.32095 0.428236 2.32095C1.293 2.32095 2.15942 2.32095 3.02749 2.32095V0H14.3738V2.32095C15.2419 2.32095 16.11 2.32095 16.9781 2.32095C17.0973 2.32095 17.1918 2.36568 17.2564 2.43029C17.2597 2.4336 17.263 2.43692 17.2663 2.44023C17.2696 2.44354 17.2729 2.44851 17.2763 2.45514C17.3458 2.51975 17.3856 2.61418 17.3856 2.73346C17.3856 3.87157 17.4999 5.07429 17.1123 6.1627ZM3.16169 8.16061C3.07223 7.74313 3.02749 7.31572 3.02749 6.87837V3.14596C2.29526 3.14596 1.56303 3.14596 0.830793 3.14596C0.815883 3.82684 0.786066 4.53257 0.895404 5.19854C1.11408 6.54041 1.96891 7.62882 3.16169 8.16061ZM7.7191 1.82396H5.48264V7.16662L6.64063 6.00863L7.7191 7.0871V1.82396ZM16.5705 3.14596C15.8383 3.14596 15.1061 3.14596 14.3738 3.14596V6.87837C14.3738 7.31572 14.3291 7.74313 14.2446 8.16061C15.4374 7.62882 16.2872 6.54041 16.5059 5.19854C16.6153 4.53257 16.5854 3.82684 16.5705 3.14596Z"
                                                    fill="#605BFF" fillOpacity="0.8" />
                                            </svg></div>
                                            <div>
                                                <h5 className="mb-0 fw-semibold">{data.won_count ? data.won_count : (data.won_count = 0)}</h5>
                                                <p className="mb-0 fw-semibold text-muted">WON LEADS</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-6">

                                    <div className="small-box">
                                        <div
                                            className="inner d-flex justify-content-start align-items-center p-3 profit-card-bg4">
                                            <div className="rounded-circle p-3 me-3 profit-card-icon-bg4"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M11.5392 23.0784C5.16478 23.0784 0 17.9136 0 11.5392C0 5.1648 5.16478 2.28882e-05 11.5392 2.28882e-05C17.9136 2.28882e-05 23.0783 5.1648 23.0783 11.5392L11.5392 11.5392L11.5392 23.0784Z"
                                                    fill="#FF8F6B" />
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                    d="M23.9998 12.9216L12.9214 12.9216L12.9214 24C19.0366 24 23.9998 19.0464 23.9998 12.9216ZM14.7742 14.7744L21.9646 14.7744C21.235 18.384 18.3838 21.2352 14.7742 21.9744L14.7742 14.7744Z"
                                                    fill="#FF8F6B" />
                                            </svg></div>
                                            <div>
                                                <h5 className="mb-0 fw-semibold">{data.loss_count ? data.loss_count : (data.loss_count = 0)}</h5>
                                                <p className="mb-0 fw-semibold text-muted">LOSS LEADS</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row g-3 mt-2">
                                <div className="col-lg-10 my-4 d-flex">
                                    
                                    <div className="card flex-fill">
                                        <div className="card-body">
                                            <div
                                                className="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
                                                <div className="datatable-top">
                                                    <div
                                                        className="row align-items-center justify-content-center float-none">
                                                        <div className="col-md-6">
                                                            <h2 className="fs-4">Leads</h2>
                                                        </div>
                                                        <div className="col-md-6 text-end">
                                                            <Link className="btn btn-link text-black text-decoration-none p-0" aria-current="page" to="/contacts/leads">View
                                                                more</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="datatable-container" style={{ overflowX: 'hidden' }}>
                                                    <table id="datatablesSimple" className="table text-break">
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>First Name</th>
                                                                <th>Last Name</th>
                                                                <th>Email</th>
                                                                <th>Company Name</th>
                                                                {role == 'admin' && <th>Partner Name</th>}
                                                                <th>Status</th>
                                                                <th>Created Date</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody><tr><td colSpan="9">{loading ? ("Loading...") : ("No Records Found")}</td></tr></tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </main>
                    {/* <!-- <footer className="py-4 bg-light mt-auto">
          <div className="container-fluid px-4">
          <div className="d-flex align-items-center justify-content-between small">
          </div>
          </div>
          </footer> --> */}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
