import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import 'datatables.net-bs5';
import { useNavigate } from 'react-router-dom'; // Import useParams
import { BASE_URL, USER_ID, TOKEN } from '../../commonConstants';
import axios from 'axios';


const Opportunites = ({ userName, role, onLogout }) => {

	// const [email, setEmail] = useState('');

	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);
	const [firstNameError, setFirstNameError] = useState('');
	const [lastNameError, setLastNameError] = useState('');

	const [emailError, setEmailError] = useState('');
	const [companyNameError, setCompanyNameError] = useState('');
	const [websiteError, setWebsiteError] = useState('');
	const [phoneError, setPhoneError] = useState('');
	const [regionError, setRegionError] = useState('');
	const [countryError, setCountryError] = useState('');
	const [leadTypeError, setLeadTypeError] = useState('');
	const [leadStatusError, setLeadStatusError] = useState('');


	const [pipelineValueError, setPipelineValueError] = useState('');
	const [pipelineStageError, setPipelineStageError] = useState('');
	const [numUsersError, setNumUsersError] = useState('');
	const [billingAddressError, setBillingAddressError] = useState('');
	const [demoDateError, setDemoDateError] = useState('');
	const [demoStatusError, setDemoStatusError] = useState('');


	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]); // Define state variable to hold data
	const [show, setShow] = useState(false);
	const [fadeOut, setFadeOut] = useState(false);


	const navigate = useNavigate();

	// Define editPartner and viewPartnerLeads functions within useEffect
	const editLead = (pid) => {
		navigate(`/edit-opportunity/${pid}`);
	};


	const [leadData, setLeadData] = useState({
		fname: '',
		lname: '',
		email: '',
		company_name: '',
		phone_number: '',
		website: '',
		region: '',
		country: '',
		lead_type: '',
		partner_associated: USER_ID,
		is_opportunity: 1,
		opportunity_status: 'pending',
		converted_by: '',
		converted_date: '',
		created_at: '',
		pipeline_value: '',
		pipeline_stage: '',
		no_of_users: '',
		billing_address: '',
		demo_date: '',
		opportinies_notes: ''
	});


	const handleClose = () => {
		setShow(false);
		setSuccess('');
		setError('');
		resetForm();
		resetFormValidation();
	};
	const handleShow = () => {
		setShow(true);
		setSuccess('');
		setError('');
		resetForm();
		resetFormValidation();
	};

	const resetForm = () => {
		setLeadData({
			fname: '',
			lname: '',
			email: '',
			company_name: '',
			phone_number: '',
			website: '',
			region: '',
			country: '',
			lead_type: '',
			partner_associated: USER_ID,
			is_opportunity: 1,
			opportunity_status: 'pending',
			converted_by: '',
			converted_date: '',
			created_at: '',
			pipeline_value: '',
			pipeline_stage: '',
			no_of_users: '',
			billing_address: '',
			demo_date: '',
			opportinies_notes: ''
		});
	};

	let type = localStorage.getItem('type') || '';
	let fetchDataapiUrl = ``;
	
	if (role === 'admin') {
		fetchDataapiUrl = `${BASE_URL}opportunities/`;
	}
	if (role == 'partner') {
		if(type == 'partner-admin'){
			fetchDataapiUrl = `${BASE_URL}opportunities/company/${USER_ID}`;
		} else {
			fetchDataapiUrl = `${BASE_URL}opportunities/partner/${USER_ID}`;
		}
	}


	const fetchData = async (source) => {
		try {
			const response = await axios.get(fetchDataapiUrl, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				cancelToken: source.token,
			});
			// console.log(response);
			if (response.status !== 200) {
				throw new Error('Failed to fetch users');
			}
			const responseData = response.data;
			setData(responseData.data); // Set fetched data to state

			// Destroy existing DataTable instance if it exists
			if ($.fn.DataTable.isDataTable('#datatablesSimple')) {
				$('#datatablesSimple').DataTable().destroy();
			}

			// Define the table columns based on the user's role
			let columns = [
				{ data: 'id', defaultContent: '' },
				{ data: 'fname' },
				{ data: 'lname' },
				{ data: 'email' },
				{ data: 'company_name' },
			];
			const hasPartnerName = responseData.data.some(
				(entry) => entry.partner && entry.partner.name
			);

			if (role == 'admin' && hasPartnerName) {
				columns.push({ data: 'partner.name' });
			}
			columns.push(
				{
					data: 'lead_status',
					render: function (data) {
						// Assuming data contains the status value (e.g., 'active', 'pending', etc.)
						// Define the Bootstrap tag class based on the status value
						let tagClass;
						if (data === null || data === undefined) {
							return ''; // Return an empty string if data is null or undefined
						}
						switch (data.toLowerCase()) {													
							
							case 'converted':
								tagClass = 'badge badge-pill bg-primary';
								break;
							default:
								tagClass = '';	
								data = '';							
						}

						// Construct the Bootstrap tag with the appropriate class and status text
						const tagMarkup = `<span class="${tagClass}">${data}</span>`;

						// Return the HTML markup for the Bootstrap tag
						return tagMarkup;
					},
				},
				{
					data: 'opportunity_status',
					render: function (data) {
						// Assuming data contains the status value (e.g., 'active', 'pending', etc.)
						// Define the Bootstrap tag class based on the status value
						let tagoClass;
						if (data === null || data === undefined) {
							return ''; // Return an empty string if data is null or undefined
						}
						switch (data.toLowerCase()) {
							case 'approved':
								tagoClass = 'badge badge-pill bg-success';
								break;
							case 'pending':
								tagoClass = 'badge badge-pill bg-warning text-dark';
								break;
							case 'rejected':
								tagoClass = 'badge badge-pill bg-dark';
								break;
							case 'converted':
								tagoClass = 'badge badge-pill bg-primary';
								break;
							default:
								tagoClass = 'badge badge-pill bg-info';
						}

						// Construct the Bootstrap tag with the appropriate class and status text
						const tagoMarkup = `<span class="${tagoClass}">${data}</span>`;

						// Return the HTML markup for the Bootstrap tag
						return tagoMarkup;
					},
				},
				{
					data: 'created_at_formated',
					render: function (data) {
						// Parse the date string into a Date object

						return data;
					},
				},
				{
					data: 'id',
					render: function (pid) {
						return `
								<div class="btn-group btn-group-sm" role="group" aria-label="Small button group">
									<button class="btn btn-outline-secondary editLead" >Edit</a>					
								</div>
							`;
					},
				}
			);

			$('#datatablesSimple').DataTable({
				data: responseData.data,
				columns: columns,
				order: [0, 'desc'], // Disable initial sorting
				createdRow: function (row, data, dataIndex) {
					// Bind click event to the buttons inside the created row
					$(row)
						.find('.editLead')
						.on('click', function () {
							editLead(data.id);
						});
				},
			});
			setLoading(false);
		} catch (error) {
			if (axios.isCancel(error)) {
				// console.log('Request cancelled');
			} else {
				setLoading(false);
				if(error.response.status == 401){
                    onLogout();
                }			
				// handle other errors
			}
		}
	};


	useEffect(() => {
		const source = axios.CancelToken.source();

		fetchData(source);

		// Cleanup function
		return () => {
			source.cancel('Request cancelled');
		};
	}, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

	useEffect(() => {
		let hideTimeout;
		let removeTimeout;
	
		if (success || error) {
		  // Start fading out before removing
		  hideTimeout = setTimeout(() => {
			setFadeOut(true);
		  }, 2000); // Start fading out 0.5 seconds before removing
	
		  // Remove the alert after the fade-out transition
		  removeTimeout = setTimeout(() => {
			setSuccess(null);
			setError(null);
			setFadeOut(false); // Reset fadeOut for future alerts
		  }, 5000); // Remove after 2 seconds
		}
	
		return () => {
		  clearTimeout(hideTimeout);
		  clearTimeout(removeTimeout);
		};
	  }, [success, error]);


	const handleSubmit = async () => {

		if (!validateForm()) {
			return;
		}
		try {
			const response = await fetch(`${BASE_URL}contacts/`, {
				method: 'POST', // Use PUT method for updating
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				body: JSON.stringify(leadData), // Send updated data in the request body
			});
			const responseData = await response.json();
			// console.log(responseData);
			if (response.ok) {
				setError('');
				setSuccess('Opportunity Created successfully');
				window.scrollTo(0, 0);
				resetForm();
				const source = axios.CancelToken.source();
				fetchData(source);
				setShow(false);
			} else {
				// Handle login error (e.g., display error message)
				if (responseData.data.email) {
					setEmailError(responseData.data.email[0]);
				} else {
					setError('Error updating partner ', error);
				}
			}
			// console.log('Lead created successfully');
		} catch (error) {
			setSuccess('');
			setError('Error Creating Lead:', error);
			// console.error('Error  Lead:', error);
		}
		// console.log('Lead created successfully');
		window.scrollTo(0, 0);
	};

	const validateForm = () => {
		let isValid = true;
		if (!leadData.fname) {
			setFirstNameError('First name is required');
			isValid = false;
		} else {
			setFirstNameError('');
		}
		if (!leadData.lname) {
			setLastNameError('Last name is required');
			isValid = false;
		} else {
			setLastNameError('');
		}
		if (!leadData.email) {
			setEmailError('Email address is required');
		}
		else if (!isValidEmail(leadData.email)) {
			setEmailError('Valid email address is required');
			isValid = false;
		} else {
			setEmailError('');
		}

		if (!leadData.company_name) {
			setCompanyNameError('Company name is required');
			isValid = false;
		} else {
			setCompanyNameError('');
		}
		if (!leadData.website) {
			setWebsiteError('Website is required');
			isValid = false;
		} else {
			setWebsiteError('');
		}
		if (!leadData.phone_number) {
			setPhoneError('Phone Number is required');
			isValid = false;
		} else {
			setPhoneError('');
		}
		if (!leadData.region) {
			setRegionError('Region is required');
			isValid = false;
		} else {
			setRegionError('');
		}
		if (!leadData.country) {
			setCountryError('Region is required');
			isValid = false;
		} else {
			setCountryError('');
		}
		if (!leadData.lead_type) {
			setLeadTypeError('Lead type is required');
			isValid = false;
		} else {
			setLeadTypeError('');
		}
		if (!leadData.pipeline_value) {
			setPipelineValueError('Pipeline Value is required');
			isValid = false;
		} else {
			setPipelineValueError('');
		}
		if (!leadData.pipeline_stage) {
			setPipelineStageError('Pipeline Stage is required');
			isValid = false;
		} else {
			setPipelineStageError('');
		}
		if (!leadData.no_of_users) {
			setNumUsersError('Pipeline Stage is required');
			isValid = false;
		} else {
			setNumUsersError('');
		}
		if (!leadData.billing_address) {
			setBillingAddressError('Billing Address is required');
			isValid = false;
		} else {
			setBillingAddressError('');
		}
		if (!leadData.demo_date) {
			setDemoDateError('Demo Date is required');
			isValid = false;
		} else {
			setDemoDateError('');
		}
		if (role == 'admin') {
			if (!leadData.opportunity_status) {
				setDemoStatusError('Demo Status is required');
				isValid = false;
			} else {
				setDemoStatusError('');
			}
		}
		return isValid;
	};

	const resetFormValidation = () => {
		setFirstNameError('');
		setLastNameError('');
		setEmailError('');
		setCompanyNameError('');
		setWebsiteError('');
		setPhoneError('');
		setRegionError('');
		setCountryError('');
		setLeadTypeError('');
		setLeadStatusError('');
		setPipelineValueError('');
		setPipelineStageError('');
		setNumUsersError('');
		setBillingAddressError('');
		setDemoDateError('');
		setDemoStatusError('');

	};
	const isValidEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};
	return (
		<div className="sb-nav-fixed">
			<Navbar userName={userName} role={role} onLogout={onLogout} />
			<div id="layoutSidenav">
				<Sidebar role={role} onLogout={onLogout} page='opportunities' />
				<div id="layoutSidenav_content">
					<main>
					<div className="container-fluid position-relative px-4">						
								 {success && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-success alert-dismissible  mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{success}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setSuccess(null)}></button></div>}
								{error && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-danger alert-dismissible mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{error}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setError(null)}></button></div>}
							<div className="row align-items-center justify-content-between">
								<div className="col-md-6">
									<h1 className="fs-4 mb-0">Opportunities</h1>
								</div>
								<div className="col-md-6 text-end">
									<button className="btn btn-primary" onClick={handleShow}>+ Add Opportunity</button>
									<Modal show={show} dialogClassName="modal-right mt-0 me-0 mb-0 modal-xl">
										<div className="modal-content" style={{ animation: 'slideFromRight 0.3s forwards' }}>
											<Modal.Header>
												<Modal.Title>Add Opportunity</Modal.Title>
												<button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
											</Modal.Header>
											<Modal.Body >
												{success && <div className="alert alert-success alert-dismissible fade show" role="alert">{success}</div>}
												{error && <div className="alert alert-danger alert-dismissible fade show" role="alert">{error}</div>}
												<form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
													<div className='custom-modal-ht'>
														<div className="row mb-4">
															<div className="col-md-6">
																<h5>Basic Details</h5>
															</div>
															<div className="col-md-6 text-end">
																{leadData.partner && leadData.partner.name && (
																	<span>Partner Name : {leadData.partner.name}</span>
																)}
															</div>
														</div>
														<div className="row mb-4">
															<div className="col-md-4">
																<div className="form-floating">
																	<input className={`form-control ${firstNameError ? 'is-invalid' : ''}`} id="inputFirstName" type="text" placeholder="Enter your first name" value={leadData.fname} onChange={(e) => { setLeadData({ ...leadData, fname: e.target.value }); setFirstNameError(e.target.value.trim() === '' ? 'First name is required' : ''); }} />
																	<label htmlFor="inputFirstName">First name</label>
																	{firstNameError && <div className="invalid-feedback">{firstNameError}</div>}
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-floating">
																	<input className={`form-control ${lastNameError ? 'is-invalid' : ''}`} id="inputLastName" type="text" placeholder="Enter your last name" value={leadData.lname} onChange={(e) => { setLeadData({ ...leadData, lname: e.target.value }); setLastNameError(e.target.value.trim() === '' ? 'Last name is required' : ''); }} />
																	<label htmlFor="inputLastName">Last name </label>
																	{lastNameError && <div className="invalid-feedback">{lastNameError}</div>}
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-floating">
																	<input className={`form-control ${emailError ? 'is-invalid' : ''}`} id="inputEmail" type="email" placeholder="name@example.com" value={leadData.email} onChange={(e) => { setLeadData({ ...leadData, email: e.target.value }); setEmailError(e.target.value.trim() === '' ? 'Email address is required' : ''); }} />
																	<label htmlFor="inputEmail">Email address</label>
																	{emailError && <div className="invalid-feedback">{emailError}</div>}
																</div>
															</div>
														</div>
														<div className="row mb-4">

															<div className="col-md-4">
																<div className="form-floating">
																	<input className={`form-control ${companyNameError ? 'is-invalid' : ''}`} id="inputCompany" type="text" placeholder="Enter your company name" value={leadData.company_name} onChange={(e) => { setLeadData({ ...leadData, company_name: e.target.value }); setCompanyNameError(e.target.value.trim() === '' ? 'Company Name is required' : ''); }} />
																	<label htmlFor="inputCompany">Company</label>
																	{companyNameError && <div className="invalid-feedback">{companyNameError}</div>}
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-floating">
																	<input className={`form-control ${phoneError ? 'is-invalid' : ''}`} id="inputPhoneNumber" type="tel" placeholder="Enter your phone number" value={leadData.phone_number} onChange={(e) => { setLeadData({ ...leadData, phone_number: e.target.value }); setPhoneError(e.target.value.trim() === '' ? 'Phone Number is required' : ''); }} />
																	<label htmlFor="inputPhoneNumber">Phone Number</label>
																	{phoneError && <div className="invalid-feedback">{phoneError}</div>}
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-floating">
																	<input className={`form-control ${websiteError ? 'is-invalid' : ''}`} id="inputWebsite" type="text" placeholder="Enter website" value={leadData.website} onChange={(e) => { setLeadData({ ...leadData, website: e.target.value }); setWebsiteError(e.target.value.trim() === '' ? 'Website is required' : ''); }} />
																	<label htmlFor="inputWebsite">Website</label>
																	{websiteError && <div className="invalid-feedback">{websiteError}</div>}
																</div>
															</div>
														</div>


														<div className="row mb-4">
															<div className="col-md-4">
																<div className="form-floating">
																	<input className={`form-control ${regionError ? 'is-invalid' : ''}`} id="inputRegion" type="text" placeholder="Enter your region" value={leadData.region} onChange={(e) => { setLeadData({ ...leadData, region: e.target.value }); setRegionError(e.target.value.trim() === '' ? 'Region is required' : ''); }} />
																	<label htmlFor="inputRegion">Region</label>
																	{regionError && <div className="invalid-feedback">{regionError}</div>}
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-floating">
																	<input className={`form-control ${countryError ? 'is-invalid' : ''}`} id="inputCountry" type="text" placeholder="Enter your country" value={leadData.country} onChange={(e) => { setLeadData({ ...leadData, country: e.target.value }); setCountryError(e.target.value.trim() === '' ? 'Country is required' : ''); }} />
																	<label htmlFor="inputCountry">Country</label>
																	{countryError && <div className="invalid-feedback">{countryError}</div>}
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-floating">
																	<select className={`form-select ${leadTypeError ? 'is-invalid' : ''}`} id="leadType" value={leadData.lead_type} onChange={(e) => { setLeadData({ ...leadData, lead_type: e.target.value }); setLeadTypeError(e.target.value.trim() === '' ? 'Lead Type is required' : ''); }} >
																		<option value="" disabled>Select Type</option>
																		<option value="VSB (50 or fewer emp.)">VSB</option>
																		<option value="SMB (51-1000 emp.)">SMB</option>
																		<option value="Enterprise (>1000 emp.)">Enterprise</option>
																		<option value="UAE Lead">UAE Lead</option>
																	</select>
																	<label htmlFor="leadType">Lead Type</label>
																	{leadTypeError && <div className="invalid-feedback">{leadTypeError}</div>}
																</div>
															</div>
														</div>
														<div className="row mb-4">
															<div className="col-md-6">
																<h5>Opportunity Details</h5>
															</div>
														</div>
														<div className="row mb-4">
															<div className="col-md-4">
																<div className="form-floating">
																	<input className={`form-control ${pipelineValueError ? 'is-invalid' : ''}`} id="pipelineValue" type="text" placeholder="Pipeline Value" value={leadData.pipeline_value} onChange={(e) => { setLeadData({ ...leadData, pipeline_value: e.target.value }); setPipelineValueError(e.target.value.trim() === '' ? 'Pipeline Value is required' : ''); }} />
																	<label htmlFor="pipelineValue">Pipeline Value</label>
																	{pipelineValueError && <div className="invalid-feedback">{pipelineValueError}</div>}
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-floating">
																	<select className={`form-select overflow-auto ${pipelineStageError ? 'is-invalid' : ''}`} id="pipelineStage" placeholder="Pipeline Stage" value={leadData.pipeline_stage} onChange={(e) => { setLeadData({ ...leadData, pipeline_stage: e.target.value }); setLeadTypeError(e.target.value.trim() === '' ? 'Pipeline Value is required' : ''); }} >
																		<option value="">Select Stage</option>
																		<option value="no_show">No Show</option>
																		<option value="demo_set">Demo Set</option>
																		<option value="reschedule_demo">Reschedule Demo</option>
																		<option value="demo_complete">Demo Complete</option>
																		<option value="engaged">Engaged</option>
																		<option value="on_hold">on Hold</option>
																		<option value="proposal">Proposal</option>
																		<option value="sow_entimate">SOW + Estimate</option>
																		<option value="closed_won">Closed Won</option>
																		<option value="demolost_noshow">Demo Lost - No Show</option>
																		<option value="closed_lost">Closed Lost</option>
																		<option value="invalid">Invalid</option>
																		<option value="demo_cancelled">Demo Cancelled</option>
																	</select>
																	<label htmlFor="pipelineStage">Pipeline Stage</label>
																	{pipelineStageError && <div className="invalid-feedback">{pipelineStageError}</div>}
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-floating">
																	<select className={`form-select ${numUsersError ? 'is-invalid' : ''}`} id="numUsers" placeholder="Number of Users" value={leadData.no_of_users} onChange={(e) => { setLeadData({ ...leadData, no_of_users: e.target.value }); setNumUsersError(e.target.value.trim() === '' ? 'No Of Users is required' : ''); }} >
																		<option value="">Select Users</option>
																		<option value="1-50">1-50</option>
																		<option value="51-100">51-100</option>
																		<option value="101-300">101-300</option>
																		<option value="301-500">301-500</option>
																		<option value="500-1000">500-1000</option>
																		<option value="1000+">1000+</option>
																		<option value="10000+">10000+</option>
																	</select>

																	<label htmlFor="numUsers">Number of Users</label>
																	{numUsersError && <div className="invalid-feedback">{numUsersError}</div>}
																</div>
															</div>
														</div>
														<div className="row mb-4">

															<div className="col-md-4">
																<div className="form-floating">
																	<input className={`form-control ${billingAddressError ? 'is-invalid' : ''}`} id="billingAddress" type="text" placeholder="Billing Address" value={leadData.billing_address} onChange={(e) => { setLeadData({ ...leadData, billing_address: e.target.value }); setBillingAddressError(e.target.value.trim() === '' ? 'Pipeline Value is required' : ''); }} />
																	<label htmlFor="billingAddress">Billing Address</label>
																	{billingAddressError && <div className="invalid-feedback">{billingAddressError}</div>}
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-floating">
																	<input className={`form-control ${demoDateError ? 'is-invalid' : ''}`} id="demoDate" type="date" placeholder="Demo Date" value={leadData.demo_date} onChange={(e) => { setLeadData({ ...leadData, demo_date: e.target.value }); setDemoDateError(e.target.value.trim() === '' ? 'Demo Date is required' : ''); }} />
																	<label htmlFor="demoDate">Demo Date</label>
																	{demoDateError && <div className="invalid-feedback">{demoDateError}</div>}
																</div>
															</div>
															{role == "admin" &&
																<div className="col-md-4">
																	<div className="form-floating">
																		<select className={`form-select ${demoStatusError && 'is-invalid'}`} id="inputStatus" value={leadData.opportunity_status} onChange={(e) => { setLeadData({ ...leadData, opportunity_status: e.target.value }); setDemoStatusError(e.target.value.trim() === '' ? 'Opportunity status is required' : ''); }}>
																			<option disabled value="">Select status</option>
																			<option value="pending">Pending</option>
																			<option value="approved">Approved</option>
																		</select>
																		<label htmlFor="demoStatus">Status</label>
																		{demoStatusError && <div className="invalid-feedback">{demoStatusError}</div>}
																	</div>
																</div>
															}

														</div>
														<div className="row mb-4">
															<div className="col-md-12">
																<div className="form-floating">
																	<textarea className="form-control" id="inputComments" placeholder="Enter Opportunity comments" style={{ height: "100px" }} value={leadData.opportinies_notes} onChange={(e) => setLeadData({ ...leadData, opportinies_notes: e.target.value })}></textarea>
																	<label htmlFor="inputComments">Opportunity Notes</label>
																</div>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12 text-end">
															<button className="btn" type="button" onClick={handleClose}>Cancel</button>
															<button type="submit" className="btn btn-primary mx-2">Create Opportunity</button>
														</div>
													</div>
												</form>
											</Modal.Body>
										</div>
									</Modal>
								</div>
							</div>
							{error && <p>{error}</p>}
							<div className="card my-4">
								<div className="card-body">
									<table id="datatablesSimple" className="table table-bordered text-break">
										<thead className="table-primary">
											<tr>
												<th>ID</th>
												<th>First Name</th>
												<th>Last Name</th>
												<th>Email</th>
												<th>Company Name</th>
												{role == 'admin' && <th>Created By</th>}
												<th>Converted Status</th>
												<th>Status</th>
												<th>Created Date</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody><tr><td colSpan="10">{loading ? ("Loading...") : ("No Records Found")}</td></tr></tbody>
									</table>
								</div>
							</div>

						</div>
					</main>
				</div>
			</div>
		</div>
	);
};

export default Opportunites;
