import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import 'datatables.net-bs5';
// import { useNavigate } from 'react-router-dom';
import { BASE_URL, USER_ID, TOKEN } from '../../commonConstants'; 
import axios from 'axios';


const Payments = ({ userName, role, onLogout }) => {

	// const [email, setEmail] = useState('');
    const [editData, setEditData] = useState([]); // Define state variable to hold data
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);

	const [companyNameError, setCompanyNameError] = useState('');

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]); // Define state variable to hold data

    const [cdata, setCData] = useState([]); // Define state variable to hold data
    const [relevantData, setRelevantData] = useState(null); // State to hold relevant data
    const [editRelevantData, setEditRelevantData] = useState(null); // State to hold relevant data
    
	const [show, setShow] = useState(false);
    const [eshow, seteShow] = useState(false);
    const [vshow, setvShow] = useState(false);
	const [fadeOut, setFadeOut] = useState(false);


	// const navigate = useNavigate();

	

	const [leadData, setLeadData] = useState({
        company_name: ''       
    });
	

	const handleClose = () => {
		setShow(false);
		setSuccess('');
		setError('');
		resetForm();
		resetFormValidation();
        setRelevantData(null);
	};
	const handleShow = () => {
		setShow(true);
		setSuccess('');
		setError('');
		resetFormValidation();
	};
    const handleEClose = () => {
        seteShow(false);
        setSuccess('');
		setError('');
    }
	const handleVClose = () => {
		setvShow(false);
        setSuccess('');
		setError('');
    }

	const resetForm = () => {
        setLeadData({
            company_name: ''
        }); 
        setRelevantData(null);
    };

	let fetchDataapiUrl = ``;
	if (role == 'partner') {
		 fetchDataapiUrl = `${BASE_URL}payments/partner/${USER_ID}`;
	}
	if (role === 'admin') {
		fetchDataapiUrl = `${BASE_URL}payments/`; 
	}

	const fetchData = async (source) => {
		try {
			const response = await axios.get(fetchDataapiUrl, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				cancelToken: source.token,
			});
			const responseData = response.data;
			setEditData(responseData.data);
			// Destroy existing DataTable instance if it exists
			if ($.fn.DataTable.isDataTable('#datatablesSimple')) {
				$('#datatablesSimple').DataTable().destroy();
			}
			let actionBtn = "";
			if (role == 'admin') {
				actionBtn = '<button class="btn btn-sm btn-outline-secondary editPayment" >Edit</button>';
			} else {
				actionBtn = '<button class="btn btn-sm btn-outline-secondary viewPayment" >View</button>';
			}
			$('#datatablesSimple').DataTable({
				data: responseData.data,
				columns: [
					{ data: 'id', defaultContent: '' },
					{ data: 'partner.name' },
					{ data: 'contact.company_name' },
					{ data: 'created_at_formated' },
					{ data: 'commission_amount' },
					{
						data: 'payment_status',
						render: function (data) {
							// Assuming data contains the status value (e.g., 'active', 'pending', etc.)
							// Define the Bootstrap tag class based on the status value
							let tagClass;
							// Check if data is null or undefined
							if (data === null || data === undefined) {
								return ''; // Return an empty string if data is null or undefined
							}
							switch (data) {
								case 'paid':
									tagClass = 'badge badge-pill bg-success';
									break;
								case 'unpaid':
									tagClass = 'badge badge-pill bg-warning text-dark';
									break;
								case 'rejected':
									tagClass = 'badge badge-pill bg-dark';
									break;
								default:
									tagClass = 'badge badge-pill bg-info';
							}

							// Construct the Bootstrap tag with the appropriate class and status text
							const tagMarkup = `<span class="${tagClass}">${data}</span>`;

							// Return the HTML markup for the Bootstrap tag
							return tagMarkup;
						}
					},
					{
						data: 'created_at_formated',
						render: function (data) {
							return data;
						}
					},
					{
						data: 'id',
						render: function (pid) {
							return `
							<div class="btn-group btn-group" role="group" aria-label="Small button group">
								`+ actionBtn + `
							</div>
							`;
						}
					}
				],
				order: [0, 'desc'], // Disable initial sorting
				createdRow: function (row, data, dataIndex) {
					// Bind click event to the buttons inside the created row
					$(row).find('.editPayment').on('click', function () {
						editPayment(data);
					});
					$(row).find('.viewPayment').on('click', function () {
						viewPayment(data);

					});
				}
			});
			setLoading(false);
		}catch (error) {
			if (axios.isCancel(error)) {
				// console.log('Request cancelled');
			} else {
				if(error.response.status == 401){
                    onLogout();
                }
				// handle other errors
				setLoading(false);
			}
		}
	};

	const fetchCompanyData = async (source) => {
		try {
			const response = await axios.get(`${BASE_URL}opportunities/unpaid`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				cancelToken: source.token,
			});
			const resData = response.data;
			setCData(resData.data); // Set fetched data to state
			// setLoading(false);
		} catch (error) {
			console.error('Error fetching users:', error);
			//setError('Failed to fetch users');
			// setLoading(false);
		}
	};

	useEffect(() => {
		const source = axios.CancelToken.source();
		fetchData(source);
		if (role === 'admin') {
			fetchCompanyData(source);
		}

		return () => {
			source.cancel('Operation canceled by the user.');
		};
	}, []);


	useEffect(() => {
		let hideTimeout;
		let removeTimeout;
	
		if (success || error) {
		  // Start fading out before removing
		  hideTimeout = setTimeout(() => {
			setFadeOut(true);
		  }, 2000); // Start fading out 0.5 seconds before removing
	
		  // Remove the alert after the fade-out transition
		  removeTimeout = setTimeout(() => {
			setSuccess(null);
			setError(null);
			setFadeOut(false); // Reset fadeOut for future alerts
		  }, 5000); // Remove after 2 seconds
		}
	
		return () => {
		  clearTimeout(hideTimeout);
		  clearTimeout(removeTimeout);
		};
	  }, [success, error]);

    const handleSubmit = async () => {
		if (!validateForm()) {
			return;
		}
        try {
			const response = await fetch(`${BASE_URL}payments/`, { 
				method: 'POST', // Use PUT method for updating
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				body: JSON.stringify(leadData), // Send updated data in the request body
			});
			const responseData = await response.json();
			if (response.ok) {
				setError('');
				setSuccess('Payment Created successfully');
				const source = axios.CancelToken.source();
				fetchData(source);
				resetForm();
				setShow(false);
                setRelevantData(null);
			} else {
				// Handle login error (e.g., display error message)
				setError('Error creating payment ', error);
			}
			// console.log('Lead created successfully');
		} catch (error) {
			setSuccess('');
			setError('Error Creating Lead ', error);
			console.error('Error  Lead ', error);
		}
		// console.log('Lead created successfully');
		window.scrollTo(0, 0);
    };
	const handleUpdate = async () => {
        try {
			const response = await fetch(`${BASE_URL}payments/${editRelevantData.id}`, { 
				method: 'PUT', // Use PUT method for updating
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				body: JSON.stringify(editRelevantData), // Send updated data in the request body
			});
			const responseuData = await response.json();
			if (response.ok) {
				setError('');
				setSuccess('Payment Status Updated');
				const source = axios.CancelToken.source();
				fetchData(source);
				resetForm();
				seteShow(false);
                setRelevantData(null);
			} else {
				// Handle login error (e.g., display error message)
				setError('Error updating Payment ', error);
			}
			// console.log('Payment Updated successfully');
		} catch (error) {
			setSuccess('');
			setError('Error Updating Payment:', error);
			console.error('Error  Payment:', error);
		}
		// console.log('Payment updated successfully');
    };

	const validateForm = () => {
		let isValid = true;	
		if (!leadData.company_name) {			
			setCompanyNameError('Company name is required');
			isValid = false;
		} else {
			setCompanyNameError('');
		}
		return isValid;
	};
	
	const resetFormValidation = () => {
		setCompanyNameError('');
	};

    const editPayment = (edata) => {
		if (edata) {
			seteShow(true);
			setEditRelevantData(edata);
		} else {
    	    setEditRelevantData(null); 
    	};
	};

	const viewPayment = (vdata) => {
		if (vdata) {
			setvShow(true);
			setEditRelevantData(vdata);
		} else {
    	    setEditRelevantData(null); 
    	};
	};


	

    const handleCompanyChange = (e) => {
        const selectedCompanyId = e.target.value;
        CompanyChange(selectedCompanyId);
    };
    const CompanyChange = (selectedCompanyId) => {
        const selectedCompany = cdata.find(company => company.id == selectedCompanyId);
        setLeadData({...leadData, company_name: selectedCompany ? selectedCompany.company_name : ''}); 
        setCompanyNameError(selectedCompanyId.trim() == '' ? 'Company name is required' : '');
       if (selectedCompany) {
        const updatedLeadData = {
            ...leadData,
            company_name: selectedCompany.company_name,
            partner_id: selectedCompany.partner && selectedCompany.partner.id,
            contact_id: selectedCompany.id,
            commission_percentage: selectedCompany.partner && selectedCompany.partner.commission_percentage,
            pipeline_value: selectedCompany.pipeline_value,
            commission_amount: selectedCompany.partner ? (selectedCompany.pipeline_value * (selectedCompany.partner.commission_percentage/100)) : '',
            payment_status: 'paid'
        };

        setLeadData(updatedLeadData);
        setRelevantData(selectedCompany); // Set relevant data based on selected company
        } else {
        setLeadData({
            ...leadData,
            company_name: '',
            partner_id: '',
            contact_id: '',
            commission_percentage: '',
            pipeline_value: '',
            commission_amount: '',
            payment_status: ''
        });
        setRelevantData(null); 
        };
    };
    return (
		<div className="sb-nav-fixed">
			<Navbar userName={userName} role={role} onLogout={onLogout} page='payments' />
			<div id="layoutSidenav">
				<Sidebar role={role} onLogout={onLogout} page='payments' />
				<div id="layoutSidenav_content">
				<main>
				<div className="container-fluid position-relative px-4">						
					{success && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-success alert-dismissible  mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{success}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setSuccess(null)}></button></div>}
					{error && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-danger alert-dismissible mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{error}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setError(null)}></button></div>}
						<div className="row align-items-center justify-content-between">
							<div className="col-md-6">
								<h1 className="fs-4 mb-0">Payments</h1>
							</div>
							{role === 'admin' && (

								<div className="col-md-6 text-end">
									<button className="btn btn-primary" onClick={handleShow}>+ Add Payment</button>						
									<Modal show={show} dialogClassName="modal-lg">
						<div className="modal-content" style={{ animation: 'slideFromRight 0.3s forwards' }}>
							<Modal.Header>
							<Modal.Title>Add Payment</Modal.Title>
							<button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
							</Modal.Header>
							<Modal.Body>							
								<form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
									<div className=''>
										<div className="row mb-4">
											<div className="col-md-6">
												<div className="form-floating">
													<select className={`form-select ${companyNameError && 'is-invalid'}`} id="inputCompany" onChange={handleCompanyChange}>
														<option value="">Select Opportunity</option>
														{cdata && Array.isArray(cdata) && cdata.map(option => (
															// Check if option exists and has valid data
															option && option.id && option.company_name && (
																<option key={option.id} value={option.id}>{option.company_name}</option>
															)
														))}
													</select>
													<label htmlFor="inputCompany">Opportunity</label>
													{companyNameError && <div className="invalid-feedback">{companyNameError}</div>}
												</div>
											</div>
										</div>
									<div className="row mb-4">	
									{relevantData && (
										<div>
											{/* Display relevant data */}
											<h2>Payment Details</h2>
											<p>Company Name: {relevantData.company_name || 'N/A'}</p>
											<p>Partner Name: {relevantData.partner && relevantData.partner.name ? relevantData.partner.name : 'N/A'}</p>
											<p>Commission: {relevantData.partner && relevantData.partner.commission_percentage ? `${relevantData.partner.commission_percentage} %` : 'N/A'}</p>
											<p>Deal Amt: ${relevantData.pipeline_value || 0}</p>
											<p>Commission Amt: ${(relevantData.pipeline_value || 0) * (relevantData.partner && relevantData.partner.commission_percentage ? (relevantData.partner.commission_percentage / 100) : 0)}</p>
											{/* Display other relevant data fields */}
										</div>
									)}
									</div>
									</div>
										<div className="row">
											<div className="col-md-12 text-end">
											<button className="btn" type="button" onClick={handleClose}>Cancel</button>
											<button type="submit" className="btn btn-success mx-2">Pay</button>
											</div>
										</div>
									</form>
								</Modal.Body>
								</div>
							</Modal>
							</div>
							)}
					</div>
					{role === 'admin' && (
                    <Modal show={eshow} dialogClassName=" modal-lg">
					  <div className="modal-content">
						<Modal.Header>
						  <Modal.Title>Update Payment</Modal.Title>
						  <button type="button" className="btn-close" aria-label="Close" onClick={handleEClose}></button>
						</Modal.Header>
						<Modal.Body >
						
							<form className="p-4" onSubmit={(e) => { e.preventDefault(); handleUpdate(); }}>
								<div className="row mb-4">									
                                {editRelevantData && (
                                    <div className="col-md-6">
                                        {/* Display relevant data */}
                                        <p>Company Name: {editRelevantData.contact.company_name || 'N/A'}</p>
                                        <p>Partner Name: {editRelevantData.partner && editRelevantData.partner.name ? editRelevantData.partner.name : 'N/A'}</p>
                                        <p>Commission: {editRelevantData.partner && editRelevantData.partner.commission_percentage ? `${editRelevantData.partner.commission_percentage} %` : 'N/A'}</p>
                                        <p>Deal Amt: ${editRelevantData.pipeline_value || 0}</p>
                                        <p>Commission Amt: ${(editRelevantData.pipeline_value || 0) * (editRelevantData.partner && editRelevantData.partner.commission_percentage ? (editRelevantData.partner.commission_percentage / 100) : 0)}</p>

                                        {/* Display other relevant data fields */}
                                    </div>
                                )}
								<div className="col-md-6">
										{editRelevantData && (
											<div className="form-floating">
											<select className={`form-select`}  value={editRelevantData.payment_status} id="inputLeadType" onChange={(e) => { setEditRelevantData({...editRelevantData, payment_status: e.target.value});  }}>
												<option value="unpaid">Unpaid</option>
												<option value="paid">Paid</option>
                                            </select>
											<label htmlFor="inputCompany">Payment Status</label>
											</div>
										)}
											
										</div>
                                </div>
									<div className="row mb-4">
										<div className="col-md-12 text-end">
										<button className="btn" type="button" onClick={handleEClose}>Cancel</button>
										<button type="submit" className="btn btn-primary mx-2">Update</button>
										</div>
									</div>
								</form>
							</Modal.Body>
							</div>
						</Modal>
					)}
				
                    <Modal show={vshow} dialogClassName="modal-lg">
					  <div className="modal-content">
						<Modal.Header>
						  <Modal.Title>Payment Details</Modal.Title>
						  <button type="button" className="btn-close" aria-label="Close" onClick={handleVClose}></button>
						</Modal.Header>
						<Modal.Body >
								<div className="row mb-4">									
                                {editRelevantData && (
                                    <div className="col-md-6">
                                        {/* Display relevant data */}
                                        <p>Company Name: {editRelevantData.contact.company_name || 'N/A'}</p>
                                        <p>Partner Name: {editRelevantData.partner && editRelevantData.partner.name ? editRelevantData.partner.name : 'N/A'}</p>
                                        <p>Commission: {editRelevantData.partner && editRelevantData.partner.commission_percentage ? `${editRelevantData.partner.commission_percentage} %` : 'N/A'}</p>
                                        <p>Deal Amt: ${editRelevantData.pipeline_value || 0}</p>
                                        <p>Commission Amt: ${(editRelevantData.pipeline_value || 0) * (editRelevantData.partner && editRelevantData.partner.commission_percentage ? (editRelevantData.partner.commission_percentage / 100) : 0)}</p>

                                        {/* Display other relevant data fields */}
                                    </div>
                                )}
								<div className="col-md-6">
								{editRelevantData && (
								<div className="form-floating">
									<p>Payment Status: 
										<span className={`badge badge-pill p-2 ms-2 ${(() => {
											let tagClass;
											if (editRelevantData.payment_status === null || editRelevantData.payment_status === undefined) {
												tagClass = ''; // Set tagClass to an empty string if data is null or undefined
											} else {
												switch (editRelevantData.payment_status) {
													case 'paid':
														tagClass = 'bg-success';
														break;
													case 'unpaid':
														tagClass = 'bg-warning text-dark';
														break;
													case 'rejected':
														tagClass = 'bg-dark';
														break;
													default:
														tagClass = 'bg-info';
												}
											}
											return tagClass;
										})()}`}>
											{editRelevantData.payment_status}
										</span>
									</p>
								</div>
							)}
										
										</div>
                                </div>
									<div className="row mb-4">
										<div className="col-md-12 text-end">
										<button className="btn" type="button" onClick={handleVClose}>Close</button>
										</div>
									</div>
							</Modal.Body>
							</div>
						</Modal>
					
					
					{error && <p>{error}</p>}
						<div className="card my-4">
						<div className="card-body">
							<table id="datatablesSimple" className="table table-bordered text-break">
							<thead className='table-primary'>
								<tr>
								<th>ID</th>
								<th>Partner Name</th>
								<th>Company Name</th>
								<th>Payment Date</th>
								<th>Amount</th>
								<th>Status</th>
								<th>Created Date</th>
								<th>Action</th>
								</tr>
							</thead>
							<tbody><tr><td colSpan="8">{loading ? (  "Loading..." ) :( "No Records Found" ) }</td></tr></tbody>
							</table>
						</div>
						</div>
					</div>
				</main>
				</div>
			</div>
		</div>
	  );
};

export default Payments;
