// fileUpload.js
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import { BASE_URL, TOKEN } from '../../commonConstants';
import axios from "axios";
import React, { Component } from "react";

class App extends Component {
	state = {
		// Initially, no file is selected
		selectedFile: null,
		fileSizeFormatted: ''
	};

	// On file select (from the pop up)
	onFileChange = (event) => {
		// Update the state
		this.setState({
			selectedFile: event.target.files[0],
			fileSizeFormatted: this.formatFileSize(event.target.files[0].size),
			uploading: false, success: false, error: false,
		});

	};

	onFileUpload = (event) => {
		event.preventDefault(); // Prevent default form submission behavior

		if (!this.state.selectedFile) {
			// console.error('No file selected');
			let errorMessage = "No file selected";
			this.setState({ uploading: false, success: false, error: errorMessage, selectedFile: null, fileSizeFormatted: null });
			return;
		}
		this.setState({ uploading: true, success: false, error: null });

		const formData = new FormData();
		formData.append("myFile", this.state.selectedFile, this.state.selectedFile.name);
		

		axios.post(`${BASE_URL}file/upload/`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${TOKEN}`,
			}
		})

			.then((response) => {
				let successMessage = "File Uploaded Successfully";

				// If the response has a message, use it
				if (response.data && response.data.message) {
					successMessage = response.data.message;
				}

				this.setState({ uploading: false, success: successMessage, selectedFile: null, fileSizeFormatted: null });
			})
			.catch((error) => {
				let errorMessage = "Error uploading file";

				// If the error response has a message, use it
				if (error.response && error.response.data && error.response.data.message) {
					errorMessage = error.response.data.message;
				}
				// console.error(errorMessage);
				this.setState({ uploading: false, success: false, error: errorMessage, selectedFile: null, fileSizeFormatted: null });
			});

	};

	formatFileSize = (bytes) => {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + ' ' + sizes[i];
	};

	// File content to be displayed after
	// file upload is complete
	fileData = () => {
		if (this.state.selectedFile) {
			return (
				<div className="uploded-files w-500">
					<div
						className="d-flex px-4 py-2 mb-3 rounded align-items-center justify-content-between profit-upload-bg">
						<div>
							<p className="mb-0 fw-semibold">{this.state.selectedFile.name}</p>
							<small className="text-muted fw-semibold">File size : {this.state.fileSizeFormatted}</small>
						</div>
						<div><button className="border-0 bg-transparent">
							<svg width="10" height="10" viewBox="0 0 10 10" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd"
									d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
									fill="currentColor"></path>
								<path fillRule="evenodd" clipRule="evenodd"
									d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
									fill="currentColor"></path>
							</svg>
						</button></div>
					</div>
				</div>
			);
		}
	};

	render() {
		const { userName, role, onLogout } = this.props;

		return (
			<div className="sb-nav-fixed">
				{/* Render the common navbar */}
				<Navbar userName={userName} role={role} onLogout={onLogout} />
				<div id="layoutSidenav">
					{/* Render the common sidebar */}
					<Sidebar role={role} onLogout={onLogout} page='resources' />
					<div id="layoutSidenav_content">
						<main>
							<div className="container-fluid px-4">
							<div className="row align-items-center justify-content-center">
                                <div className="col-md-12">
                                    <h1 className="fs-4 mb-0">Add Resource</h1>
                                </div>
                            </div>
								<form id="file-upload-form" action="<?php echo $_SERVER['PHP_SELF'] ?>" className="form-container d-flex justify-content-center align-items-center my-4" encType="multipart/form-data">
									<div className="upload-files-container bg-light py-5 rounded-lg d-flex align-items-center justify-content-center flex-column rounded-3 vw-100 vh-50">
										{this.state.uploading && <div>Loading...</div>}
										{this.state.success && <div className="alert alert-success alert-dismissible w-500 fade m-auto show mb-4" role="alert">{this.state.success ? this.state.success : "The File Updated Successfully"}</div>}
										{this.state.error && <div className="alert alert-danger alert-dismissible w-500 fade m-auto show mb-4" role="alert">{this.state.error ? this.state.error : "Error Uploading the File"}</div>}
										<div className="drag-file-area rounded mt-0">
											<h3 className="dynamic-message">Select a file to upload</h3>

											<label htmlFor="file-upload" id="file-drag" className="label"><span className="browse-files">
												<input id="file-upload" type="file" className="default-file-input" name="fileUpload" onChange={this.onFileChange} />
												<span id="file-upload-btn" className=" btn btn-primary browse-files-text fw-bold">Browse
													file </span>
												<span className="ms-3 fs-5">from device</span>
											</span>
											</label>
										</div>

										<output htmlFor="file-upload" id="messages">{this.fileData()}</output>
										<button type="button" className="btn btn-primary btn-lg btn-block w-500" onClick={this.onFileUpload}>
											{this.state.uploading ? (
												<div><span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
													<span className="sr-only"> Uploading...</span></div>
											) : (
												<div>Upload Files</div>
											)}
										</button>
									</div>
								</form>
							</div>
						</main>
					</div>
				</div>
			</div>
		);
	}
}

export default App;
