// EditLead.js
import React, { useEffect, useState } from 'react';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import { Link, useNavigate, useParams } from 'react-router-dom'; // Import useParams
import { BASE_URL, USER_ID, TOKEN } from '../../commonConstants'; 

const EditLead = ({ userName, role, onLogout }) => {
    const navigate = useNavigate();
    const { id } = useParams(); // Get the id parameter from the URL


    const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);
	const [firstNameError, setFirstNameError] = useState('');
	const [lastNameError, setLastNameError] = useState('');

	const [emailError, setEmailError] = useState('');
	const [companyNameError, setCompanyNameError] = useState('');
	const [websiteError, setWebsiteError] = useState('');
	const [phoneError, setPhoneError] = useState('');
	const [regionError, setRegionError] = useState('');
	const [countryError, setCountryError] = useState('');
	const [leadTypeError, setLeadTypeError] = useState('');
	// const [leadStatusError, setLeadStatusError] = useState('');
	const [fadeOut, setFadeOut] = useState(false);

	const [loading, setLoading] = useState(true);
	// const [data, setData] = useState([]); // Define state variable to hold data?
    // const [data, setData] = useState({ lead_status: "" });


    const [leadData, setLeadData] = useState({
        fname: '',
		lname: '',
        email: '',
        company_name: '',
		phone_number:'',
        website: '',
		region:'',
		country:'',
		lead_type:'',
		lead_status:'',
        partner_associated:USER_ID,
		is_lead:1,
        is_opportunity:'',
        converted_by: '',
		converted_date:'',       
        created_at: '',
		lead_notes: ''
    });


    const fetchData = async () => {
        try {
            const response = await fetch(`${BASE_URL}leads/${id}`, { // Use the id parameter in the URL
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${TOKEN}`,
                },
            });
            if (!response.ok) {
                navigate(`/contacts/leads`);
                throw new Error('Failed to fetch partner');
            }
            const responseData = await response.json();
            // console.log(responseData.data);
			setLeadData(responseData.data); // Set fetched data to state
            setLoading(false);
        } catch (error) {
            // console.error('Error fetching users:', error);
            setError('Failed to fetch users');
            setLoading(false);
        }
    };
    useEffect(() => {        
        fetchData();
    }, [id]); 

	useEffect(() => {
		let hideTimeout;
		let removeTimeout;
	
		if (success || error) {
		  // Start fading out before removing
		  hideTimeout = setTimeout(() => {
			setFadeOut(true);
		  }, 2000); // Start fading out 0.5 seconds before removing
	
		  // Remove the alert after the fade-out transition
		  removeTimeout = setTimeout(() => {
			setSuccess(null);
			setError(null);
			setFadeOut(false); // Reset fadeOut for future alerts
		  }, 5000); // Remove after 2 seconds
		}
	
		return () => {
		  clearTimeout(hideTimeout);
		  clearTimeout(removeTimeout);
		};
	  }, [success, error]);


    const updateLead = async () => {
        if (!validateForm()) {
            return;
          }
        try {
            const response = await fetch(`${BASE_URL}contacts/${id}`, { 
                method: 'PUT', // Use PUT method for updating
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${TOKEN}`,
                },
                body: JSON.stringify(leadData), // Send updated data in the request body
            });
            const responseData = await response.json();
            if (response.ok) {
				setError('');
				setSuccess('Lead Updated successfully');
				fetchData();
			} else {
				// Handle login error (e.g., display error message)
				if(responseData.data.email){
                    setError('');
                    setSuccess('');
					setEmailError(responseData.data.email[0]);
				} else{
                    setSuccess('');
					setError('Error updating lead ', error);
				}
			}
        } catch (error) {
            setSuccess('');
            setError('Error updating lead ', error);
            // console.error('Error updating lead ', error);        
		}
		window.scrollTo(0, 0);
    };
    
    const validateForm = () => {
		let isValid = true;		
		if (!leadData.fname) {			
			setFirstNameError('First name is required');
			isValid = false;
		} else {
			setFirstNameError('');
		}
		if (!leadData.lname) {			
			setLastNameError('Last name is required');
			isValid = false;
		} else {
			setLastNameError('');
		}
		if(!leadData.email){
			setEmailError('Email address is required');
		}
		else if (!isValidEmail(leadData.email)) {
			setEmailError('Valid email address is required');
			isValid = false;
		} else {
			setEmailError('');
		}

		if (!leadData.company_name) {			
			setCompanyNameError('Company name is required');
			isValid = false;
		} else {
			setCompanyNameError('');
		}
		if (!leadData.website) {			
			setWebsiteError('Website is required');
			isValid = false;
		} else {
			setWebsiteError('');
		}
		if (!leadData.phone_number) {			
			setPhoneError('Phone Number is required');
			isValid = false;
		} else {
			setPhoneError('');
		}
		if (!leadData.region) {			
			setRegionError('Region is required');
			isValid = false;
		} else {
			setRegionError('');
		}
		if (!leadData.country) {			
			setCountryError('Region is required');
			isValid = false;
		} else {
			setCountryError('');
		}
		return isValid;
	};
	
	const isValidEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

    return (
        <div className="sb-nav-fixed">
            <Navbar userName={userName} role={role} onLogout={onLogout} />
            <div id="layoutSidenav">
                <Sidebar role={role} onLogout={onLogout} page='leads' />

                <div id="layoutSidenav_content">
                <main>
                <div className="container-fluid position-relative px-4">
                       
                        {success && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-success alert-dismissible  mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{success}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setSuccess(null)}></button></div>}
                        {error && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-danger alert-dismissible mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{error}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setError(null)}></button></div>}
                           

                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-6">
                            <h1 className="fs-4 mb-0">Edit Lead</h1>
                        </div>
                        {role == 'admin' &&  
						
							<div className="col-md-4 text-end">
								{leadData.partner && leadData.partner.name && (
										<span>Created: {leadData.partner.name}</span>
									)}
							</div>
							  }
							{role == 'admin' &&  
                        	<div className="col-md-2 text-end">
								
														
								<div className="form-floating ">
								<select className="form-select"  id="inputStatus"  value={leadData.lead_status.toLowerCase()}   onChange={(e) => { 
									const selectedStatus = e.target.value;                             
									if (selectedStatus === "converted") {
										setLeadData(prevLeadData => ({
											...prevLeadData, 
											lead_status: selectedStatus,
											is_opportunity: 1,
											convertedDate: new Date(),
											convertedBy: USER_ID
										}));
									} else {
										setLeadData(prevLeadData => ({
											...prevLeadData, 
											lead_status: selectedStatus,
											is_opportunity: "", // Assuming is_opportunity is a string, reset to an empty string
											convertedDate: "", // Reset convertedDate when not converted
											convertedBy: "" // Reset convertedBy when not converted
										}));
									}                              
								}} >
								<option value="pending">Pending</option>
								<option value="approved">Approved</option>
								<option value="rejected">Rejected</option>
								<option value="converted">Converted</option>
								</select>
								<label htmlFor="inputStatus">Status</label>
								</div>
							</div>
					
                        
                        }

						{role == 'partner' &&
						 <div className="col-md-6 text-end">
						 <p className='mb-0'>Lead Status: 
							 <span className={`badge badge-pill p-2 ms-2 ${(() => {
								 let tagClass;
								 if (leadData.lead_status === null || leadData.lead_status === undefined) {
									 tagClass = ''; // Set tagClass to an empty string if data is null or undefined
								 } else {
									 switch (leadData.lead_status) {
										 case 'approved':
											 tagClass = 'bg-success';
											 break;
										 case 'pending':
											 tagClass = 'bg-warning text-dark';
											 break;
										 case 'rejected':
											 tagClass = 'bg-dark';
											 break;
										 case 'converted':
											 tagClass = 'bg-primary';
											 break;
										 default:
											 tagClass = 'bg-info';
									 }
								 }
								 return tagClass;
							 })()}`}>
								 {leadData.lead_status}
							 </span>
						 </p>                            
					 </div>

						}
                        
                    </div>

                           
                            <form className="p-4 shadow-sm border my-4" onSubmit={(e) => { e.preventDefault(); updateLead(); }}>
                            {role == 'admin' &&  
                            	<div>
									<div className="row mb-4">
										<div className="col-md-6">
											<div className="form-floating">
												<input className={`form-control ${firstNameError ? 'is-invalid' : ''}`} id="inputFirstName" type="text" placeholder="Enter your first name" value={leadData.fname} onChange={(e) => { setLeadData({ ...leadData, fname: e.target.value });  setFirstNameError(e.target.value.trim() === '' ? 'First name is required' : ''); }} />
												<label htmlFor="inputFirstName">First name</label>
												{firstNameError && <div className="invalid-feedback">{firstNameError}</div>}
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-floating">
												<input className={`form-control ${lastNameError ? 'is-invalid' : ''}`} id="inputLastName" type="text" placeholder="Enter your last name" value={leadData.lname} onChange={(e) => { setLeadData({ ...leadData, lname: e.target.value });  setLastNameError(e.target.value.trim() === '' ? 'Last name is required' : ''); }} />
												<label htmlFor="inputLastName">Last name </label>
												{lastNameError && <div className="invalid-feedback">{lastNameError}</div>}
											</div>
										</div>
									
									</div>
									<div className="row mb-4">
										<div className="col-md-6">
											<div className="form-floating">
												<input className={`form-control ${emailError ? 'is-invalid' : ''}`}  id="inputEmail" type="email" disabled placeholder="name@example.com" value={leadData.email} />
												<label htmlFor="inputEmail">Email address</label>
												{emailError && <div className="invalid-feedback">{emailError}</div>}
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-floating">
												<input className={`form-control ${companyNameError ? 'is-invalid' : ''}`} id="inputCompany" type="text" placeholder="Enter your company name" value={leadData.company_name} onChange={(e) => { setLeadData({ ...leadData, company_name: e.target.value });  setCompanyNameError(e.target.value.trim() === '' ? 'Company Name is required' : ''); }} />
												<label htmlFor="inputCompany">Company</label>
												{companyNameError && <div className="invalid-feedback">{companyNameError}</div>}
											</div>
										</div>
									</div>
									<div className="row mb-4">
										<div className="col-md-6">
											<div className="form-floating">
												<input className={`form-control ${phoneError ? 'is-invalid' : ''}`} id="inputPhoneNumber" type="tel" placeholder="Enter your phone number" value={leadData.phone_number} onChange={(e) => { setLeadData({ ...leadData, phone_number: e.target.value });  setPhoneError(e.target.value.trim() === '' ? 'Phone Number is required' : ''); }} />
												<label htmlFor="inputPhoneNumber">Phone Number</label>
												{phoneError && <div className="invalid-feedback">{phoneError}</div>}
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-floating">
												<input className={`form-control ${websiteError ? 'is-invalid' : ''}`}  id="inputWebsite" type="text" placeholder="Enter website" value={leadData.website} onChange={(e) => { setLeadData({ ...leadData, website: e.target.value });  setWebsiteError(e.target.value.trim() === '' ? 'Website is required' : ''); }}/>
												<label htmlFor="inputWebsite">Website</label>
												{websiteError && <div className="invalid-feedback">{websiteError}</div>}
											</div>
										</div>
									</div>
									<div className="row mb-4">
										<div className="col-md-6">
										<div className="form-floating">
												<input className={`form-control ${regionError ? 'is-invalid' : ''}`} id="inputRegion" type="text" placeholder="Enter your region"  value={leadData.region} onChange={(e) => { setLeadData({ ...leadData, region: e.target.value });  setRegionError(e.target.value.trim() === '' ? 'Region is required' : ''); }} />
												<label htmlFor="inputRegion">Region</label>
												{regionError && <div className="invalid-feedback">{regionError}</div>}
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-floating">
												<input className={`form-control ${countryError ? 'is-invalid' : ''}`} id="inputCountry" type="text" placeholder="Enter your country" value={leadData.country} onChange={(e) => { setLeadData({ ...leadData, country: e.target.value });  setCountryError(e.target.value.trim() === '' ? 'Country is required' : ''); }} />
												<label htmlFor="inputCountry">Country</label>
												{countryError && <div className="invalid-feedback">{countryError}</div>}
											</div>
										</div>
									</div>
									<div className="row mb-4">
										<div className="col-md-6">
											<div className="form-floating">
											<select className={`form-select${leadTypeError ? 'is-invalid' : ''}`} id="inputLeadType"  value={leadData.lead_type} onChange={(e) => { setLeadData({ ...leadData, lead_type: e.target.value }); }} >
													<option value="" disabled>Select Type</option>
													<option value="VSB (50 or fewer emp.)">VSB</option>
													<option value="SMB (51-1000 emp.)">SMB</option>
													<option value="Enterprise (>1000 emp.)">Enterprise</option>
													<option value="UAE Lead">UAE Lead</option>
												</select>
												{leadTypeError && <div className="invalid-feedback">{leadTypeError}</div>}
												<label htmlFor="inputLeadType">Lead Type	</label>
											</div>
										</div>  
										<div className="col-md-6">
											<div className="form-floating">
												<input className="form-control" id="inputCreatedAt" type="text" placeholder="Enter your country" value={leadData.created_at_formated} disabled />
												<label htmlFor="inputCreatedAt">Created Date</label>
											</div>
										</div>                                 
										
									</div>
                                </div>
                                }
                                {role == 'partner' && 
                                <div>
									  <div className="row pt-4 mb-4 mx-1 border border-sm rounded bg-light text-break">
										
										
										{leadData.fname && 
									<div className="col-4 mb-4 gap-2 d-flex">
										<label className="form-label mb-0">First Name:</label>
										<p className="mb-0 fw-medium">{leadData.fname}</p>
									</div>
								}
								{leadData.lname && 
									<div className="col-4 mb-4 gap-2 d-flex">
										<label className="form-label mb-0">Last Name:</label>
										<p className="mb-0 fw-medium">{leadData.lname}</p>
									</div>
								}


								{leadData.email && 
									<div className="col-4 mb-4 gap-2 d-flex">
										<label className="form-label mb-0">Email Address:</label>
										<p className="mb-0 fw-medium">{leadData.email}</p>
									</div> }
									{leadData.company_name && 
									<div className="col-4 mb-4 gap-2 d-flex">
										<label className="form-label mb-0">Company:</label>
										<p className="mb-0 fw-medium">{leadData.company_name}</p>
									</div> }
									{leadData.phone_number && 
									<div className="col-4 mb-4 gap-2 d-flex">
										<label className="form-label mb-0">Phone Number:</label>
										<p className="mb-0 fw-medium">{leadData.phone_number}</p>
									</div>
								}
								{leadData.website && 
									<div className="col-4 mb-4 gap-2 d-flex">
										<label className="form-label mb-0">Website:</label>
										<p className="mb-0 fw-medium">{leadData.website}</p>
									</div>
								}
									{leadData.region &&
									<div className="col-4 mb-4 gap-2 d-flex">
										<label className="form-label mb-0">Region:</label>
										<p className="mb-0 fw-medium">{leadData.region}</p>
									</div>										
									}

									{leadData.country &&
									<div className="col-4 mb-4 gap-2 d-flex">
										<label className="form-label mb-0">Country:</label>
										<p className="mb-0 fw-medium">{leadData.country}</p>
									</div>										
									}
									{leadData.lead_type &&
									<div className="col-4 mb-4 gap-2 d-flex">
										<label className="form-label mb-0">Lead Type:</label>
										<p className="mb-0 fw-medium">{leadData.lead_type}</p>
									</div>										
									}
									{leadData.created_at &&
									<div className="col-4 mb-4 gap-2 d-flex">
										<label className="form-label mb-0">Created Date:</label>
										<p className="mb-0 fw-medium">{leadData.created_at_formated}</p>
									</div>										
									}
									{leadData.lead_status == "converted" && leadData.converted_date && (
										<div>
										<div className="col-4 mb-4 gap-2 d-flex">
											<label className="form-label mb-0">Converted At:</label>
											<p className="mb-0 fw-medium">{leadData.converted_date}</p>
										</div>

										{leadData.converted_by && (
											<div className="col-4 mb-4 gap-2 d-flex">
												<label className="form-label mb-0">Converted By:</label>
												<p className="mb-0 fw-medium">{leadData.converted_by}</p>
											</div>
										)}
										</div>

									)}

									
								</div>
                                </div>
                                }                                                    
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <div className="form-floating">
                                            <textarea className="form-control" id="inputLeadComments" placeholder="Enter lead comments" style={{ height: "150px" }}  value={leadData?.lead_notes || ''}  onChange={(e) => {  setLeadData({ ...leadData, lead_notes:  e.target.value }); }}></textarea>
                                            <label htmlFor="inputLeadComments">Lead Notes</label>
                                        </div>
                                    </div>                                                                                                          
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-12 text-end">
                                        <Link className="btn btn-link text-decoration-none" aria-current="page" to="/contacts/leads">Cancel</Link>
                                        <button type="submit" className="btn btn-primary mx-2">Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default EditLead;
