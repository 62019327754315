import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import 'datatables.net-bs5';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, TOKEN } from '../../commonConstants'; 
import axios from 'axios';

const Companies = ({ userName, role, onLogout }) => {
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);
	const [companyNameError, setCompanyNameError] = useState('');
	const [websiteError, setWebsiteError] = useState('');
	const [commissionError, setCommissionError] = useState('');
	const [agreementError, setAgreementError] = useState('');
	const [typeError, setTypeError] = useState('');

	const [loading, setLoading] = useState(true);
	const [signedDateError, setSignedDateError] = useState('');
	const [onBoardedDateError, setOnBoardedDateError] = useState('');
	// const [data, setData] = useState([]); // Define state variable to hold data
	const [fadeOut, setFadeOut] = useState(false);

	const [show, setShow] = useState(false);
	const navigate = useNavigate();
	

	// Define editPartner function within useEffect
	const editPartner = (pid) => {
		navigate(`/edit-company/${pid}`);
	};	

	const [partnerData, setPartnerData] = useState({
		
		company_name: '',
		website: '',
		commision_percentage: '',		
		agreement_from: '',
		signed_date: '',
		onboarded_on: '',
		type: ''
	});

	const handleClose = () => {
		setShow(false);
		setSuccess('');
		setError('');
		resetForm();
		resetFormValidation();
	};
	const handleShow = () => {
		setShow(true);
		setSuccess('');
		setError('');
		resetForm();
		resetFormValidation();
	};

	const resetForm = () => {
		setPartnerData({			
			company_name: '',
			website: '',
			commision_percentage: '',			
			agreement_from: '',
			signed_date: '',
			onboarded_on: '',
			type: ''
		});
	};


	const fetchData = async (source) => {
		try {
			const response = await axios.get(`${BASE_URL}companies`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				cancelToken: source.token,
			});
			if (response.status !== 200) {
				throw new Error('Failed to fetch users');
			}
			// console.log('company response:', response);
			const responseData = response.data;
			// console.log('hello'+JSON.stringify(responseData.data));
			if ($.fn.DataTable.isDataTable('#datatablesSimple')) {
				$('#datatablesSimple').DataTable().destroy();
			}
			$('#datatablesSimple').DataTable({
				data: responseData.data,
				columns: [
					{ data: 'id', defaultContent: '' },
					{ data: 'company_name', defaultContent: '' },
					{ data: 'created_at_formated', defaultContent: '' },
					{
						data: 'id',
						render: function (pid) {
							return `
							<div class="" role="group" aria-label="Small button group">
							<button class="btn btn-sm btn-outline-secondary editPartner mx-2" >Edit</button>							
							</div>
							`;
						}
					}
					
				],
				order: [0, 'desc'],
				createdRow: function (row, data, dataIndex) {
					$(row).find('.editPartner').on('click', function () {
						editPartner(data.id);
					});
				}
			});
			setLoading(false);
		}		
		catch (error) {
			if (axios.isCancel(error)) {
				// console.log('Request cancelled');
				// console.log('Error fetching partners error:', error);
				// setError('Failed to fetch users');
			} else {
				setLoading(false);
				if(error.response.status == 401){
                    onLogout();
                }
				// handle other errors
			}
		}
	};
	
	useEffect(() => {
		const source = axios.CancelToken.source();
		fetchData(source);
		return () => {
			source.cancel('Operation canceled by the user.');
		};
	}, []);

	useEffect(() => {
		let hideTimeout;
		let removeTimeout;
	
		if (success || error) {
		  // Start fading out before removing
		  hideTimeout = setTimeout(() => {
			setFadeOut(true);
		  }, 2000); // Start fading out 0.5 seconds before removing
	
		  // Remove the alert after the fade-out transition
		  removeTimeout = setTimeout(() => {
			setSuccess(null);
			setError(null);
			setFadeOut(false); // Reset fadeOut for future alerts
		  }, 5000); // Remove after 2 seconds
		}
	
		return () => {
		  clearTimeout(hideTimeout);
		  clearTimeout(removeTimeout);
		};
	  }, [success, error]);



	const handleSubmit = async () => {
		if (!validateForm()) {
		  return;
		}
		try {
			const response = await fetch(`${BASE_URL}companies`, {
				method: 'POST', // Use PUT method for updating
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				body: JSON.stringify(partnerData), // Send updated data in the request body
			});
			const responseData = await response.json();
			if (response.ok) {
				setError('');
				setSuccess('Partner Company Created');
				window.scrollTo(0, 0);
				const source = axios.CancelToken.source();
				fetchData(source);
				resetForm();
				setShow(false);
			} else {
				// Handle login error (e.g., display error message)
					setError('Error updating partner ', error);
					window.scrollTo(0, 0);
			}			
			// console.log('Partner created successfully');
		} catch (error) {
			if(error.response.status == 401){
				onLogout();
			}	
			setSuccess('');
			setError('Error Creating Partner:', error);
			window.scrollTo(0, 0);
			// console.error('Error  Partner:', error);
		}
	};

	const validateForm = () => {
		let isValid = true;		
		
		if (!partnerData.company_name) {			
			setCompanyNameError('Company name is required');
			isValid = false;
		} else {
			setCompanyNameError('');
		}
		if (!partnerData.website) {
			setWebsiteError('Website is required');
			isValid = false;
		} else {
			setWebsiteError('');
		}
		if(!partnerData.commision_percentage){
			setCommissionError('Commission percentage is required');
			isValid = false;
		}		
		if(!partnerData.commision_percentage){
			setCommissionError('Commission percentage is required');
			isValid = false;
		}
		else if (isNaN(partnerData.commision_percentage) || partnerData.commision_percentage < 0.1 || partnerData.commision_percentage > 100) {
			setCommissionError('Commission percentage must be a number within 0.1 and 100');
			isValid = false;
		}
		else if (!/^\d+(\.\d{1,2})?$/.test(partnerData.commision_percentage)) {
			setCommissionError('Commission percentage can have at most two digits after the decimal point');
			isValid = false;
		} else {
			setCommissionError('');
		}
		
		if(!partnerData.agreement_from){
			setAgreementError('Agreement from date is required');
			isValid = false;
		} else {
			setAgreementError('');
		}
		if(!partnerData.signed_date){
			setSignedDateError('Signed date is required');
			isValid = false;
		} else {
			setSignedDateError('');
		}
		if(!partnerData.onboarded_on){
			setOnBoardedDateError('Onboarded date is required');
			isValid = false;
		} else {
			setOnBoardedDateError('');
		}
		if(!partnerData.type){
			setTypeError('Partner type is required');
			isValid = false;
		} else {
			setTypeError('');
		}

		return isValid;
	};
	
	const resetFormValidation = () => {
		
		setCompanyNameError('');
		setWebsiteError('');
		setCommissionError('');		
		setAgreementError('');
		setSignedDateError('');
		setOnBoardedDateError('');
		setTypeError('');

	};
	

	return (
		<div className="sb-nav-fixed">
			<Navbar userName={userName} role={role} onLogout={onLogout} />
			<div id="layoutSidenav">
				<Sidebar role={role} onLogout={onLogout} page='companies' />
				<div id="layoutSidenav_content">
					<main>
						<div className="container-fluid position-relative px-4">
						{success && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-success alert-dismissible  mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{success}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setSuccess(null)}></button></div>}
						
							<div className="row align-items-center justify-content-between">
								<div className="col-md-6">
									<h1 className="fs-4 mb-0">Companies</h1>
								</div>
								<div className="col-md-6 text-end">
									<button className="btn btn-primary" onClick={handleShow}>+ Add Company</button>
									<Modal show={show} dialogClassName="modal-right mt-0 mb-0 me-0">
										<div className="modal-content" style={{ animation: 'slideFromRight 0.3s forwards' }}>
											<Modal.Header >
												<Modal.Title>Register Company</Modal.Title>
												<button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
											</Modal.Header>
											<Modal.Body >
												{error && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-danger alert-dismissible mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{error}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setError(null)}></button></div>}
												<form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
													<div className="custom-modal-ht">	
	
													<div className="row mb-4">
														<div className="col-md-12">
															<div className="form-floating">
																<input className={`form-control ${companyNameError ? 'is-invalid' : ''}`} id="inputCompany" type="text" placeholder="Enter your company name" value={partnerData.company_name} onChange={(e) =>{ setPartnerData({ ...partnerData, company_name: e.target.value }); setCompanyNameError(e.target.value.trim() === '' ? 'Company name is required' : ''); }} />
																<label htmlFor="inputCompany">Company</label>
																{companyNameError && <div className="invalid-feedback">{companyNameError}</div>}
															</div>
														</div>
													</div>
													<div className="row mb-4">
														<div className="col-md-12">
															<div className="form-floating">
																<input className={`form-control ${websiteError ? 'is-invalid' : ''}`} id="inputWebsite" type="text" placeholder="Enter your website" value={partnerData.website} onChange={(e) =>{ setPartnerData({ ...partnerData, website: e.target.value }); setWebsiteError(e.target.value.trim() === '' ? 'Website is required' :''); }} />
																<label htmlFor="inputWebsite">Website</label>
																{websiteError && <div className="invalid-feedback">{websiteError}</div>}
															</div>
														</div>
													</div>
													<div className="row mb-4">
														<div className="col-md-12">
															<div className="form-floating">
																<input className={`form-control ${commissionError ? 'is-invalid' : ''}`} id="inputCommission" type="text" placeholder="Enter commission" value={partnerData.commision_percentage} onChange={(e) =>{ setPartnerData({ ...partnerData, commision_percentage: e.target.value }); setCommissionError(e.target.value.trim() === '' ? 'Commision Percentage is required' :''); }} />
																<label htmlFor="inputCommission">Commission (%)</label>
																{commissionError && <div className="invalid-feedback">{commissionError}</div>}
															</div>
														</div>
													</div>
													<div className="row mb-4">
														<div className="col-md-12">
															<div className="form-floating">
																<select className={`form-select ${typeError ? 'is-invalid' : ''}`} id="inputPartnerType"  value={partnerData.type} onChange={(e) => { setPartnerData({ ...partnerData, type: e.target.value }); setTypeError(e.target.value.trim() === '' ? 'Partner Type is required' : ''); }} >
																	<option value="" disabled>Select Partner Type</option>
																	<option value="channel">Channel</option>
																	<option value="referral">Referral</option>
																</select>
																<label htmlFor="inputPartnerType">Partnership Type</label>
																{typeError && <div className="invalid-feedback">{typeError}</div>}
															</div>
														</div>
													</div>

													<div className="row mb-4">
														<div className="col-md-12">
															<div className="form-floating">												
																<input className={`form-control ${agreementError ? 'is-invalid' : ''}`} id="inputAgreement" type="date" placeholder="Enter Agreement" value={partnerData.agreement_from} onChange={(e) =>{ setPartnerData({ ...partnerData, agreement_from: e.target.value }); setAgreementError(e.target.value.trim() === '' ? 'Agremment from date is required' :''); }} />
																<label htmlFor="inputAgreement">Agreement From</label>
																{agreementError && <div className="invalid-feedback">{agreementError}</div>}
															</div>
														</div>
													</div>
													<div className="row mb-4">
														<div className="col-md-12">
															<div className="form-floating">												
																<input className={`form-control ${signedDateError ? 'is-invalid' : ''}`} id="inputSigned" type="date" placeholder="Enter Agreement" value={partnerData.signed_date} onChange={(e) =>{ setPartnerData({ ...partnerData, signed_date: e.target.value }); setSignedDateError(e.target.value.trim() === '' ? 'Agremment from date is required' :''); }} />
																<label htmlFor="inputSigned">Signed Date</label>
																{signedDateError && <div className="invalid-feedback">{signedDateError}</div>}
															</div>
														</div>
													</div>
													<div className="row mb-4">
														<div className="col-md-12">
															<div className="form-floating">												
																<input className={`form-control ${onBoardedDateError ? 'is-invalid' : ''}`} id="inputOnboarded" type="date" placeholder="Enter Agreement" value={partnerData.onboarded_on} onChange={(e) =>{ setPartnerData({ ...partnerData, onboarded_on: e.target.value }); setOnBoardedDateError(e.target.value.trim() === '' ? 'Agremment from date is required' :''); }} />
																<label htmlFor="inputOnboarded">Onboarded On</label>
																{onBoardedDateError && <div className="invalid-feedback">{onBoardedDateError}</div>}
															</div>
														</div>
													</div>

													</div>
													<div>
													<div className="row">
														<div className="col-md-12 text-end">
															<button className="btn" type="button" onClick={handleClose}>Cancel</button>
															<button type="submit" className="btn btn-primary mx-2">Register</button>
														</div>
													</div>
													</div>
												</form>
											</Modal.Body>
										</div>
									</Modal>
								</div>
							</div>
							
							{error && <p>{error}</p>}
							{/* {console.log('return jsx->',data)}								 */}
								<div className="card my-4">
									<div className="card-body">
										<table id="datatablesSimple" className="table table-bordered text-break">
											<thead className='table-primary'>
												<tr>
													<th>ID</th>
													<th>Name</th>
													<th>Created Date</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody><tr><td colSpan="8">{loading ? (  "Loading..." ) :( "No Records Found" ) }</td></tr></tbody>
										</table>
									</div>
								</div>
					
						</div>
					</main>
				</div>
			</div>
		</div>
	);
};

export default Companies;
