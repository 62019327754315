import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import 'datatables.net-bs5';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, TOKEN } from '../../commonConstants'; 
import axios from 'axios';

const Partners = ({ userName, role, onLogout }) => {
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);
	const [nameError, setNameError] = useState('');
	const [emailError, setEmailError] = useState('');
	const [companyNameError, setCompanyNameError] = useState('');	
	const [typeError, setTypeError] = useState('');
	const [loading, setLoading] = useState(true);
	// const [data, setData] = useState([]); // Define state variable to hold data
	const [fadeOut, setFadeOut] = useState(false);
    const [cdata, setCData] = useState([]); // Define state variable to hold data

	const [show, setShow] = useState(false);
	const navigate = useNavigate();

	
	

	// Define editPartner and viewPartnerLeads functions within useEffect
	const editPartner = (pid) => {
		navigate(`/edit-partner/${pid}`);
	};

	const viewPartnerLeads = (pid) => {
		navigate(`/view-partner-leads/${pid}`);
	};	

	const [partnerData, setPartnerData] = useState({
		name: '',
		email: '',
		company_name: '',	
		type: '',
		role: 'partner'	
	});



	const handleClose = () => {
		setShow(false);
		setSuccess('');
		setError('');
		resetForm();
		resetFormValidation();
	};
	const handleShow = () => {
		setShow(true);
		setSuccess('');
		setError('');
		resetForm();
		resetFormValidation();
	};

	const resetForm = () => {
		setPartnerData({
			name: '',
			email: '',
			company_name: '',			
			type: '',
			role: 'partner'		
		});
	};

	
	let fetchDataapiUrl = ``;
	if (role == 'partner') {
		fetchDataapiUrl = `${BASE_URL}partner/users/partner/`;
	}
	if (role === 'admin') {
		fetchDataapiUrl = `${BASE_URL}users/partner/`;
	}

	const fetchData = async (source) => {
		try {
			const response = await axios.post(fetchDataapiUrl, {}, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				cancelToken: source.token,
			});
			if (response.status !== 200) {
				throw new Error('Failed to fetch users');
			}
			// console.log('partner response:', response);
			const responseData = response.data;
			if ($.fn.DataTable.isDataTable('#datatablesSimple')) {
				$('#datatablesSimple').DataTable().destroy();
			}
			$('#datatablesSimple').DataTable({
				data: responseData?.data,
				columns: [
					{ data: 'id', defaultContent: '' },
					{ data: 'name' },
					{ data: 'email' },
					{
						data: 'status',
						render: function (data) {
							let tagClass;
							switch (data) {
								case 'active':
									tagClass = 'badge badge-pill bg-success';
									break;
								case 'pending':
									tagClass = 'badge badge-pill bg-warning text-dark';
									break;
								case 'suspended':
									tagClass = 'badge badge-pill bg-dark';
									break;
								default:
									tagClass = 'badge badge-pill bg-primary';
							}
							const tagMarkup = `<span class="${tagClass}">${data}</span>`;
							return tagMarkup;
						}
					},
					{
						data: 'created_at_formated',
						render: function (data) {
							return data;
						}
					},
					{
						data: 'id',
						render: function (pid) {
							return `
							<div class="" role="group" aria-label="Small button group">
							<button class="btn btn-sm btn-outline-secondary editPartner mx-2" >Edit</button>
							<button class="btn btn-sm btn-outline-primary viewPartnerLeads" >Partner Leads</button>
							</div>
							`;
						}
					}
				],
				order: [0, 'desc'],
				createdRow: function (row, data, dataIndex) {
					$(row).find('.editPartner').on('click', function () {
						editPartner(data.id);
					});
					$(row).find('.viewPartnerLeads').on('click', function () {
						viewPartnerLeads(data.id);
					});
				}
			});
			if(role == 'partner' && responseData?.company){
				setPartnerData({ ...partnerData, company_name: responseData?.company });
			}
			setLoading(false);
		}		
		catch (error) {
			if (axios.isCancel(error)) {
				// console.log('Request cancelled');
				// console.log('Error fetching partners error:', error);
				// setError('Failed to fetch users');
			} else {
				setLoading(false);
				if(error.response.status == 401){
                    onLogout();
                }
				// handle other errors
			}
		}
	};
	const fetchCompanyData = async (source) => {
		try {
			const response = await axios.get(`${BASE_URL}companies/`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				cancelToken: source.token,
			});
			const resData = response.data;
			setCData(resData.data); // Set fetched data to state
			// setLoading(false);
		} catch (error) {
			// console.error('Error fetching users:', error);
			//setError('Failed to fetch users');
			// setLoading(false);
		}
	};
	
	useEffect(() => {
		const source = axios.CancelToken.source();
		fetchData(source);
		if(role == 'admin'){
			fetchCompanyData(source);
		}
		return () => {
			source.cancel('Operation canceled by the user.');
		};
	}, []);

	useEffect(() => {
		let hideTimeout;
		let removeTimeout;
	
		if (success || error) {
		  // Start fading out before removing
		  hideTimeout = setTimeout(() => {
			setFadeOut(true);
		  }, 2000); // Start fading out 0.5 seconds before removing
	
		  // Remove the alert after the fade-out transition
		  removeTimeout = setTimeout(() => {
			setSuccess(null);
			setError(null);
			setFadeOut(false); // Reset fadeOut for future alerts
		  }, 5000); // Remove after 2 seconds
		}
	
		return () => {
		  clearTimeout(hideTimeout);
		  clearTimeout(removeTimeout);
		};
	  }, [success, error]);



	const handleSubmit = async () => {				
		if (!validateForm()) {			
		  return;
		}
		try {			
			const response = await fetch(`${BASE_URL}register/`, {
				method: 'POST', // Use PUT method for updating
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				body: JSON.stringify(partnerData), // Send updated data in the request body
			});
			const responseData = await response.json();
			if (response.ok) {				
				setError('');
				setSuccess('Partner Created & Activation link Sent');
				const source = axios.CancelToken.source();
				fetchData(source);
				resetForm();
				setShow(false);
			} else {
				// Handle login error (e.g., display error message)
				if(responseData.data.email){
					setEmailError(responseData.data.email[0]);
					setError('');
				} else{
					setError('Error updating partner ', error);
				}
			}			
			// console.log('Partner created successfully');
		} catch (error) {
			if(error.response.status == 401){
				onLogout();
			}	
			setSuccess('');
			setError('Error Creating Partner:', error);
			// console.error('Error  Partner:', error);
		}
		window.scrollTo(0, 0);
	};

	const validateForm = () => {
		let isValid = true;
		if (!partnerData.name) {			
			setNameError('Partner name is required');
			isValid = false;			
		} else {
			setNameError('');
		}
		if(!partnerData.email){
			setEmailError('Email address is required');			
		}
		else if (!isValidEmail(partnerData.email)) {
			setEmailError('Valid email address is required');
			isValid = false;			
		} else {
			setEmailError('');
		}		
		if (role == "admin" && !partnerData.company_name) {						
			setCompanyNameError('Company name is required');
			isValid = false;
		} else {
			setCompanyNameError('');
		}		
		
		if(!partnerData.type){			
			setTypeError('Rep`s type is required');
			isValid = false;
		} else {
			setTypeError('');
		}
		

		return isValid;
	};
	
	const resetFormValidation = () => {
		setNameError('');
		setEmailError('');
		setCompanyNameError('');		
		setTypeError('');
	};
	const isValidEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};


	const handleCompanyChange = (e) => {
        const selectedCompanyId = e.target.value;
        CompanyChange(selectedCompanyId);
    };
    const CompanyChange = (selectedCompanyId) => {
        const selectedCompany = cdata.find(company => company.id == selectedCompanyId);
		setPartnerData({...partnerData, company_name: selectedCompany ? selectedCompany.company_name : ''});
        setCompanyNameError(selectedCompanyId.trim() == '' ? 'Company name is required' : '');
       if (selectedCompany) {
		const updatedPartnerData = {
			...partnerData,
			company_name: selectedCompany.id
		};        

		setPartnerData(updatedPartnerData);
        } else {
			setPartnerData({
            ...partnerData,
            company_name: ''
        });
        };
    };

	return (
		<div className="sb-nav-fixed">
			<Navbar userName={userName} role={role} onLogout={onLogout} />
			<div id="layoutSidenav">
				<Sidebar role={role} onLogout={onLogout} page='partners' />
				<div id="layoutSidenav_content">
					<main>
						<div className="container-fluid position-relative px-4">
						{success && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-success alert-dismissible  mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{success}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setSuccess(null)}></button></div>}
						
							<div className="row align-items-center justify-content-between">
								<div className="col-md-6">
									<h1 className="fs-4 mb-0">Users</h1>
								</div>
								<div className="col-md-6 text-end">
									<button className="btn btn-primary" onClick={handleShow}>+ Add User</button>
									<Modal show={show} dialogClassName="modal-right mt-0 mb-0 me-0">
										<div className="modal-content" style={{ animation: 'slideFromRight 0.3s forwards' }}>
											<Modal.Header >
												<Modal.Title>Register Partner</Modal.Title>
												<button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
											</Modal.Header>
											<Modal.Body >
												{error && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-danger alert-dismissible mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{error}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setError(null)}></button></div>}
												<form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
													<div className="custom-modal-ht">
													{role === 'admin' &&
													<div className="row mb-4">
														<div className="col-md-12">
															<div className="form-floating">
																<select className={`form-select ${companyNameError && 'is-invalid'}`} id="inputCompany" onChange={handleCompanyChange}>
																	<option value="">Select Company</option>
																	{cdata && Array.isArray(cdata) && cdata.map(option => (
																		// Check if option exists and has valid data
																		option && option.id && option.company_name && (
																			<option key={option.id} value={option.id}>{option.company_name}</option>
																		)
																	))}
																</select>
																<label htmlFor="inputCompany">Partner</label>
																{companyNameError && <div className="invalid-feedback">{companyNameError}</div>}
															</div>
														</div>
													</div>
													}		
														

													<div className="row mb-4">
														<div className="col-md-12">
															<div className="form-floating">
																<input className={`form-control ${nameError ? 'is-invalid' : ''}`} id="inputName" type="text" placeholder="Name" value={partnerData.name} onChange={(e) => { setPartnerData({ ...partnerData, name: e.target.value });  setNameError(e.target.value.trim() === '' ? 'Partner name is required' : '');
															}} />
																<label htmlFor="inputName">Name</label>
																{nameError && <div className="invalid-feedback">{nameError}</div>}
															</div>
														</div>
													</div>
													<div className="row mb-4">
														<div className="col-md-12">
															<div className="form-floating">
																<input className={`form-control ${emailError ? 'is-invalid' : ''}`} id="inputEmail" type="email" placeholder="name@example.com" value={partnerData.email} onChange={(e) => { setPartnerData({ ...partnerData, email: e.target.value }); setEmailError(e.target.value.trim() === '' ? 'Email address is required' : ''); }} />
																<label htmlFor="inputEmail">Email address</label>
																{emailError && <div className="invalid-feedback">{emailError}</div>}
															</div>
														</div>
													</div>
													
													<div className="row mb-4">
														<div className="col-md-12">
															<div className="form-floating">
																<select className={`form-select ${typeError ? 'is-invalid' : ''}`} id="inputPartnerType"  value={partnerData.type} onChange={(e) => { setPartnerData({ ...partnerData, type: e.target.value }); setTypeError(e.target.value.trim() === '' ? 'Rep`s Type is required' : ''); }} >
																	<option value="" disabled>Select Role</option>
																	<option value="partner-admin">Admin</option>
																	<option value="partner-user">User</option>
																</select>
																<label htmlFor="inputPartnerType">Role</label>
																{typeError && <div className="invalid-feedback">{typeError}</div>}
															</div>
														</div>
													</div>												

													</div>
													<div>
													<div className="row">
														<div className="col-md-12 text-end">
															<button className="btn" type="button" onClick={handleClose}>Cancel</button>
															<button type="submit" className="btn btn-primary mx-2">Register & Send Activation link</button>
														</div>
													</div>
													</div>
												</form>
											</Modal.Body>
										</div>
									</Modal>
								</div>
							</div>
							
							{error && <p>{error}</p>}
							{/* {console.log('return jsx->',data)}*/}
								<div className="card my-4">
									<div className="card-body">
										<table id="datatablesSimple" className="table table-bordered text-break">
											<thead className='table-primary'>
												<tr>
													<th>ID</th>
													<th>Name</th>
													<th>Email</th>
													<th>Status</th>
													<th>Created Date</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody><tr><td colSpan="8">{loading ? (  "Loading..." ) :( "No Records Found" ) }</td></tr></tbody>
										</table>
									</div>
								</div>				
						</div>
					</main>
				</div>
			</div>
		</div>
	);
};

export default Partners;
