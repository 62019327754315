// EditPartner.js
import React, { useEffect, useState } from 'react';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import { Link, useNavigate, useParams } from 'react-router-dom'; // Import useParams
import {  BASE_URL, TOKEN } from '../../commonConstants'; 
import axios from 'axios';

const EditPartner = ({ userName, role, onLogout }) => {
    const navigate = useNavigate();
    const { id } = useParams(); // Get the id parameter from the URL

    const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);
    const [info, setInfo] = useState(false);
	const [nameError, setNameError] = useState('');
	const [emailError, setEmailError] = useState('');
	const [companyNameError, setCompanyNameError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [initialPartnerData, setInitialPartnerData] = useState(null);
	const [typeError, setTypeError] = useState('');
	const [fadeOut, setFadeOut] = useState(false);
    const [cdata, setCData] = useState([]); // Define state variable to hold data


	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]); // Define state variable to hold data


    const [partnerData, setPartnerData] = useState({
        name: '',
        email: '',
        company_name: '',
        status: '',
        created_at: '',
        type: ''
    });

    // Dynamic options for status select
    let statusOptions = [
        { value: 'active', label: 'Active' },
        { value: 'suspended', label: 'Block' },
        { value: 'pending', label: 'Pending'},
    ];

    const fetchCompanyData = async (source) => {
		try {
			const response = await axios.get(`${BASE_URL}companies/`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${TOKEN}`,
				},
				cancelToken: source.token,
			});
			const resData = response.data;
			setCData(resData.data); // Set fetched data to state
			// setLoading(false);
		} catch (error) {
			// console.error('Error fetching users:', error);
			//setError('Failed to fetch users');
			// setLoading(false);
		}
	};

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${BASE_URL}users/edit/${id}`, { // Use the id parameter in the URL
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${TOKEN}`,
                    },
                    body: JSON.stringify({}),
                });
                if (!response.ok) {
                    navigate(`/partners/`);
                    throw new Error('Failed to fetch partner');
                }
                const responseData = await response.json();
                setPartnerData(responseData.data); 
                setInitialPartnerData(responseData.data); // Set the initial state
                setLoading(false);
            } catch (error) {
                // console.error('Error fetching users:', error);
                setError('Failed to fetch users');
                setLoading(false);
            }
        };
        fetchData();
        const source = axios.CancelToken.source();
        fetchCompanyData(source);
        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, [id]);

    useEffect(() => {
		let hideTimeout;
		let removeTimeout;
	
		if (success || error || info) {
		  // Start fading out before removing
		  hideTimeout = setTimeout(() => {
			setFadeOut(true);
		  }, 3000); // Start fading out 0.5 seconds before removing
	
		  // Remove the alert after the fade-out transition
		  removeTimeout = setTimeout(() => {
			setSuccess(null);
			setError(null);
			setFadeOut(false); // Reset fadeOut for future alerts
		  }, 5000); // Remove after 2 seconds
		}
	
		return () => {
		  clearTimeout(hideTimeout);
		  clearTimeout(removeTimeout);
		};
	  }, [success, error, info]);

    const updatePartner = async () => {
        if (!validateForm()) {
            return;
          }
          if (JSON.stringify(partnerData) === JSON.stringify(initialPartnerData)) {
            // If they are the same, don't make the API call
            // console.log('No changes were made');
            setInfo('No changes were made');
            window.scrollTo(0, 0);
            setSuccess('');
            setError('');
		    return;
        } else { setInfo(''); setInitialPartnerData(partnerData); }
        try {
            const response = await fetch(`${BASE_URL}users/update/${id}`, { 
                method: 'POST', // Use PUT method for updating
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${TOKEN}`,
                },
                body: JSON.stringify(partnerData), // Send updated data in the request body
            });
			const responseData = await response.json();
            if (response.ok) {                
				setError('');
				setSuccess('Partner Updated successfully');
			} else {
                setSuccess('');
				// Handle login error (e.g., display error message)
				if(responseData.data.email){
					setEmailError(responseData.data.email[0]);
				} else{
					setError('Error updating partner ', error);
				}
			}
            // Optionally, you can handle success (e.g., show a success message or navigate to another page)
            // console.log('Partner updated successfully');
        } catch (error) {
            setError('Error updating partner:', error);
            // console.error('Error updating partner:', error);
            // Optionally, you can set an error state to display an error message to the user
        }
        window.scrollTo(0, 0);
    };
    // Handler for updating partner status
    const handleStatusChange = (e) => {
        setPartnerData({ ...partnerData, status: e.target.value });
    };
  
    const validateForm = () => {
		let isValid = true;
		if (!partnerData.name) {			
			setNameError('Partner name is required');
			isValid = false;
		} else {
			setNameError('');
		}
		if(!partnerData.email){
			setEmailError('Email address is required');
		}
		else if (!isValidEmail(partnerData.email)) {
			setEmailError('Valid email address is required');
			isValid = false;
		} else {
			setEmailError('');
		}

		if (!partnerData.company_name) {			
			setCompanyNameError('Company name is required');
			isValid = false;
		} else {
			setCompanyNameError('');
		}
		
		
        if(!partnerData.type){
			setTypeError('Partner type is required');
			isValid = false;
		} else {
			setTypeError('');
		}
		   
		return isValid;
	};
	
	const isValidEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};
    if (partnerData.email_verified_at === null) {
    statusOptions = statusOptions.filter(option => option.value !== 'active');
    }


    return (
        <div className="sb-nav-fixed">
            <Navbar userName={userName} role={role} onLogout={onLogout} />
            <div id="layoutSidenav">
                <Sidebar role={role} onLogout={onLogout} page="partners"/>
                <div id="layoutSidenav_content">
                <main>
                        <div className="container-fluid position-relative px-4">
                       
                        {success && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-success alert-dismissible  mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{success}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setSuccess(null)}></button></div>}
                        {error && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-danger alert-dismissible mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{error}<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() =>setError(null)}></button></div>}
                            {info && <div className= { `position-absolute start-50 translate-middle-x w-25 m-auto py-2 alert alert-info alert-dismissible mb-0 fade show${fadeOut ? 'alert-fade-out' : ''}`} role="alert">{info}<button type="button" className="btn-close" data-bs-dismiss="alert" onClick={() => setInfo(null)}  aria-label="Close"></button></div>}

                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-12">
                                    <h1 className="fs-4 mb-0">Edit Partner</h1>
                                </div>
                            </div>
                            <form className="p-4 shadow-sm border my-4" onSubmit={(e) => { e.preventDefault(); updatePartner(); }}>
                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input className={`form-control ${nameError ? 'is-invalid' : ''}`} id="inputName" type="text" placeholder="Partner Name" value={partnerData.name} onChange={(e) => { setPartnerData({ ...partnerData, name: e.target.value });  setNameError(e.target.value.trim() === '' ? 'Partner name is required' : '');
                                        }} />
                                            <label htmlFor="inputName">Name</label>
                                            {nameError && <div className="invalid-feedback">{nameError}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input className={`form-control ${emailError ? 'is-invalid' : ''}`} id="inputEmail" type="email" disabled placeholder="name@example.com" value={partnerData.email} onChange={(e) => {  setEmailError(e.target.value.trim() === '' ? 'Email address is required' : ''); }} />
                                            <label htmlFor="inputEmail">Email address</label>
                                            {emailError && <div className="invalid-feedback">{emailError}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                                               
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <select className="form-select" id="inputType" value={partnerData.type || ''} onChange={(e) => { setPartnerData({ ...partnerData, type: e.target.value }); setTypeError(e.target.value.trim() === '' ? 'Partner Type is required' : ''); }}>
                                                <option value="" disabled>Select Partner Type</option>
                                                <option value="partner-admin">Admin</option>
                                                <option value="partner-user">User</option>
                                            </select>
                                            <label htmlFor="inputType">Patner Type</label>
                                            {typeError && <div className="invalid-feedback">{typeError}</div>}
                                        </div>
                                    </div>  
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <select className="form-select" id="inputStatus" value={partnerData.status || ''} onChange={handleStatusChange}>
                                                {statusOptions.map(option => (
                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                            <label htmlFor="inputCommission">Patner Status</label>
                                            {statusError && <div className="invalid-feedback">{statusError}</div>}
                                        </div>
                                    </div>                                  
                                </div>

                                <div className="row mb-4">
                                {role === 'admin' &&
                                    <div className="col-md-6">                                    
                                        <div className="form-floating">                                       
                                            <select className="form-select"  value={partnerData.company_name || ''} onChange={(e) => { setPartnerData({ ...partnerData, company_name: e.target.value }); setCompanyNameError(e.target.value.trim() === '' ? 'Company name is required' : ''); }}>
                                            <option value="">Select Company</option>
                                            {cdata && Array.isArray(cdata) && cdata.map(option => (
                                                // Check if option exists and has valid data
                                                option && option.id && option.company_name && (
                                                    <option key={option.id} value={option.id}>{option.company_name}</option>
                                                )
                                            ))}
                                            </select>
                                            <label htmlFor="inputType">Company</label>
                                            {companyNameError && <div className="invalid-feedback">{companyNameError}</div>}
                                        </div>
                                    </div> 
                                    }  
                                   
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-floating">                                           
                                        <span>Created Date : </span>{(partnerData.created_at_formated) || ''}
                                        </div>
                                    </div>                                                                      
                                </div>
                                    <div className="row mb-4">
                                    <div className="col-md-12 text-end">
                                        <Link className="btn btn-link text-decoration-none" aria-current="page" to="/partners">Cancel</Link>
                                        <button type="submit" className="btn btn-primary mx-2">Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default EditPartner;
