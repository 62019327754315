import React from 'react';
import Modal from 'react-bootstrap/Modal';

const LogoutModal = ({ showModal, handleConfirmLogout, handleCancelLogout }) => {
  return (
    <Modal show={showModal}>
      <Modal.Header>
        <Modal.Title>Log Out</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">Are you sure you want to logout?</div>
        <div className="text-end">
        <button type="button" className="btn btn-link text-decoration-none" onClick={handleCancelLogout}>Cancel</button>
          <button type="button" className="btn btn-primary" onClick={handleConfirmLogout}>Logout</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LogoutModal;
